export const findHorses = /* GraphQL */ `
	query findHorses($input: FindHorseInput) {
		countMatchingHorses(input: $input)
		findHorses(input: $input) {
			name
			sex
			age
			colour
			horseCode
		}
	}
`;

export const getHorses = /* GraphQL */ `
	query getHorse($horseCode: Int!) {
		getHorse(horseCode: $horseCode) {
			age
			colour
			country
			horseCode
			name
			nameOverseas
			sex
			status
			microChipNumber
		}
		listClaimRelationships
		getHorseProfile(horseCode: $horseCode) {
			program {
				id
				value
			}
		}
	}
`;

export const getHorsesByName = /* GraphQL */ `
	query getHorsesByName($horseName: String) {
		getHorsesByName(horseName: $horseName) {
			horseCode
			name
		}
	}
`;

export const submitRetirementReportsForReview = `
  mutation submitRetirementReportsForReview ( $horseCode: Int!){
	submitRetirementReportsForReview(horseCode: $horseCode
		)
  }`;

export const submitAckRetirementQuarterlyReportsForReview = `
  mutation submitAckRetirementQuarterlyReportsForReview ($quarterNumber: Int!, $horseCode: Int!, $year: Int!){
	submitAckRetirementQuarterlyReportsForReview(
		quarterNumber: $quarterNumber
		horseCode: $horseCode
		year: $year)
  }`;

export const getResetConcludedDetails = /* GraphQL */ `
	query getResetConcludedDetails($horseCode: Int!) {
		getResetConcludedDetails(horseCode: $horseCode) {
			concludedReason
			reason
		}
	}
`;

export const countHorseClaims = /* GraphQL */ `
	query countHorseClaims(
		$filterStatuses: [FormStatusEnum!]
		$uniqueHorsesOnly: Boolean
		$horseCode: Int
	) {
		countHorseClaims(
			status: $filterStatuses
			uniqueHorsesOnly: $uniqueHorsesOnly
			horseCode: $horseCode
		)
	}
`;

export const getHorseClaimers = /* GraphQL */ `
	query getHorseClaimers($horseCode: Int!) {
		getHorseProfile(horseCode: $horseCode) {
			claims {
				ownershipType
				member {
					externalId
				}
			}
		}
	}
`;

export const getMemberData = `
  query getMemberData($externalId: String!) {
    getMember(externalId: $externalId) {
      id
      externalId
      claims {
        horse {
          horseCode
          name
        }
      }
      quickLinks {
				linkId
				linkOrder
			}
      notifications {
        type
        id
        message
        identifiers {
          id
          value
        }
        link
      }
      roles {
        id
        code
      }
    }
  }
`;

export const getMemberBenefitsAndMerchandiseForms = /* GraphQL */ `
	query getMemberBenefitsAndMerchandiseForms($memberExternalId: String) {
		getMemberBenefitsAndMerchandiseForms(memberExternalId: $memberExternalId) {
			id
			memberId
			memberBenefits {
				id
				value
			}
			memberBenefitsOther
			memberMerchandise {
				id
				value
			}
			memberMerchandiseOther
			memberMerchandiseDetails
			memberMerchandiseRugSize {
				id
				value
			}
			eventTicketsDetails
			eventDate
			clinicDetails {
				id
				value
			}
			clinicDetailsOther
			competitionDrawDate
			competitionPrizeDetails
			winnersEligibilityConfirmedBy
			comment
			created
			byMemberId {
				firstName
				lastName
			}
		}
	}
`;

export const getMemberBenefitsAndMerchandiseFormById = /* GraphQL */ `
	query getMemberBenefitsAndMerchandiseFormById($id: Int!) {
		getMemberBenefitsAndMerchandiseFormById(id: $id) {
			clinicId
			clinicOther
			comment
			competitionDrawDate
			competitionPrizeDetails
			eventDate
			eventTicketsDetails
			id
			memberId
			memberBenefitsTypeId
			memberBenefitsOther
			merchandiseTypeId
			merchandiseTypeOther
			merchandiseDetails
			merchandiseRugSizeId
			winnersEligibilityConfirmedBy
		}
	}
`;

export const getSearchReferenceData = /* GraphQL */ `
	query filterQuery {
		listCountries
		listHorseColours
		listHorseGenders
		listHorseStatuses
	}
`;

export const getThoroughbredReferenceData = /* GraphQL */ `
	query filterQuery {
		listCountries
		listHorseColours
		listHorseGenders
	}
`;

export const getThoroughbredRelationshipsTypeReferenceData = /* GraphQL */ `
	query listThoroughbredRelationships {
		listThoroughbredRelationships {
			id
			value
		}
	}
`;

export const getProfileReferenceData = /* GraphQL */ `
	query listYearsRidingLookupsQuery {
		listYearsRidingLookups {
			id
			value
		}
	}
`;

export const getHorseClaimByIdQuery = /* GraphQL */ `
	query getHorseClaimById($id: Int!) {
		getHorseClaimById(id: $id) {
			horse {
				name
				horseCode
			}
			isAtMyAddress
			stabledDate
			foalYear
			damName
			microChipNumber
			completionDatetime
			sireName
			reviewNotes
			reviewReasons
			status
			ownershipType
			organisation
			claimPurpose
			claimsSource
			claimsSourceOther
			stabledAddressLine
			stabledSuburb
			stabledState
			stabledPostcode
			stabledCountry
			stabledAddressValidated
			residentialAddress
			residentialCountry
			residentialPostcode
			residentialState
			residentialSuburb
			behalfAdmin {
				firstName
				lastName
			}
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			brandLeftImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			brandRightImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsFrontImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsSideImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			microchipImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
		}
	}
`;

export const getPreviousHorseClaimByIdQuery = /* GraphQL */ `
	query getHorseClaimById($id: Int!) {
		getHorsePreviousClaimById(id: $id) {
			stabledDate
			ownershipType
			organisation
			claimsSource
			claimsSourceOther
			claimPurpose
			stabledAddressLine
			stabledSuburb
			stabledState
			stabledPostcode
			stabledCountry
			stabledAddressValidated
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				horseCode
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			brandLeftImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			brandRightImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsFrontImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsSideImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			microchipImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
		}
	}
`;

export const getHorsePreviousClaim = /* GraphQL */ `
	query getHorsePreviousClaim($memberExternalId: String, $horseCode: Int!) {
		getHorsePreviousClaim(
			memberExternalId: $memberExternalId
			horseCode: $horseCode
		) {
			stabledDate
			ownershipType
			organisation
			claimsSource
			claimsSourceOther
			claimPurpose
			stabledAddressLine
			stabledSuburb
			stabledState
			stabledPostcode
			stabledCountry
			stabledAddressValidated
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				horseCode
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			brandLeftImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			brandRightImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsFrontImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsSideImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			microchipImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
		}
	}
`;

export const getPreviousHorseClaimByUserQuery = /* GraphQL */ `
	query getHorsePreviousClaimByUser($horseCode: Int!) {
		getHorsePreviousClaimByUser(horseCode: $horseCode) {
			stabledDate
			ownershipType
			organisation
			claimsSource
			claimsSourceOther
			claimPurpose
			stabledAddressLine
			stabledSuburb
			stabledState
			stabledPostcode
			stabledCountry
			stabledAddressValidated
			id
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				horseCode
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			brandLeftImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			brandRightImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsFrontImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsSideImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			microchipImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
		}
	}
`;

export const getShareInformationForm = /* GraphQL */ `
	query getShareInformationForm($horseCode: Int!, $memberExternalId: String!) {
		getShareInformationForm(
			horseCode: $horseCode
			memberExternalId: $memberExternalId
		) {
			address
			causeOfDeath {
				id
			}
			confirmAccurateInfo
			country
			currentStatus {
				id
				value
			}
			relationship {
				id
			}
			relationshipOther
			lastSeenDate
			id
			isStatusCorrect
			informationToUpdate
			postcode
			state
			suburb
			locationValidated
			injuries {
				id
				value
			}
			illnesses {
				id
				value
			}
			info
			newStatus {
				id
				value
			}
			naturalDateOfDeath
			ohepDate
			knackeryDate
			knackeryPostcode
			knackerySuburb
			deceasedUnknownDate
			deceasedOtherDate
			deceasedOtherInfo
			euthanisedDate
			euthanisedReason {
				id
			}
			# euthanisedInjury {
			# 	id
			# }
			euthanisedInjuryOther
			# euthanisedIllness {
			# 	id
			# }
			euthanisedIllnessOther
			euthanisedUnsuitable {
				id
			}
			euthanisedUnsuitableOther
			euthanisedOther {
				id
			}
			retiredPostcode
			retiredStatusDate
			retiredSuburb
			updateLocationAndStatus
			otherStatusDate
			otherStatusPostcode
			otherStatusSuburb
			uploads {
				filename
				key
			}
			otherStatusValue
		}
	}
`;

export const getDeceasedThoroughbredForm = /* GraphQL */ `
	query getDeceasedThoroughbredForm(
		$horseCode: Int!
		$memberExternalId: String!
	) {
		getDeceasedThoroughbredForm(
			horseCode: $horseCode
			memberExternalId: $memberExternalId
		) {
			id
			memberId
			horseCode
			deceasedThoroughbredSource {
				id
				value
			}
			deceasedDateOfDeath
			deceasedCauseOfDeath {
				id
				value
			}
			euthanisedReason {
				id
				value
			}
			euthanisedInjuryOther
			euthanisedIllnessOther
			euthanisedUnsuitable {
				id
				value
			}
			euthanisedUnsuitableOther
			deceasedReasonForDeathOther {
				id
				value
			}
			deceasedKnackeryPostcode
			deceasedKnackerySuburb
			deceasedOtherPostcode
			deceasedOtherSuburb
			informationAboutDeath
			injuries {
				id
			}
			illnesses {
				id
			}
			unsuitableReasons {
				id
				value
			}
			deceasedReasonForDeathOtherText
			hasOwnerBeenNotified
			status
			created
			lastUpdated
			reviewNotes
			lastReviewed
			lastSubmitted
		}
	}
`;

export const getDeceasedThoroughbredForms = /* GraphQL */ `
	query getDeceasedThoroughbredForms($status: [FormStatusEnum]) {
		getDeceasedThoroughbredForms(status: $status) {
			id
			deceasedThoroughbredSource {
				id
				value
			}
			deceasedDateOfDeath
			deceasedCauseOfDeath {
				id
				value
			}
			euthanisedReason {
				id
				value
			}
			euthanisedInjuryOther
			euthanisedIllnessOther
			euthanisedUnsuitable {
				id
				value
			}
			euthanisedUnsuitableOther
			deceasedReasonForDeathOther {
				id
				value
			}
			deceasedKnackeryPostcode
			deceasedKnackerySuburb
			deceasedOtherPostcode
			deceasedOtherSuburb
			informationAboutDeath
			deceasedReasonForDeathOtherText
			injuries {
				id
			}
			illnesses {
				id
			}
			unsuitableReasons {
				id
				value
			}
			hasOwnerBeenNotified
			status
			created
			lastUpdated
			reviewNotes
			lastReviewed
			lastSubmitted
			member {
				id
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				horseCode
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
		}
	}
`;

export const getDeceasedThoroughbredFormById = /* GraphQL */ `
	query getDeceasedThoroughbredFormById($id: Int!) {
		getDeceasedThoroughbredFormById(id: $id) {
			id
			lastSeenDate
			relationship {
				id
				value
			}
			relationshipOther
			adminAction {
				id
				formId
				admin {
					id
					lastName
					firstName
					externalId
				}
				adminOutcome {
					id
					value
				}
				adminStatus {
					id
					value
				}
			}
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				horseCode
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			# memberId
			# horseCode
			deceasedThoroughbredSource {
				id
				value
			}
			deceasedDateOfDeath
			deceasedCauseOfDeath {
				id
				value
			}
			euthanisedReason {
				id
				value
			}
			euthanisedInjuryOther
			euthanisedIllnessOther
			euthanisedUnsuitable {
				id
				value
			}
			euthanisedUnsuitableOther
			deceasedReasonForDeathOtherText
			deceasedReasonForDeathOther {
				id
				value
			}
			deceasedKnackeryPostcode
			deceasedKnackerySuburb
			deceasedOtherPostcode
			deceasedOtherSuburb
			informationAboutDeath
			injuries {
				id
			}
			illnesses {
				id
			}
			unsuitableReasons {
				id
				value
			}
			hasOwnerBeenNotified
			status
			created
			lastUpdated
			reviewNotes
			lastReviewed
			lastSubmitted
			retirementCategory
			vulnerabilityType
			behaviourOthersRetirement
			conformationalDetailsRetirement
			otherEducationDetails
			otherInjuryDetailsRetirement
			otherIllnessDetailsRetirement
			rehomingHistoryDetails
			otherDetailsRetirement
			habitsRetirement {
				id
				value
			}
			injuriesRetirement {
				id
				value
			}
			illnessRetirement {
				id
				value
			}
			education {
				id
				value
			}
			reasonRetirement {
				id
				value
			}
		}
	}
`;

export const getThoroughbredFormQuery = /* GraphQL */ `
	query getThoroughbredForm($guid: String!) {
		getThoroughbredForm(guid: $guid) {
			member {
				firstName
				lastName
				email
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			brandLeftImages {
				filename
				guid
				imageSection
				key
				memberExternalId
			}
			brandRightImages {
				filename
				guid
				imageSection
				key
				memberExternalId
			}
			colour
			country
			damName
			foalYear
			guid
			horseAge
			horseName
			markings {
				filename
				guid
				imageSection
				key
				memberExternalId
			}
			microchip
			notes
			relationship {
				id
				value
			}
			organisation
			sex
			sireName
			status
			reviewNotes
			adminAction {
				id
				formId
				admin {
					id
					lastName
					firstName
					externalId
				}
				adminOutcome {
					id
					value
				}
				adminStatus {
					id
					value
				}
			}
		}
	}
`;

export const saveThoroughbredFormData = /* GraphQL */ `
	mutation saveThoroughbredForm(
		$ThoroughbredFormInputData: ThoroughbredFormInput!
	) {
		saveThoroughbredForm(input: $ThoroughbredFormInputData)
	}
`;

export const deleteThoroughbredFormMutation = /* GraphQL */ `
	mutation deleteThoroughbredForm($guid: String!) {
		deleteThoroughbredForm(guid: $guid)
	}
`;

export const saveHorsePlacementFormData = /* GraphQL */ `
	mutation saveHorsePlacementForm(
		$horsePlacementFormInputData: HorsePlacementFormInput!
	) {
		saveHorsePlacementForm(input: $horsePlacementFormInputData) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveConcludeResetForm = /* GraphQL */ `
	mutation SaveConcludeResetForm(
		$resetConcludedFormData: ConcludeResetFormInput!
	) {
		concludeReset(input: $resetConcludedFormData) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveOttInformation = /* GraphQL */ `
	mutation SaveOTTInformation($ottInformation: OttInformationInput!) {
		saveOttInformation(input: $ottInformation) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const createShareInformationForm = /* GraphQL */ `
	mutation saveShareInformationForm(
		$shareInformationFormInputData: ShareInformationFormInput!
	) {
		saveShareInformationForm(input: $shareInformationFormInputData) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const updateShareInformationForm = /* GraphQL */ `
	mutation saveShareInformationForm(
		$shareInformationFormInputData: ShareInformationFormInput!
	) {
		saveShareInformationForm(input: $shareInformationFormInputData) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveHorsePlacementFormStatusMutation = `
mutation saveHorsePlacementFormStatus($horsePlacementFormStatusInput: HorsePlacementFormStatusInput!) {
	saveHorsePlacementFormStatus(
		input: $horsePlacementFormStatusInput
	) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }
  `;

export const deleteHorsePlacementFormMutation = /* GraphQL */ `
	mutation deleteHorsePlacementForm($id: Int!, $memberExternalId: String) {
		deleteHorsePlacementForm(id: $id, memberExternalId: $memberExternalId)
	}
`;

export const getHorsePlacementFormQuery = /* GraphQL */ `
	query getHorsePlacementForm($horseCode: Int!, $memberExternalId: String!) {
		getHorsePlacementForm(
			horseCode: $horseCode
			memberExternalId: $memberExternalId
		) {
			member {
				firstName
				lastName
				email
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			additionalPhotos {
				filename
				isApproved
				key
				type
			}
			address
			behindFullPhotos {
				filename
				isApproved
				key
				type
			}
			confirmed
			contactNumber
			country
			created
			currentOwners
			currentRetrainers
			damName
			dateArrived
			disciplines {
				id
				value
			}
			disciplinesOther
			faceOnPhotos {
				filename
				isApproved
				key
				type
			}
			height
			horse {
				name
				sex
				colour
				age
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			id
			injuries {
				id
				value
			}
			injuriesDetails
			termsDeclaration
			registerDeclaration
			contributeRetrainingCosts
			isEligibleForRESET
			isHelpingTransportCosts
			isKnownToWindsuck {
				id
				value
			}
			isManualAddress
			lastReviewed
			lastUpdated
			leftSidePhotos {
				filename
				isApproved
				key
				type
			}
			lengthOfRelationship {
				id
				value
			}
			otherDetails
			postcode
			relationshipToHorse {
				id
				value
			}
			relationshipToHorseOther
			retirementOtherReason
			retirementReason {
				id
				value
			}
			reviewMessages
			reviewNotes
			riddenHistory
			rightSidePhotos {
				filename
				isApproved
				key
				type
			}
			sireName
			state
			status
			suburb
			temperament
			vetReports {
				filename
				key
				isApproved
			}
			isInjuryImpactedHorse
			isIllnessImpactedHorse
			illnesses {
				id
				value
			}
			doesHorseHasCurrentVices
			doesHorseHasPastVices
			vicesCurrent {
				id
				value
			}
			vicesPast {
				id
				value
			}
			isWillingToArrange
			illnessOther
			injuryOther
			habitsCurrentOther
			habitsPastOther
			isPastInjuryImpactedHorse
			injuriesPast {
				id
				value
			}
			illnessAndInjuriesDetails
			injuryPastOther
			isImpactSoundness
		}
	}
`;

export const getHorsePlacementFormForReset = /* GraphQL */ `
	query getHorsePlacementFormForReset($horseCode: Int!) {
		getHorsePlacementFormForReset(horseCode: $horseCode) {
			address
			confirmed
			contactNumber
			country
			created
			currentOwners
			currentRetrainers
			damName
			dateArrived
			disciplines {
				id
				value
			}
			disciplinesOther
			height
			id
			injuries {
				id
				value
			}
			injuriesDetails
			isEligibleForRESET
			isHelpingTransportCosts
			isKnownToWindsuck {
				id
				value
			}
			isManualAddress
			lastReviewed
			lastUpdated
			lengthOfRelationship {
				id
				value
			}
			otherDetails
			postcode
			relationshipToHorse {
				id
				value
			}
			relationshipToHorseOther
			retirementOtherReason
			retirementReason {
				id
				value
			}
			reviewMessages
			reviewNotes
			riddenHistory
			sireName
			state
			status
			suburb
			temperament
		}
	}
`;

export const getPlacementReferenceData = /* GraphQL */ `
	query filterQuery {
		listSpecialtyDisciplines {
			id
			value
		}
		listIllnessDetails {
			id
			value
		}
		listHabitsAndVices {
			id
			value
		}
		listInjuryDetails {
			id
			value
		}
		listRelationshipToHorseLength {
			id
			value
		}
		listRelationshipToHorse {
			id
			value
		}
		listRetirementLookup {
			id
			value
		}
		listYesNo {
			id
			value
		}
	}
`;

export const uploadHorsePlacementImage = /* GraphQL */ `
	mutation saveHorsePlacementImage(
		$filename: String!
		$horseCode: Int!
		$key: String!
		$type: HorsePlacementImageTypeEnum!
		$memberId: String
		$isSubmitOnBehalf: Boolean
	) {
		saveHorsePlacementImage(
			filename: $filename
			horseCode: $horseCode
			key: $key
			type: $type
			memberId: $memberId
			isSubmitOnBehalf: $isSubmitOnBehalf
		) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const deleteHorsePlacementImageMutation = /* GraphQL */ `
	mutation deleteHorsePlacementImage($key: String!) {
		deleteHorsePlacementImage(key: $key)
	}
`;

export const saveHorseClaimStatusMutation = /* GraphQL */ `
	mutation saveHorseClaimStatus(
		$id: Int!
		$reviewNotes: String
		$reviewReasons: String
		$status: FormStatusEnum!
	) {
		saveHorseClaimStatus(
			id: $id
			reviewNotes: $reviewNotes
			reviewReasons: $reviewReasons
			status: $status
		) {
			id
		}
	}
`;

export const saveBusinessProfileStatusMutation = /* GraphQL */ `
	mutation saveBusinessProfileStatus(
		$id: Int!
		$reviewNotes: String
		$reviewReasons: String
		$status: FormStatusEnum!
		$source: String
	) {
		saveBusinessProfileStatus(
			id: $id
			reviewNotes: $reviewNotes
			reviewReasons: $reviewReasons
			status: $status
			source: $source
		)
	}
`;

export const getMemberProfile = /* GraphQL */ `
	query getMember($externalId: String!) {
		getMember(externalId: $externalId) {
			id
			email
			externalId
			firstName
			lastName
			preferredName
			dateOfBirth
			gender
			goldenRecordId
			phone
			businessPhone
			postcode
			yearsRiding {
				id
				value
			}
			residentialAddress {
				address
				country
				lastUpdated
				postcode
				state
				suburb
			}
			postalAddress {
				address
				country
				lastUpdated
				postcode
				state
				suburb
			}
			primaryMembershipType {
				id
				name
			}
			secondaryMemebershipTypes {
				name
				id
			}
			interests {
				id
				title
			}
			otherInterests
			memberships {
				id
				address
				name
				postcode
			}
			raceClubMemberships {
				id
				address
				name
				postcode
			}
			otherMemberships
			lastUpdated
			roles {
				id
				name
				code
			}
		}
	}
`;

export const updateMemberProfile = /* GraphQL */ `
	mutation updateMember($updateMemberProfileData: MemberUpdateInput!) {
		updateMember(input: $updateMemberProfileData) {
			id
		}
	}
`;

export const saveResidentialAddress = /* GraphQL */ `
	mutation saveResidentialAddress($saveAddress: AddressInput) {
		saveResidentialAddress(input: $saveAddress)
	}
`;

export const savePostalAddress = /* GraphQL */ `
	mutation savePostalAddress($saveAddress: AddressInput) {
		savePostalAddress(input: $saveAddress)
	}
`;

export const saveSecondaryMemebershipTypes = /* GraphQL */ `
	mutation saveSecondaryMemebershipTypes($secondaryMemebershipTypeIds: [Int]!) {
		saveSecondaryMemebershipTypes(
			secondaryMemebershipTypeIds: $secondaryMemebershipTypeIds
		)
	}
`;

export const saveInterests = /* GraphQL */ `
	mutation saveInterests($interestIds: [Int]!) {
		saveInterests(interestIds: $interestIds)
	}
`;

export const saveOtherInterests = /* GraphQL */ `
	mutation saveOtherInterests($otherInterests: String!) {
		saveOtherInterests(otherInterests: $otherInterests)
	}
`;

export const saveMemberships = /* GraphQL */ `
	mutation saveMemberships($membershipIds: [Int]!) {
		saveMemberships(membershipIds: $membershipIds)
	}
`;

export const saveOtherMemeberships = /* GraphQL */ `
	mutation saveOtherMemeberships($otherMemberships: String!) {
		saveOtherMemeberships(otherMemberships: $otherMemberships)
	}
`;

export const getMembershipListData = /* GraphQL */ `
	query filterQuery {
		listSecondaryMemebershipTypes {
			id
			name
		}
		listMemberships {
			id
			name
			displayOrder
		}
		listInterests {
			id
			title
			displayOrder
		}
		listRaceClubMemberships {
			id
			name
			displayOrder
		}
	}
`;

export const getDeceasedThoroughbredReferences = /* GraphQL */ `
	query getDeceasedThoroughbredReferences {
		listAckRetirementInjuryTypes {
			id
			value
		}
		listAckRetirementIllnessTypes {
			id
			value
		}
		listShareInfoEuthanisedIllness {
			id
			value
		}
		listShareInfoEuthanisedInjuries {
			id
			value
		}
		listShareInfoEuthanisedOtherReasons {
			id
			value
		}
		listShareInfoEuthanisedUnsuitableReasons {
			id
			value
		}
	}
`;

export const getShareInformationOptions = /* GraphQL */ `
	query shareInfoOptionsQuery {
		listShareInfoRelationships {
			id
			value
		}
		listShareInfoCauseOfDeaths {
			id
			value
		}
		listShareInfoEuthanisedIllness {
			id
			value
		}
		listShareInfoEuthanisedInjuries {
			id
			value
		}
		listShareInfoEuthanisedOtherReasons {
			id
			value
		}
		listShareInfoEuthanisedUnsuitableReasons {
			id
			value
		}
		listShareInfoStatuses {
			id
			value
		}
		listShareInfoEuthanisedReasons {
			id
			value
		}
	}
`;

export const getRetrainerConcludedRetrainingOptions = /* GraphQL */ `
	query retrainerConcludedRetrainingOptionsQuery {
		listEuthanisedInjuries {
			id
			value
		}
		listEuthanisedIllness {
			id
			value
		}
		listEuthanisedOtherReasons {
			id
			value
		}
		listEuthanisedReasons {
			id
			value
		}
		listEuthanisedUnsuitableReasons {
			id
			value
		}
	}
`;

export const getHorseRehomePurposes = /* GraphQL */ `
	query shareInfoOptionsQuery {
		listHorseRehomePurposes {
			id
			value
		}
	}
`;

export const listSoldRehomePriceBrackets = /* GraphQL */ `
	query listSoldRehomePriceBrackets {
		listSoldRehomePriceBrackets {
			id
			value
		}
	}
`;

export const getBusinessProfileTypeReferenceData = /* GraphQL */ `
	query listBusinessProfileType {
		listBusinessProfileType {
			id
			value
		}
	}
`;

export const getBusinessProfileID = /* GraphQL */ `
	query getBusinessProfile($externalId: String!) {
		getBusinessProfile(memberExternalId: $externalId) {
			id
		}
	}
`;

export const getBusinessProfileByIdQuery = /* GraphQL */ `
	query getBusinessProfileById($id: Int!, $byBusinessProfile: Boolean) {
		getBusinessProfileById(id: $id, byBusinessProfile: $byBusinessProfile) {
			id
			status
			reviewNotes
			reviewReasons
			businessProfileType {
				id
				value
			}
			businessName
			logo {
				approved
				filename
				key
				type
				businessProfileId
			}
			firstName
			lastName
			email
			businessPhone
			fullAddress
			streetAddress
			suburb
			postcode
			state
			businessSummary
			facilitiesOnProperty {
				id
				value
			}
			facilitiesOffSite {
				id
				value
			}
			specialtyDisciplines {
				id
				value
			}
			servicesOffered {
				id
				value
			}
			facilitiesOffSiteOther
			facilitiesOnPropertyOther
			specialtyDisciplinesOther
			servicesOfferedOther
			priceRange
			experiences
			twitter
			instagram
			facebook
			website
			riddenPhotos {
				approved
				filename
				key
				type
				businessProfileId
			}
			propertyPhotos {
				approved
				filename
				key
				type
				businessProfileId
			}
			generalPhotos {
				approved
				filename
				key
				type
				businessProfileId
			}
			isMaximumCapacity
			maximumCapacity
			rangeOfFosterCare
			adviseDatesUnAvailable
			dateUnavailableEnd
			dateUnavailableStart
			finalNotes
			alternativeContact
			alternativePhone
		}
	}
`;

export const getBusinessProfile = /* GraphQL */ `
	query getBusinessProfile($externalId: String!) {
		getBusinessProfile(memberExternalId: $externalId) {
			id
			status
			businessProfileType {
				id
				value
			}
			businessName
			logo {
				approved
				filename
				key
				type
			}
			firstName
			firstNameDisplayOption {
				value
				id
			}
			lastName
			lastNameDisplayOption {
				id
				value
			}
			email
			emailDisplayOption {
				id
				value
			}
			businessPhone
			businessPhoneDisplayOption {
				id
				value
			}
			fullAddress
			streetAddress
			streetAddressDisplayOption {
				id
				value
			}
			suburb
			suburbDisplayOption {
				id
				value
			}
			postcode
			postcodeDisplayOption {
				id
				value
			}
			state
			stateDisplayOption {
				id
				value
			}
			businessSummary
			facilitiesOnProperty {
				id
				value
			}
			facilitiesOffSite {
				id
				value
			}
			specialtyDisciplines {
				id
				value
			}
			servicesOffered {
				id
				value
			}
			facilitiesOffSiteOther
			facilitiesOnPropertyOther
			specialtyDisciplinesOther
			servicesOfferedOther
			priceRange
			experiences
			twitter
			instagram
			facebook
			website
			riddenPhotos {
				approved
				filename
				key
				type
			}
			propertyPhotos {
				approved
				filename
				key
				type
			}
			generalPhotos {
				approved
				filename
				key
				type
			}
			created
			lastUpdated
		}
	}
`;

export const getBusinessProfileByIdTemplateQuery = /* GraphQL */ `
	query getBusinessProfileById($id: Int!, $byBusinessProfile: Boolean) {
		getBusinessProfileById(id: $id, byBusinessProfile: $byBusinessProfile) {
			id
			businessName
			businessProfileType {
				id
				value
			}
			logo {
				approved
				filename
				key
				type
				approved
				businessProfileId
			}
			firstName
			firstNameDisplayOption {
				value
			}
			lastName
			lastNameDisplayOption {
				value
			}
			email
			emailDisplayOption {
				id
				value
			}
			businessPhone
			businessPhoneDisplayOption {
				id
				value
			}
			fullAddress
			streetAddress
			streetAddressDisplayOption {
				id
				value
			}
			suburb
			suburbDisplayOption {
				id
				value
			}
			postcode
			postcodeDisplayOption {
				id
				value
			}
			state
			stateDisplayOption {
				id
				value
			}
			businessSummary
			facilitiesOnProperty {
				id
				value
			}
			facilitiesOffSite {
				id
				value
			}
			specialtyDisciplines {
				id
				value
			}
			servicesOffered {
				id
				value
			}
			facilitiesOffSiteOther
			facilitiesOnPropertyOther
			specialtyDisciplinesOther
			servicesOfferedOther
			priceRange
			experiences
			twitter
			instagram
			facebook
			website
			riddenPhotos {
				approved
				filename
				key
				type
				approved
				businessProfileId
			}
			propertyPhotos {
				approved
				filename
				key
				type
				approved
				businessProfileId
			}
			generalPhotos {
				approved
				filename
				key
				type
				approved
				businessProfileId
			}
			isMaximumCapacity
			maximumCapacity
			rangeOfFosterCare
			adviseDatesUnAvailable
			dateUnavailableEnd
			dateUnavailableStart
			finalNotes
			alternativeContact
			alternativePhone
		}
		listSpecialtyDisciplines {
			id
			value
		}
		listHorseGenderTypes {
			id
			value
		}
	}
`;

export const saveBusinessDetails = /* GraphQL */ `
	mutation saveBusinessDetails(
		$businessName: String!
		$businessProfileTypeId: Int!
		$memberExternalId: String!
		$firstName: String
		$lastName: String
		$businessPhone: String
		$email: String
		$postcode: String
		$state: String
		$streetAddress: String
		$suburb: String
		$alternativeContact: String
		$alternativePhone: String
	) {
		saveBusinessDetails(
			businessName: $businessName
			businessProfileTypeId: $businessProfileTypeId
			memberExternalId: $memberExternalId
			firstName: $firstName
			lastName: $lastName
			businessPhone: $businessPhone
			email: $email
			postcode: $postcode
			state: $state
			streetAddress: $streetAddress
			suburb: $suburb
			alternativeContact: $alternativeContact
			alternativePhone: $alternativePhone
		)
	}
`;

export const saveBusinessContactDetails = /* GraphQL */ `
	mutation saveBusinessContacts(
		$businessPhone: String!
		$businessPhoneDisplayOption: Int!
		$email: String!
		$emailDisplayOption: Int!
		$firstName: String!
		$firstNameDisplayOption: Int!
		$lastName: String!
		$lastNameDisplayOption: Int!
		$memberExternalId: String!
		$businessProfileTypeId: Int!
	) {
		saveBusinessContacts(
			businessPhone: $businessPhone
			businessPhoneDisplayOption: $businessPhoneDisplayOption
			email: $email
			emailDisplayOption: $emailDisplayOption
			firstName: $firstName
			firstNameDisplayOption: $firstNameDisplayOption
			lastName: $lastName
			lastNameDisplayOption: $lastNameDisplayOption
			memberExternalId: $memberExternalId
			businessProfileTypeId: $businessProfileTypeId
		)
	}
`;

export const saveBusinessPropDetails = /* GraphQL */ `
	mutation saveBusinessPropertyDetails(
		$fullAddress: String!
		$memberExternalId: String!
		$postcode: String!
		$postcodeDisplayOption: Int!
		$state: String!
		$stateDisplayOption: Int!
		$streetAddress: String!
		$streetAddressDisplayOption: Int!
		$suburb: String
		$suburbDisplayOption: Int!
		$businessProfileTypeId: Int!
	) {
		saveBusinessPropertyDetails(
			fullAddress: $fullAddress
			memberExternalId: $memberExternalId
			postcode: $postcode
			postcodeDisplayOption: $postcodeDisplayOption
			state: $state
			stateDisplayOption: $stateDisplayOption
			streetAddress: $streetAddress
			streetAddressDisplayOption: $streetAddressDisplayOption
			suburb: $suburb
			suburbDisplayOption: $suburbDisplayOption
			businessProfileTypeId: $businessProfileTypeId
		)
	}
`;

export const uploadBusinessProfileImage = /* GraphQL */ `
	mutation saveBusinessProfileImage(
		$filename: String!
		$key: String!
		$memberExternalId: String!
		$type: BusinessProfileImageTypeEnum!
		$businessProfileTypeId: Int!
	) {
		saveBusinessProfileImage(
			filename: $filename
			key: $key
			memberExternalId: $memberExternalId
			type: $type
			businessProfileTypeId: $businessProfileTypeId
		) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const uploadShareInformationImage = /* GraphQL */ `
	mutation saveShareInformationImage(
		$filename: String!
		$key: String!
		$shareInformationFormId: Int!
	) {
		saveShareInformationFormUpload(
			filename: $filename
			key: $key
			shareInformationFormId: $shareInformationFormId
		) {
			error {
				errorCode
				errorDetails
				errorMessage
			}
			id
			success
		}
	}
`;

export const deleteShareInformationImage = /* GraphQL */ `
	mutation deleteShareInformationFormUpload($key: String!) {
		deleteShareInformationFormUpload(key: $key)
	}
`;

export const removeBusinessProfileImage = /* GraphQL */ `
	mutation deleteBusinessProfileImage(
		$filename: String!
		$type: BusinessProfileImageTypeEnum!
		$businessProfileId: Int!
	) {
		deleteBusinessProfileImage(
			filename: $filename
			type: $type
			businessProfileId: $businessProfileId
		)
	}
`;

export const removeThoroughbredImage = /* GraphQL */ `
	mutation deleteThoroughbredImage($key: String!) {
		deleteThoroughbredImage(key: $key)
	}
`;

export const getDisplayOptionsReferenceData = /* GraphQL */ `
	query listDisplayOptions {
		listDisplayOptions {
			id
			value
		}
	}
`;

export const getBusinessInfoReferenceData = /* GraphQL */ `
	query listBusinessInfoQuery {
		listFacilitiesOnProperty {
			id
			value
		}
		listFacilitiesOffSite {
			id
			value
		}
		listSpecialtyDisciplines {
			id
			value
		}
		listServicesOffered {
			id
			value
		}
	}
`;

export const saveBusinessInformation = /* GraphQL */ `
	mutation saveBusinessInformation(
		$businessSummary: String!
		$experiences: String
		$facilitiesOffSite: [Int]
		$facilitiesOffSiteOther: String
		$facilitiesOnProperty: [Int]
		$facilitiesOnPropertyOther: String
		$memberExternalId: String!
		$priceRange: String
		$servicesOffered: [Int]
		$servicesOfferedOther: String
		$specialtyDisciplines: [Int]
		$specialtyDisciplinesOther: String
		$businessProfileTypeId: Int!
		$isMaximumCapacity: Boolean
		$maximumCapacity: String
		$rangeOfFosterCare: Int
		$isAdviseDatesUnavailable: Boolean
		$dateUnavailableStart: AWSDate
		$dateUnavailableEnd: AWSDate
		$finalNotes: String
	) {
		saveBusinessInformation(
			businessSummary: $businessSummary
			experiences: $experiences
			facilitiesOffSite: $facilitiesOffSite
			facilitiesOffSiteOther: $facilitiesOffSiteOther
			facilitiesOnProperty: $facilitiesOnProperty
			facilitiesOnPropertyOther: $facilitiesOnPropertyOther
			memberExternalId: $memberExternalId
			priceRange: $priceRange
			servicesOffered: $servicesOffered
			servicesOfferedOther: $servicesOfferedOther
			specialtyDisciplines: $specialtyDisciplines
			specialtyDisciplinesOther: $specialtyDisciplinesOther
			businessProfileTypeId: $businessProfileTypeId
			isMaximumCapacity: $isMaximumCapacity
			maximumCapacity: $maximumCapacity
			rangeOfFosterCare: $rangeOfFosterCare
			isAdviseDatesUnavailable: $isAdviseDatesUnavailable
			dateUnavailableStart: $dateUnavailableStart
			dateUnavailableEnd: $dateUnavailableEnd
			finalNotes: $finalNotes
		)
	}
`;

export const saveBusinessInfoFacilitiesOffSiteData = /* GraphQL */ `
	mutation saveBusinessInfoFacilitiesOffSite(
		$ids: [Int]
		$memberExternalId: String!
	) {
		saveBusinessInfoFacilitiesOffSite(
			ids: $ids
			memberExternalId: $memberExternalId
		)
	}
`;

export const saveBusinessInfoFacilitiesOnPropertyData = /* GraphQL */ `
	mutation saveBusinessInfoFacilitiesOnProperty(
		$ids: [Int]
		$memberExternalId: String!
	) {
		saveBusinessInfoFacilitiesOnProperty(
			ids: $ids
			memberExternalId: $memberExternalId
		)
	}
`;

export const saveBusinessInfoServicesOfferedData = /* GraphQL */ `
	mutation saveBusinessInfoServicesOffered(
		$ids: [Int]
		$memberExternalId: String!
	) {
		saveBusinessInfoServicesOffered(
			ids: $ids
			memberExternalId: $memberExternalId
		)
	}
`;

export const saveBusinessInfoSpecialtyDisciplinesData = /* GraphQL */ `
	mutation saveBusinessInfoSpecialtyDisciplines(
		$ids: [Int]
		$memberExternalId: String!
	) {
		saveBusinessInfoSpecialtyDisciplines(
			ids: $ids
			memberExternalId: $memberExternalId
		)
	}
`;

export const saveBusinessSocialLinksDetails = /* GraphQL */ `
	mutation saveBusinessSocialLinks(
		$facebook: String
		$instagram: String
		$memberExternalId: String!
		$twitter: String
		$website: String
		$businessProfileTypeId: Int!
	) {
		saveBusinessSocialLinks(
			facebook: $facebook
			instagram: $instagram
			memberExternalId: $memberExternalId
			twitter: $twitter
			website: $website
			businessProfileTypeId: $businessProfileTypeId
		)
	}
`;

export const saveBusinessProfileCorrectionsMutation = /* GraphQL */ `
	mutation saveBusinessProfileCorrections(
		$id: Int!
		$businessSummary: String
		$experiences: String
		$priceRange: String
		$facebook: String
		$instagram: String
		$twitter: String
		$website: String
	) {
		saveBusinessProfileCorrections(
			id: $id
			businessSummary: $businessSummary
			experiences: $experiences
			priceRange: $priceRange
			facebook: $facebook
			instagram: $instagram
			twitter: $twitter
			website: $website
		)
	}
`;

export const deleteHorseClaim = /* GraphQL */ `
	mutation deleteHorseClaim($horseCode: Int!) {
		deleteHorseClaim(horseCode: $horseCode)
	}
`;

export const deleteShareInformationForm = /* GraphQL */ `
	mutation deleteShareInformationForm($id: Int!) {
		deleteShareInformationForm(id: $id)
	}
`;

export const getHorseClaim = /* GraphQL */ `
	query getHorseClaim($horseCodeForClaim: Int!, $memberExternalId: String!) {
		getHorseClaim(
			horseCode: $horseCodeForClaim
			memberExternalId: $memberExternalId
		) {
			foalYear
			damName
			createDatetime
			completionDatetime
			lastUpdated
			microChipNumber
			ownershipType
			score
			sireName
			phone
			gender
			residentialAddress
			residentialSuburb
			residentialState
			residentialPostcode
			residentialCountry
			stabledAddressLine
			stabledSuburb
			stabledState
			stabledPostcode
			stabledCountry
			organisation
			claimPurpose
			claimsSource
			claimsSourceOther
			isAtMyAddress
			stabledAddressValidated
			stabledDate
			status
			brandLeftImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			brandRightImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsFrontImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			markingsSideImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			microchipImages {
				filename
				horseCode
				imageSection
				key
				memberExternalId
			}
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				horseCode
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
		}
	}
`;

export const saveHorseClaim = /* GraphQL */ `
	mutation saveHorseClaim($horseClaimData: HorseClaimInput) {
		saveHorseClaim(input: $horseClaimData) {
			id
		}
	}
`;

export const verifyHorseClaimData = /* GraphQL */ `
	query verifyClaimData($verifyClaimData: EvaluateClaimDetailsInput) {
		isMatched(input: $verifyClaimData) {
			damName
			foalYear
			microChipNumber
			sireName
		}
	}
`;

//{filename: "", horseCode: 10, imageSection: brandLeft, key: "", memberExternalId: ""}

export const uploadImage = /* GraphQL */ `
	mutation saveUploadedImage($saveImage: UploadedImageInput) {
		saveUploadedImage(input: $saveImage) {
			filename
			horseCode
			imageSection
			key
			memberExternalId
		}
	}
`;

export const uploadThoroughbredImage = /* GraphQL */ `
	mutation saveThoroughbredImage($saveImage: ThoroughbredImageInput) {
		saveThoroughbredImage(input: $saveImage)
	}
`;

export const deleteImage = /* GraphQL */ `
	mutation deleteUploadedImage($imageInput: UploadedImageInput) {
		deleteUploadedImage(input: $imageInput)
	}
`;

export const deleteThoroughbredImageMutation = /* GraphQL */ `
	mutation deleteThoroughbredImage($key: String!) {
		deleteThoroughbredImage(key: $key)
	}
`;

export const getUserImage = /* GraphQL */ `
	query getUserImage($imageKey: String!) {
		getUserImage(imageKey: $imageKey) {
			signedUrl
		}
	}
`;

export const uploadImageToS3 = /* GraphQL */ `
	mutation putUserImage($imageKey: String!) {
		putUserImage(fileName: $imageKey) {
			imageKey
			signedUrl
		}
	}
`;

export const deleteImageFromS3 = /* GraphQL */ `
	mutation deleteUserImage($imageKey: String!) {
		deleteUserImage(imageKey: $imageKey) {
			errorCode
			errorMessage
			resultText
			success
		}
	}
`;

export const deleteImagesFromS3 = /* GraphQL */ `
	mutation deleteUserImages($imageKeys: [String!]) {
		deleteUserImages(imageKeys: $imageKeys) {
			errorCode
			errorMessage
			resultText
			success
		}
	}
`;

export const getQuickLinks = /* GraphQL */ `
	query getMember($externalId: String!) {
		getMember(externalId: $externalId) {
			quickLinks {
				linkId
				linkOrder
			}
		}
	}
`;

export const searchUsers = /* GraphQL */ `
	query searchUsers($input: FindUserInput!) {
		findUsers(input: $input) {
			id
			claims {
				id
			}
			email
			externalId
			firstName
			lastName
			residentialAddress {
				suburb
			}
			primaryMembershipType {
				name
			}
		}
	}
`;

export const searchRetrainers = /* GraphQL */ `
	query searchRetrainers(
		$businessProfileType: Int
		$name: String
		$servicesOffered: [Int!]
		$specialtyDisciplines: [Int!]
		$orderBy: OrderByEnum
		$businessProfileIds: [Int!]
	) {
		countRetrainers(
			businessProfileType: $businessProfileType
			name: $name
			servicesOffered: $servicesOffered
			specialtyDisciplines: $specialtyDisciplines
			businessProfileIds: $businessProfileIds
		)
		findRetrainers(
			businessProfileType: $businessProfileType
			name: $name
			servicesOffered: $servicesOffered
			specialtyDisciplines: $specialtyDisciplines
			orderBy: $orderBy
			businessProfileIds: $businessProfileIds
		) {
			id
			businessProfileType {
				id
				value
			}
			businessName
			suburb
			postcode
			state
			businessSummary
			latitude
			longitude
			servicesOffered {
				id
				value
			}
			specialtyDisciplines {
				id
				value
			}
			riddenPhotos {
				filename
				key
				type
				approved
			}
		}
	}
`;

export const listServicesOffered = /* GraphQL */ `
	query listServicesOffered {
		listServicesOffered {
			id
			value
		}
	}
`;

export const listSpecialtyDisciplines = /* GraphQL */ `
	query listSpecialtyDisciplines {
		listSpecialtyDisciplines {
			id
			value
		}
	}
`;

export const listFacilitiesOnAndOffProperty = /* GraphQL */ `
	query listFacilitiesOnAndOffProperty {
		listFacilitiesOnProperty {
			id
			value
		}
		listFacilitiesOffSite {
			id
			value
		}
	}
`;

export const listHorseClaims = /* GraphQL */ `
	query getHorseClaims(
		$status: [FormStatusEnum!]
		$paginationIndex: Int
		$paginationSize: Int
		$orderBy: String = "create_datetime"
		$sortDirection: OrderByEnum = DESC
	) {
		getHorseClaims(
			status: $status
			paginationIndex: $paginationIndex
			paginationSize: $paginationSize
			orderBy: $orderBy
			order: $sortDirection
		) {
			id
			status
			member {
				firstName
				lastName
			}
			horse {
				name
				horseCode
			}
			completionDatetime
			createDatetime
		}
	}
`;

export const listBusinessProfiles = /* GraphQL */ `
	query getBusinessProfiles($status: [FormStatusEnum!]) {
		getBusinessProfiles(status: $status) {
			id
			status
			firstName
			lastName
		}
	}
`;

export const listFullCircleForms = /* GraphQL */ `
	query getBusinessProfiles($status: [FormStatusEnum!]) {
		getFullCircles(status: $status) {
			id
			status
			lastUpdated
			reviewLastUpdated
			member {
				firstName
				lastName
			}
			horse {
				name
				horseCode
			}
		}
	}
`;

export const listThoroughbredForms = /* GraphQL */ `
	query getThoroughbredForms($status: [FormStatusEnum!]) {
		getThoroughbredForms(status: $status) {
			guid
			status
			lastUpdated
			member {
				firstName
				lastName
			}
			horseName
			lastUpdated
		}
	}
`;

export const getShareInformationForms = /* GraphQL */ `
	query getShareInformationForms($status: [FormStatusEnum]) {
		getShareInformationForms(status: $status) {
			id
			member {
				externalId
				firstName
				lastName
			}
			status
			horse {
				name
			}
		}
	}
`;

export const getEvents = /* GraphQL */ `
	query getEvents($status: [FormStatusEnum]) {
		getEvents(status: $status) {
			id
			eventName
			eventStartDate
			eventEndDate
			eventStartTime
			eventEndTime
			eventAddress
			eventSuburb
			eventPostcode
			eventState
			eventCountry
			eventDiscipline
			lastSaved
			status
			firstName
			lastName
			eventOrganiser
			userEventOrganiserId
			userEventOrganiser
		}
	}
`;

export const getFilteredEvents = /* GraphQL */ `
	query getEvents(
		$status: [FormStatusEnum]
		$eventDisciplineIds: [Int]
		$eventOrganiserIds: [Int]
		$eventEndFilter: String
		$eventStartFilter: String
	) {
		getEvents(
			status: $status
			eventDisciplineIds: $eventDisciplineIds
			eventOrganiserIds: $eventOrganiserIds
			eventEndFilter: $eventEndFilter
			eventStartFilter: $eventStartFilter
		) {
			id
			eventName
			eventStartDate
			eventEndDate
			eventStartTime
			eventEndTime
			eventAddress
			eventSuburb
			eventPostcode
			eventState
			eventCountry
			eventDiscipline
			lastSaved
			status
			isCancelled
		}
	}
`;

export const getUpcomingEvents = /* GraphQL */ `
	query getUpcomingEvents {
		getUpcomingEvents {
			id
			eventName
			eventStartDate
			eventEndDate
			eventStartTime
			eventEndTime
			eventAddress
			eventSuburb
			eventPostcode
			eventState
			eventCountry
			eventDiscipline
			lastSaved
			status
			isCancelled
		}
	}
`;

export const getEventById = /* GraphQL */ `
	query getEventById($eventId: Int!) {
		getEventById(eventId: $eventId) {
			disciplineOther
			entriesCloseDate
			entriesOpenDate
			eventAddress
			eventCountry
			eventStartDate
			eventEndDate
			eventDiscipline
			eventEndTime
			eventDisciplineId
			eventName
			eventOrganiser
			eventOrganiserId
			eventPostcode
			eventStartTime
			eventState
			eventSuburb
			howToEnter
			judgingDetails
			lastSaved
			limitedEntries
			manualAddress
			organiserContactDetails
			organiserOther
			otherInformation
			ottSponsoredClasses
			ottSponsorshipDetails
			photographerDetails
			socialMedia
			status
			eventOrganiserImage {
				filename
				key
			}
			isCancelled
			cancellationOtherDetails
			cancellationReason
			cancellationReasonOther
			dateNotified
			isCancelled
			isAddEventResults
			stagedDetails
		}
	}
`;

export const getEventResultsById = /* GraphQL */ `
	query getEventResultsById($eventId: Int) {
		getEventResultsById(eventId: $eventId) {
			eventId
			id
			class
			horseCode
			horseName
			racingHorseName
			riderName
			memberId
			result
			comments
			status
			profilePhotoKey
		}
	}
`;

export const getEditEventResultsById = /* GraphQL */ `
	query getEditEventResultsById($eventId: Int) {
		getEditEventResultsById(eventId: $eventId) {
			eventId
			id
			class
			horseCode
			horseName
			racingHorseName
			riderName
			memberId
			result
			comments
			status
		}
	}
`;

export const getRole = /* GraphQL */ `
	query getMemberRoles($externalId: String!) {
		getMember(externalId: $externalId) {
			roles {
				id
				name
				code
			}
		}
	}
`;

export const getMemberNotifications = /* GraphQL */ `
	query getMemberNotifications($externalId: String!) {
		getMember(externalId: $externalId) {
			id
			notifications {
				type
				id
				message
				identifiers {
					id
					value
				}
				link
			}
		}
	}
`;

export const saveFullCircle = `
mutation saveFullCircle($input: FullCircleInput, $memberExternalId: String!, $horseCode: Int!, $typeOfAssistance: [Int!]) {
  saveFullCircle(input: $input)
  saveFullCircleTypesOfAssociation(memberExternalId: $memberExternalId, horseCode: $horseCode, typeOfAssistance: $typeOfAssistance)
}`;

export const saveFullCircleStatusMutation = /* GraphQL */ `
	mutation saveFullCircleStatus(
		$id: Int!
		$reviewNotes: String
		$status: FormStatusEnum!
	) {
		saveFullCircleStatus(id: $id, reviewNotes: $reviewNotes, status: $status)
	}
`;

export const saveThoroughbredRegistrationStatusMutation = /* GraphQL */ `
	mutation saveThoroughbredFormStatus(
		$guid: String!
		$reviewNotes: String
		$status: FormStatusEnum!
	) {
		saveThoroughbredFormStatus(
			guid: $guid
			reviewNotes: $reviewNotes
			status: $status
		)
	}
`;

export const getFullCircle = `
query getFullCircle($memberExternalId: String!, $horseCode: Int!) {
  getFullCircle(memberExternalId: $memberExternalId, horseCode: $horseCode) {
    id
    member {
        externalId
        firstName
        lastName
        phone
        businessPhone
        residentialAddress {
            address
            country
            postcode
            state
            suburb
        }
    }
    horse {
      horseCode
      name
      status
      sex
      age
      colour
    }
    phone
    businessPhone
    residentialAddress
    residentialSuburb
    residentialState
    residentialPostcode
    residentialCountry
    relationship {
      id
      value
    }
	organisation
    typeOfAssistance {
      id
      value
    }
    createDatetime
    lastUpdated
    status
    reviewNotes
    reviewLastUpdated
  }
}`;

export const getFullCircleByIdQuery = /* GraphQL */ `
	query getFullCircleById($id: Int!) {
		getFullCircleById(id: $id) {
			id
			member {
				firstName
				lastName
				phone
				businessPhone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			horse {
				name
				sex
				age
				colour
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			organisation
			phone
			businessPhone
			residentialAddress
			residentialSuburb
			residentialState
			residentialPostcode
			residentialCountry
			relationship {
				value
			}
			typeOfAssistance {
				value
			}
			status
			reviewNotes
		}
	}
`;

export const getFullCircleStats = `
query getFullCircleStats {
  getFullCircleStats {
    totalMembers
    totalHorses
  }
}`;

export const deleteFullCircle = `
mutation deleteFullCircle($horseCode: Int!){
  deleteFullCircle(horseCode: $horseCode)}`;

export const getRelationshipList = `
query getRelationshipList {
  listFullCircleRelationships {
    id
    value
  }
}`;

export const getAssistanceTypeList = `
query getAssistanceTypeList{
  listFullCircleTypesOfAssistance {
    id
    value
  }
}`;

export const getRoleList = `
query getRoles{
  listRoles {
	id
    code
    name
    description
  }
}`;

export const assignRoles = `
mutation assignRoles($email: String, $roles: [String!], $eventOrganiserId: Int, $eventOrganiserOther: String){
  assignRoles(email: $email, roles: $roles, eventOrganiserId: $eventOrganiserId, eventOrganiserOther: $eventOrganiserOther)
}`;

export const getMemberByEmail = `
query getMemberByEmail($email: String!) {
  getMemberByEmail(email: $email) {
    id
	externalId
	roles {
		code
		name
		description
	}
	eventOrganiser {
        id
        value
		organiserOther
      }
	retrainingData {
		retrainingThoroughbreds {
		horse {
			horseCode
			name
			sex
			age
			colour
			ottInformation {
			paddockName
			profilePhoto {
				key
			}
			}
		}
		}
	}
  }
}`;

export const approvedClaimList = `
query approvedThroughbredList($memberExternalId: String!, $paginationSize: Int = 1000, $paginationIndex: Int = 0) {
  getHorseClaims(memberExternalId: $memberExternalId, status: [Approved], paginationSize: $paginationSize, paginationIndex: $paginationIndex) {
    horse {
      name
      colour
      sex
      age
      horseCode
      ottInformation {
        paddockName
        profilePhoto {
          key
        }
      }
    }
    id
  }
}`;

export const reviewedFullCircleList = `
query reviewedFullCircleList($memberExternalId: String!, $paginationSize: Int = 1000, $paginationIndex: Int = 0) {
  getFullCircles(memberExternalId: $memberExternalId, status: [Reviewed], paginationSize: $paginationSize, paginationIndex: $paginationIndex) {
    memberHasClaimOnHorse
    memberFollowsHorse
    horse {
      name
      colour
      sex
      age
      horseCode
      ottInformation {
        paddockName
        profilePhoto {
          key
        }
        paddockNameVisibility {
          id
        }
        profilePhotoVisibility {
          id
        }
      }
    }
    id
  }
}`;

export const getRetrainingList = `
query getRetrainingThoroughbreds($memberExternalId: String!) {
	getMember(externalId: $memberExternalId) {
		retrainingData {
			retrainingThoroughbreds {
			horse {
				horseCode
				name
				sex
				age
				colour
				ottInformation {
				paddockName
				profilePhoto {
					key
				}
				}
			}
			}
		}
	}
}`;

export const isClaimedByMember = `
query isClaimedByMember($memberExternalId: String!, $horseCode: Int!) {
  isClaimedByMember( memberExternalId: $memberExternalId, horseCode: $horseCode)
}`;

export const getExistingNonRejectedForms = `
query getExistingNonRejectedForms($memberExternalId: String!, $horseCode: Int!) {
	getExistingNonRejectedForms(memberExternalId: $memberExternalId, horseCode: $horseCode) {
		formName
		nonRejectedFormExists
	}
}`;

export const getHorseProgram = `
query getHorseProgram($horseCode: Int!) {
  getHorseProfile(horseCode: $horseCode) {
	program {
		id
		value
	  }
	}
}`;

export const removeHorseUpdate = `
  mutation removeHorseUpdate($memberExternalId: String!, $postId: Int!, $reasonId: Int, $otherReason: String) {
    removeHorseUpdate(input: {memberExternalId: $memberExternalId, postId: $postId, reasonId: $reasonId, otherReason: $otherReason}) {
      id
      message
      success
    }
  }
`;

export const getHorseLocation = /* GraphQL */ `
	query getHorseLocation($horseCode: Int!) {
		getHorseProfile(horseCode: $horseCode) {
			location {
				address
				suburb
				postcode
				state
				country
				stabledDate
				lastUpdated
				isValidated
				source {
					id
					value
				}
				sourceOther
				address
			}
		}
	}
`;

export const getHorseProfile = `
query getHorseProfile($horseCode: Int!) {
  getHorseProfile(horseCode: $horseCode) {
    horse {
      name
      status
      sex
      age
      country
      colour
      sireName
      damName
      registrationNumber
      foalDate
      foalYear
      microChipNumber
      nameOverseas
      lifeNumber
      ottInformation {
        paddockName
        profilePhoto {
          key
        }
      }
      racingSummary {
        raceTypeSummary {
          bonus
          career
          prizeMoney
          starts
        }
        classSummary {
          class
          firsts
          prizeMoney
          seconds
          starts
          thirds
        }
      }
    }
    dateStabled
    location {
      address
      suburb
      postcode
      state
      country
      stabledDate
      lastUpdated
	  source {
        id
        value
      }
      sourceOther
    }
    program {
      id
      value
    }
    status {
      id
      status {
        id
        value
      }
      effectiveDate
    }
    claims {
      member {
        firstName
        lastName
      }
      id
      ownershipType
      createDatetime
    }
	unclaims {
	  	id
		member {
		firstName
		lastName
		}
		ownershipType
		created
	}
  }
}`;

export const listAccountDisableReasons = `
query listAccountDisableReasons {
  listAccountDisableReasons {
    id
    value
  }
}`;

export const listOttPrograms = `
query listOttPrograms {
  listOttPrograms {
    id
    value
  }
}`;

export const listOhepNominationRelationships = `
	query listOhepNominationRelationships {
		listOhepNominationRelationships {
			id
			value
		}
	}
`;

export const listRetrainingStatuses = `
query listRetrainingStatuses {
  listRetrainingStatuses {
    id
    value
  }
}`;

export const getOttPrograms = `
query getOttPrograms($role: [String] = ["ROLE_RV_EQUINE_WELFARE"]) {
	getOttPrograms(roles: $role) {
	  statuses {
		id
		value
	  }
	  id
	  value
	}
}`;

export const listhorseSourceLocation = `
query listHorseLocationSources {
	listHorseLocationSources {
	  id
	  value
	}
  }
  `;

export const saveHorseLocation = `
  mutation saveHorseLocation($input: SaveHorseLocationInput) {
    saveHorseLocation(input: $input)
  }
`;

export const saveHorseProgram = `mutation saveHorseProgram($memberExternalId: String, $horseCode: Int!, $programId: Int!){
  saveHorseProgram(memberExternalId: $memberExternalId, horseCode: $horseCode, programId: $programId)
}`;

export const getRetrainingThoroughbreds = `
query getRetrainingThoroughbreds($memberExternalId: String!) {
	getMember(externalId: $memberExternalId) {
		retrainingData {
			lastSubmitted {
				dateTime
			}
			retrainingThoroughbreds {
			horse {
				horseCode
				name
				ottInformation {
				paddockName
				profilePhoto {
					key
				}
				}
			}
			location {
				address
			}
			status {
				id
			status {
			id
				value
			}
			effectiveDate
			funding
			submitted {
				id
				submittedDateTime
			}
			}
			}
		}
	}
	listFundingTypes {
		id
		value
	}
}
`;

export const setOttProgramAndStatus = `
mutation setOttProgramAndStatus(
	$horseCode: Int!,
	$memberExternalId: String,
	$programId: Int!,
	$statusId: Int!,
	$asOfDate: AWSDate,
	$isEmailRetrainers: Boolean) {
		setOttProgramAndStatus(horseCode: $horseCode, memberExternalId: $memberExternalId, programId: $programId, statusId: $statusId, asOfDate: $asOfDate, isEmailRetrainers: $isEmailRetrainers){
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
}`;

export const saveRetrainerHorseStatus = `
mutation saveRetrainerHorseStatus($input: RetrainingHorseStatusInput) {
  saveStatus(input: $input)
}`;

export const saveRetrainerRehab = `
mutation saveRetrainerRehab($id: Int!, $injuryDetailsId: Int, $otherInjuryDetails: String, $rehabOtherReason: String, $rehabReasonId: Int!, $otherIllnessDetails: String, $illnessDetailsId: Int) {
  saveRetrainingRehabStatus(id: $id, injuryDetailsId: $injuryDetailsId, otherInjuryDetails: $otherInjuryDetails, rehabOtherReason: $rehabOtherReason, rehabReasonId: $rehabReasonId, otherIllnessDetails: $otherIllnessDetails, illnessDetailsId: $illnessDetailsId)
}`;

export const saveEuthanisedReason = `mutation saveEuthanisedReason($id: Int!, $reasonId: Int!, $specificCauses: [Int!], $specificCauseOther: String) {
  saveConcludedEuthanisedReason(id: $id, reasonId: $reasonId, specificCauses: $specificCauses, specificCauseOther: $specificCauseOther)
}`;

export const saveConcludedSoldRehomed = `
mutation saveConcludedSoldRehomed($rehoming : ConcludedSoldRehomedInput) {
saveConcludedSoldRehomed(input: $rehoming)
}`;

export const saveConcludedReturnToOwner = `mutation saveConcludedReturnToOwner($id: Int!, $reasonId: Int!, $reasonOther: String) {
	saveConcludedReturnToOwner(id: $id, reasonId: $reasonId, reasonOther: $reasonOther)
}`;

export const saveConcludedRetainingClientFunded = `mutation saveConcludedRetainingClientFunded($focusId: Int, $id: Int!, $otherFocus: String) {
	saveConcludedRetainingClientFunded(focusId: $focusId, id: $id, otherFocus: $otherFocus)
}`;

export const saveConcludedSoldRehomedDisciplines = `
mutation saveConcludedSoldRehomedDisciplines($id: Int!, $disciplineIds: [Int!]) {
saveConcludedSoldRehomedDisciplines(id: $id, disciplineIds: $disciplineIds)
}`;

// other added here
export const saveRetrainerOwnPurpose = `
mutation saveConcludedRetainingFocus($id: Int!, $focusId: Int, $otherFocus: String) {
  saveConcludedRetainingFocus(id: $id, focusId: $focusId, otherFocus: $otherFocus)
}`;

export const horseRetainerPageOptionsList = `
query horseRetainerPageOptionsList {
  listSpecialtyDisciplines {
    id
    value
  }
  listHorsePurposes {
    id
    value
  }
  listRehabReasons {
    id
    value
  }
  listSoldRehomePriceBrackets {
    id
    value
  }
  listSoldRehomePurposes {
    id
    value
  }
  listInjuryDetails {
    id
    value
  }
  listReturnToOwnerReasons {
    id
    value
  }
  listConclusionReasons {
    id
    value
  }
  listAckRetirementIllnessTypes {
	id
	value
  }
}`;

export const getRESETConcludedOptions = `
query ResetConcludedLookupData {
  listResetConcludedReasons {
    id
    value
  }
  listResetConcludedReturnedReasons {
    id
    value
  }
  listResetConcludedEuthanisedReasons {
    id
    value
  }
  listInjuryDetails {
    id
    value
  }
  listSpecialtyDisciplines {
    id
    value
  }
  listHorseRehomePurposes {
    id
    value
  }
  listShareInfoEuthanisedIllness {
    id
    value
  }
  listSoldRehomePriceBrackets {
	id
	value
}
}
`;

export const submitRetrainerForm = `
mutation submitRetrainerForm {
  submitRetrainingThoroughbredsReport
}`;

export const getAdminHorsePlacementListings = `
query getAdminHorsePlacementListings {
	getAdminHorsePlacementListings {
		assignedRetrainer
		latestRESETExpressQualification {
			id
			value
		}
		horse {
			age
			name
			horseCode
  		sex
      racingSummary {
        dateLastRaced
        raceTypeSummary {
          career
          starts
        }
      }
		}
		height
		suburb
		postcode
		riddenHistory
		disciplines {
			id
			value
		}
		disciplinesOther
		leftSidePhotos {
			created
			filename
			isApproved
			key
			type
		}
		rightSidePhotos {
			created
			filename
			isApproved
			key
			type
		}
		behindFullPhotos {
			created
			filename
			isApproved
			key
			type
		}
		faceOnPhotos {
			created
			filename
			isApproved
			key
			type
		}
		additionalPhotos {
			created
			filename
			isApproved
			key
			type
		}
		id
		horseOTTStatusID
		byMemberID
		removeReason {
			id
			value
		}
		eligibilityForBCTS
	}
}`;

export const getInterestedHorseListings = `
query getInterestedHorseListings($memberExternalId: String!) {
	getInterestedHorseListings(memberExternalId: $memberExternalId) {
		latestRESETExpressQualification {
			id
			value
		}
		horse {
			age
			name
			horseCode
  		sex
      racingSummary {
        dateLastRaced
        raceTypeSummary {
          career
          starts
        }
      }
		}
		height
		suburb
		postcode
		riddenHistory
		disciplines {
			id
			value
		}
		disciplinesOther
		leftSidePhotos {
			created
			filename
			isApproved
			key
			type
		}
		rightSidePhotos {
			created
			filename
			isApproved
			key
			type
		}
		behindFullPhotos {
			created
			filename
			isApproved
			key
			type
		}
		faceOnPhotos {
			created
			filename
			isApproved
			key
			type
		}
		additionalPhotos {
			created
			filename
			isApproved
			key
			type
		}
		id
		horseOTTStatusID
		eligibilityForBCTS
	}
}`;

export const getResetExpressHorsePlacement = `
	query getResetExpressHorsePlacement {
		getResetExpressHorsePlacement {
			latestRESETExpressQualification {
				id
				value
			}
			horse {
			  age
			  name
			  horseCode
        sex
        racingSummary {
          dateLastRaced
          raceTypeSummary {
            career
            starts
          }
        }
			}
			height
			suburb
			postcode
			riddenHistory
			disciplines {
			  id
			  value
			}
			disciplinesOther
			leftSidePhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			rightSidePhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			behindFullPhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			faceOnPhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			additionalPhotos {
				created
				filename
				isApproved
				key
				type
			  }
			id
			horseOTTStatusID
			eligibilityForBCTS
			assignedRetrainer
			assignedRetrainerDetails {
				id
				externalId
			}
		  }}`;

export const getAvailableHorsePlacement = `
	query getAvailableHorsePlacement {
		getAvailableHorsePlacement {
			latestRESETExpressQualification {
				id
				value
			}
			horse {
			  age
			  name
			  horseCode
        sex
        racingSummary {
          dateLastRaced
          raceTypeSummary {
            career
            starts
          }
        }
			}
			height
			suburb
			postcode
			riddenHistory
			disciplines {
			  id
			  value
			}
			disciplinesOther
			leftSidePhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			rightSidePhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			behindFullPhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			faceOnPhotos {
			  created
			  filename
			  isApproved
			  key
			  type
			}
			additionalPhotos {
				created
				filename
				isApproved
				key
				type
			  }
			id
			horseOTTStatusID
			eligibilityForBCTS
		  }}`;

export const getPublishedHorsePlacementListings = `
	query getHorsePlacementListings($memberExternalId: String!)  {
		getHorsePlacementListings(memberExternalId: $memberExternalId)  {
			member {
				externalId
				firstName
				lastName
			  }
			horse {
  			age
  			name
  			horseCode
        sex
        racingSummary {
          dateLastRaced
          raceTypeSummary {
            career
            starts
          }
        }
			}
			height
			suburb
			postcode
			riddenHistory
			disciplines {
			id
			value
			}
			disciplinesOther
			leftSidePhotos {
			created
			filename
			isApproved
			key
			type
			}
			rightSidePhotos {
			created
			filename
			isApproved
			key
			type
			}
			behindFullPhotos {
			created
			filename
			isApproved
			key
			type
			}
			faceOnPhotos {
			created
			filename
			isApproved
			key
			type
			}
			additionalPhotos {
				created
				filename
				isApproved
				key
				type
			}
			id
		}}`;

export const getAvailableHorseById = `
query getAvailableHorseById($id: Int!) {
	getHorsePlacementFormById(id: $id) {
	  address
	  contactNumber
	  currentOwners
	  currentRetrainers
	  isKnownToWindsuck{
		id
		value
	  }
	  member {
		email
		phone
	  }
	  riddenHistory
	  injuries {
		id
		value
	  }
	  injuriesDetails
	  height
	  horse {
		age
		damName
		sireName
		name
	  }
	  additionalPhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  behindFullPhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  disciplines {
		id
		value
	  }
	  disciplinesOther
	  faceOnPhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  id
	  isHelpingTransportCosts
	  leftSidePhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  retirementReason {
		id
		value
	  }
	  rightSidePhotos {
		created
		filename
		isApproved
		type
		key
	  }
	  temperament
	  suburb
	  postcode
	  otherDetails
	}
  }
  `;

export const getHorsePlacementForms = `
  query getHorsePlacementForms($status: [FormStatusEnum!]) {
  	getHorsePlacementForms(status: $status) {
  	  id
  	  member {
  		externalId
  		firstName
  		lastName
  	  }
  	  status
      horse {
        name
      }
  	}
  }`;

export const getOHEPNominationForms = `
  query getOhepNominationForms($status: [FormStatusEnum!]) {
	getOhepNominationForms(status: $status) {
  	  id
		status
		horse {
			name
		}
		member {
			firstName
			lastName
			id
		}
	}
  }`;

export const getOhepEoiForms = `
  query getOhepEoiForms($status: [FormStatusEnum!]) {
	getOhepEoiForms(status: $status) {
		guid
		status
		horseName
		member {
			firstName
			lastName
			id
		}
	}
  }`;

export const getHorseResetForms = `
  query getResetNominationForms($status: [FormStatusEnum]) {
	getResetNominationForms(status: $status) {
	  id
	  member {
		externalId
		firstName
		lastName
	  }
	  status
  	horse {
  	  name
      ottInformation {
        paddockName
      }
  	}
	}
}`;

export const getHorseResetWeeklyForms = `
  query getResetWeeklyProgressForms($status: [FormStatusEnum]) {
	getResetWeeklyProgressForms(status: $status) {
	  id
	  member {
		externalId
		firstName
		lastName
	  }
	  status
  	horse {
  	  name
      ottInformation {
        paddockName
      }
	  }
	  weekId
	}
}`;
export const getResetExpressRehabilitationConclusionForms = `
  query getResetExpressRehabilitationConclusionForms($status: [FormStatusEnum]) {
	getResetExpressRehabilitationConclusionForms(status: $status) {
	  id
	  member {
		externalId
		firstName
		lastName
	  }
	  status
	  lastUpdated
  	horse {
  	  name
      ottInformation {
        paddockName
      }
	  }
	  weekId
	}
}`;

export const getResetExpressRehomeAssessmentForms = `
  query getResetExpressRehomeAssessmentForms($status: [FormStatusEnum]) {
	getResetExpressRehomeAssessmentForms(status: $status) {
	  id
	  member {
		externalId
		firstName
		lastName
	  }
	  status
	  lastUpdated
  	horse {
  	  name
      ottInformation {
        paddockName
      }
	  }
	  weekId
	}
}`;

export const getHorseResetInitialForms = `
  query getResetInitialAssessmentForms($status: [FormStatusEnum]) {
	getResetInitialAssessmentForms(status: $status) {
	  id
	  member {
  		externalId
  		firstName
  		lastName
	  }
	  status
  	horse {
  	  name
      ottInformation {
        paddockName
      }
	  }
		lastUpdated
	}
}`;

export const getHorsePlacementFormsIdStatus = `
  query getHorsePlacementForms($memberExternalId: String,$status: [FormStatusEnum!]) {
  	getHorsePlacementForms(memberExternalId:$memberExternalId, status: $status) {
  	  id
  	  member {
  		externalId
  		firstName
  		lastName
  	  }
  	  status
      horse {
        name
      }
  	}
  }`;

export const getHorsePlacementFormById = `
  query getHorsePlacementFormById($id: Int!) {
	getHorsePlacementFormById(id: $id) {
		id
		horse {
		  horseCode
		  name
		  age
		  colour
      sex
      damName
			sireName
			foalDate
			microChipNumber
			horseBrand
			horseMarkings
		}
		member {
		  externalId
		  firstName
		  lastName
		  email
		  phone
		}
		confirmed
		sireName
		damName
		height
		retirementOtherReason
		relationshipToHorseOther
		retirementReason {
		  id
		  value
		}
	  address
	  suburb
	  postcode
	  state
	  country
	  isManualAddress
	  dateArrived
	  isHelpingTransportCosts
	  contactNumber
	  isKnownToWindsuck{
		id
		value
	  }
	  relationshipToHorse{
		  id
		  value
		}
	  relationshipToHorseOther
	  lengthOfRelationship{
		  id
		  value
		}
	  currentOwners
	  currentRetrainers
	  injuries{
		  id
		  value
		}
	  injuriesDetails
	  temperament
	  disciplines{
		  id
		  value
	  }
	  disciplinesOther
	  riddenHistory
	  otherDetails
	  faceOnPhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  leftSidePhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  rightSidePhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  behindFullPhotos {
		created
		filename
		isApproved
		key
		type
	  }
	  additionalPhotos {
		created
		filename
		isApproved
		key
		type
	  }
    vetReports {
      filename
      key
    }
	  isEligibleForRESET
	  termsDeclaration
	  registerDeclaration
	  contributeRetrainingCosts
	  status
	  created
	  lastUpdated
	  reviewNotes
	  reviewMessages
	  lastReviewed
	  eligibilityForBCTS
	  ageGreaterBCTS
	  behaviourBCTS
	  behaviourOthersBCTS
	  conformationalBCTS
	  conformationalDetailsBCTS
	  injuryBCTS
	  otherReasonBCTS
	  otherReasonDetailsBCTS
	  retirementCategory
	  vulnerabilityType
	  behaviourOthersRetirement
	  conformationalDetailsRetirement
	  otherEducationDetails
	  otherInjuryDetailsRetirement
	  otherIllnessDetailsRetirement
	  rehomingHistoryDetails
	  otherDetailsRetirement
	  windsucker
	  unbroken
	  currentInjuryOther
	  previousInjuryOther
	  habitsBCTS {
			id
			value
	    }
	  currentInjuries {
		id
		value
		}
	  previousInjuries {
		id
		value
		}
      habitsRetirement {
			id
			value
	    }
	  injuriesRetirement {
		id
		value
		}
	  illnessRetirement {
		id
		value
		}
	  education {
		id
		value
		}
	  reasonRetirement {
		id
		value
		}
	  behalfAdmin {
	  	firstName
	  	lastName
	    }
	  isInjuryImpactedHorse
	  isIllnessImpactedHorse
	  illnesses {
	  	id
	  	value
	  }
	  doesHorseHasCurrentVices
	  doesHorseHasPastVices
	  vicesCurrent {
	  	id
	  	value
	  }
	  vicesPast {
	  	id
	  	value
	  }
	  isWillingToArrange
	  illnessOther
	  injuryOther
	  habitsCurrentOther
	  habitsPastOther
	  isPastInjuryImpactedHorse
	  injuriesPast {
	  	id
	  	value
	  }
	  illnessAndInjuriesDetails
	  injuryPastOther
	  isImpactSoundness
	  }
  }
   `;

export const reviewHorsePlacementForm = `
mutation saveHorsePlacementFormStatus($horsePlacementFormStatusInput: HorsePlacementFormStatusInput!) {
		saveHorsePlacementFormStatus(
		input: $horsePlacementFormStatusInput
	) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }
  `;

export const reviewHorseResetForm = `
mutation saveResetNominationFormStatus($id: Int!
	$reviewNotes: String
	$reviewMessages: String
	$status: FormStatusEnum!) {
		saveResetNominationFormStatus(
		id: $id
		reviewNotes: $reviewNotes
		reviewMessages: $reviewMessages
		status: $status
	) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }
  `;

export const reviewHorseResetWeeklyForm = `
  mutation saveResetWeeklyProgressFormStatus($id: Int!
	  $reviewNotes: String
	  $reviewMessages: String
	  $status: FormStatusEnum!) {
		saveResetWeeklyProgressFormStatus(
		  id: $id
		  reviewNotes: $reviewNotes
		  reviewMessages: $reviewMessages
		  status: $status
	  ) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	  }
	}
	`;

export const saveResetExpressRehomeAssessmentFormStatus = `
	mutation saveResetExpressRehomeAssessmentFormStatus($id: Int!
		$reviewNotes: String
		$reviewReasons: String
		$status: FormStatusEnum!) {
		  saveResetExpressRehomeAssessmentFormStatus(
			id: $id
			reviewNotes: $reviewNotes
			reviewReasons: $reviewReasons
			status: $status
		) {
		  id
		  success
		  error {
			errorCode
			errorDetails
			errorMessage
		  }
		}
	  }
	  `;

export const saveResetExpressRehabilitationFormStatus = `
	mutation saveResetExpressRehabilitationFormStatus($id: Int!
		$reviewNotes: String
		$reviewReasons: String
		$status: FormStatusEnum!) {
		saveResetExpressRehabilitationFormStatus(
			id: $id
			reviewNotes: $reviewNotes
			reviewReasons: $reviewReasons
			status: $status
		) {
		id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
		}
	}
	`;

export const reviewHorseResetInitialForm = `
	mutation saveResetInitialAssessmentFormStatus($id: Int!
		$reviewNotes: String
		$reviewMessages: String
		$status: FormStatusEnum!) {
			saveResetInitialAssessmentFormStatus(
			id: $id
			reviewNotes: $reviewNotes
			reviewMessages: $reviewMessages
			status: $status
		) {
		  id
		  success
		  error {
			errorCode
			errorDetails
			errorMessage
		  }
		}
	  }
	  `;

export const reviewShareInformationForm = `
	mutation saveShareInformationFormStatus($id: Int!
		$reviewNotes: String
		$reviewMessages: String
		$status: FormStatusEnum!) {
			saveShareInformationFormStatus(
			id: $id
			reviewNotes: $reviewNotes
			reviewMessages: $reviewMessages
			status: $status
		) {
		  id
		  success
		  error {
			errorCode
			errorDetails
			errorMessage
		  }
		}
	  }
	  `;

export const getHorseInterestAndDetails = `
  query getHorseInterestAndDetails($horseCode: Int!, $memberExternalId: String!) {
	getHorsePlacementInterest(horseCode: $horseCode, memberExternalId: $memberExternalId) {
	  id
	  horse {
  		horseCode
  		name
  		damName
  		sireName
				racingSummary {
						dateLastRaced
						raceTypeSummary {
								bonus
								career
								prizeMoney
								starts
						}
				}
	  }
	  horsePlacementForm {
				latestRESETExpressQualification {
					id
					value
				}
				injuryOther
				isImpactSoundness
				isPastInjuryImpactedHorse
				injuriesPast{
					id
					value
				}
				injuryPastOther
				isIllnessImpactedHorse
				illnessOther
				illnesses{
					id
					value
				}
				illnessAndInjuriesDetails
				doesHorseHasCurrentVices
				vicesCurrent {
					id
					value
				}
				doesHorseHasPastVices
				vicesPast{
					id
					value
				}
				habitsCurrentOther
				habitsPastOther
				vetReports {
						filename
						isApproved
						key
						type
				}
				id
				status
				created
				lastUpdated
				lastReviewed
				address
				contactNumber
				currentOwners
				currentRetrainers
				isKnownToWindsuck{
					id
					value
				}
				member {
					id
					externalId
					email
					phone
					firstName
					lastName
				}
				riddenHistory
				assignedRetrainer
				assignedRetrainerDetails {
					id
					externalId
					email
					phone
					firstName
					lastName
				}
				injuries {
					id
					value
				}
				injuriesDetails
				height
				eligibilityForBCTS
				horse {
					age
					damName
					sireName
					name
								sex
				}
				additionalPhotos {
					created
					filename
					isApproved
					key
					type
				}
				behindFullPhotos {
					created
					filename
					isApproved
					key
					type
				}
				disciplines {
					id
					value
				}
				disciplinesOther
				faceOnPhotos {
					created
					filename
					isApproved
					key
					type
				}
			id
			isHelpingTransportCosts
			contributeRetrainingCosts
			leftSidePhotos {
				created
				filename
				isApproved
				key
				type
			}
			retirementReason {
				id
				value
			}
			retirementOtherReason
			removeReason {
				id
				value
			}
			removeReasonOther
			removeDetails
			rightSidePhotos {
				created
				filename
				isApproved
				type
				key
			}
			temperament
			suburb
			postcode
			otherDetails
		}
		contactOwner
		collectDate
		acknowledged
	  noLongerInterested {
		id
		value
	  }
	  noLongerInterestedDetails
	  status
	  created
	  lastUpdated
	  expires
	}
  }`;

export const noLongerInterested = `
  mutation noLongerInterested($horsePlacementInterestId : Int!, $noLongerInterestedId: Int, $details: String) {
	noLongerInterestedHorsePlacement(id: $horsePlacementInterestId, noLongerInterestedId: $noLongerInterestedId, details: $details)  {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const submitHorsePlacementInterest = `
  mutation submitHorsePlacementInterest($id: Int!, $contactOwner: Boolean, $collectDate: AWSDate, $acknowledged: Boolean) {
	submitHorsePlacementInterest(
		id: $id,
		contactOwner: $contactOwner,
		collectDate: $collectDate,
		acknowledged: $acknowledged) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const placeHorseInterest = `
  mutation placeHorseInterest(
	$horseCode: Int!,
	$horsePlacementFormId: Int!) {
	saveHorsePlacementInterest(
		horseCode: $horseCode,
		horsePlacementFormId: $horsePlacementFormId) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const listNoLongerInterestedOptions = `
  query listNoLongerInterestedOptions {
	listNoLongerInterestedOptions {
	  id
	  value
	}
  }`;

export const listHorsePlacementRemoveReasonOptions = `
	query listHorsePlacementRemoveReason {
		listHorsePlacementRemoveReason {
		id
		value
	}
}`;

export const removeHorsePlacementListing = `
  mutation removeHorsePlacementListing(
	$id: Int!,
	$removeDetails: String,
	$removeReasonId: Int!,
	$removeReasonOther: String) {
	removeHorsePlacementListing(
		id: $id,
		removeDetails: $removeDetails,
		removeReasonId: $removeReasonId,
		removeReasonOther: $removeReasonOther) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const getResetForm = `
query getResetForm($horseCode: Int!, $memberExternalId: String!) {
	getHorseProfile(horseCode: $horseCode) {
		horse {
			name
			sex
			colour
		}
		status {
			id
			status {
			  id
			  value
			}
			effectiveDate
		  }
	}
	getResetNominationForm(horseCode: $horseCode, memberExternalId: $memberExternalId) {
	id
	addressValidated
	otherReleventDetails
	iAmRegisteredManagingOwner
	iAmRegisteredOwner
	attachedProofOfPurchase
	proofOfPurchaseDocuments {
		filename
		key
		type
		created
	}
	sireName
	damName
	horseHeight
	member {
		email
		phone
		firstName
		lastName
		residentialAddress {
			address
			suburb
			postcode
			state
			country
		}
	  }
	horse {
		name
		colour
		age
	}
	disciplines
	{
	id
	value
	}
	retirementReason
	{
	id
	value
	}
	retirementReasonOther
	address
	suburb
	postcode
	state
	country
	residentialAddressValidated
	residentialAddress
	residentialSuburb
	residentialPostcode
	residentialState
	residentialCountry
	relationshipToHorse
	{
	id
	value
	}
	relationshipToHorseOther
	lengthOfRelationship
	{
	id
	value
	}
	currentOwners
	currentRetrainers
	isKnownToWindsuck
	{
	id
	value
	}
	injuries
	{
	id
	value
	}
	vetDocuments {
		filename
		key
		type
		created
	}
	injuriesDetails
	heritableDisorder
	disease
	accidents
	eyeIssues
	lameness
	headShaking
	vices
	breathingAbnormalities
	surgicalProcedures
	vaccinationsAdministered
	stranglesTetanusHerpesEquity
	breedingHistory
	temperamentDetails
	disciplinesOther
	riddenHistory
	faceOnPhotos{
		filename
		key
		type
		created
		}
	leftSidePhotos{
		filename
		key
		type
		created
		}
	rightSidePhotos{
		filename
		key
		type
		created
		}
	behindFullPhotos{
		filename
		key
		type
		created
		}
	additionalPhotos{
		filename
		key
		type
		created
		}
	rehomingDeclarationMinOfTwo
	rehomingDeclarationNoKnownBehaviours
	rehomingDeclarationNoKnownVeterinary
	programDeclerationResponsibleOwner
	programDeclerationContinueAsOwner
	programDeclerationRehomeIntention
	programDeclerationNotGuaranteeAcknowledgement
	programDeclerationRDAVAcknowledgement
	programDeclerationRacingVicDeterminesSalePrice
	programDeclerationEntryIntoReHomed
	programDeclerationReadPrivacyStatement
	consentToVeterinaryExamination
	isHelpingTransportCosts
	status
	created
	lastUpdated
	reviewNotes
	reviewMessages
	lastReviewed
	}
  }`;

export const saveResetForm = `
  mutation MyMutation($resetFormData: ResetNominationFormInput!) {
	saveResetNominationForm(input: $resetFormData) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const saveOhepNominationForm = `
	mutation saveOhepNominationForm($ohepNominationFormData: OhepNominationFormInput!) {
		saveOhepNominationForm(input: $ohepNominationFormData) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveOhepNominationFormStatus = `
	mutation saveOhepNominationFormStatus ($id: Int! $status: FormStatusEnum!) {
		saveOhepNominationFormStatus (id: $id, status: $status) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveOhepNominationFile = `
	mutation SaveOhepNominationFile($filename: String $horseCode: Int! $key: String! $type: OhepNominationFileTypeEnum, $memberExternalId: String) {
		saveOhepNominationFile(filename: $filename horseCode: $horseCode key: $key type: $type, memberExternalId: $memberExternalId) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveOhepNTPFile = `
	mutation saveOhepNTPFile($filename: String $id: Int! $key: String! $type: OhepNTPFileTypeEnum) {
		saveOhepNTPFile(filename: $filename id: $id key: $key type: $type) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveOhepEoiForm = `
	mutation saveOhepEoiForm($input: OhepEoiFormInput!) {
		saveOhepEoiForm(input: $input) {
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveOhepNTPForm = `
	mutation saveOhepNTPForm($input: OhepNTPFormInput!) {
		saveOhepNTPForm(input: $input) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveOhepEoiFormStatus = `
	mutation saveOhepEoiFormStatus ($guid: String! $reviewNotes: String $status: FormStatusEnum!) {
		saveOhepEoiFormStatus (guid: $guid, reviewNotes: $reviewNotes status: $status) {
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const deleteOhepAdminFile = /* GraphQL */ `
	mutation deleteOhepAdminFile($key: String!) {
		deleteOhepAdminFile(key: $key)
	}
`;

export const deleteOhepEoiForm = /* GraphQL */ `
	mutation deleteOhepEoiForm($guid: String!) {
		deleteOhepEoiForm(guid: $guid)
	}
`;

export const deleteOhepNTPForm = /* GraphQL */ `
	mutation deleteOhepNTPForm($id: Int!) {
		deleteOhepNTPForm(id: $id)
	}
`;

export const saveOhepEoiFile = `
	mutation SaveOhepEoiFile($filename: String $guid: String! $key: String! $type: OhepEoiFileTypeEnum) {
		saveOhepEoiFile(filename: $filename guid: $guid key: $key type: $type) {
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const deleteOhepEoiFile = /* GraphQL */ `
	mutation deleteOhepEoiFile($key: String!) {
		deleteOhepEoiFile(key: $key)
	}
`;

export const resetFormUploadImage = `
  mutation saveResetNominationImage(
	$filename: String!
	$horseCode: Int!
	$key: String!
	$type: HorsePlacementImageTypeEnum!
	$memberExternalId: String
) {
	saveResetNominationImage(
		filename: $filename
		horseCode: $horseCode
		key: $key
		type: $type
		memberExternalId: $memberExternalId
	) {
		id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
	}
}`;

export const updateResetFormStatus = `
  mutation updateResetFormStatus(
	$id: Int!
	$status: FormStatusEnum!
  ) {
  saveResetNominationFormStatus(
  id: $id,
  status: $status) {
    id
    success
    error {
      errorCode
      errorDetails
      errorMessage
    }
  }
}`;

export const updateShareInformationFormStatus = `
  mutation saveShareInformationFormStatus(
	$id: Int!
	$status: FormStatusEnum!
  ) {
    saveShareInformationFormStatus(id: $id, status: $status) {
      id
      success
      error {
        errorMessage
        errorCode
        errorDetails
      }
    }
}`;

export const deleteResetForm = /* GraphQL */ `
	mutation deleteResetNominationForm($id: Int!) {
		deleteResetNominationForm(id: $id)
	}
`;

export const deleteOhepNominationForm = /* GraphQL */ `
	mutation deleteOhepNominationForm($id: Int!) {
		deleteOhepNominationForm(id: $id)
	}
`;

export const getResetFormById = /* GraphQL */ `
	query getResetFormById($id: Int!) {
		getAdminMembers {
			id
			externalId
			firstName
			lastName
		}
		listResetAdminStatuses {
			id
			value
		}
		listResetVetCategories {
			id
			value
		}
		listResetRejectReasons {
			id
			value
		}
		listInjuryDetails {
			id
			value
		}
		listShareInfoEuthanisedIllness {
			id
			value
		}
		getResetNominationFormById(id: $id) {
			lastUpdated
			accidents
			additionalPhotos {
				created
				filename
				isApproved
				key
				type
			}
			attachedProofOfPurchase
			proofOfPurchaseDocuments {
				created
				filename
				isApproved
				key
				type
			}
			behindFullPhotos {
				created
				filename
				isApproved
				key
				type
			}
			breathingAbnormalities
			breedingHistory
			consentToVeterinaryExamination
			currentOwners
			currentRetrainers
			otherReleventDetails
			address
			suburb
			postcode
			state
			country
			residentialAddress
			residentialSuburb
			residentialPostcode
			residentialState
			residentialCountry
			residentialAddressValidated
			damName
			disciplines {
				id
				value
			}
			disciplinesOther
			disease
			eyeIssues
			faceOnPhotos {
				created
				filename
				isApproved
				type
				key
			}
			headShaking
			heritableDisorder
			horse {
				age
				colour
				name
				ottInformation {
					paddockName
				}
				sex
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
				horseCode
			}
			horseHeight
			iAmRegisteredManagingOwner
			iAmRegisteredOwner
			id
			injuries {
				id
				value
			}
			vetDocuments {
				created
				filename
				isApproved
				key
				type
			}
			injuriesDetails
			isHelpingTransportCosts
			isKnownToWindsuck {
				id
				value
			}
			lameness
			leftSidePhotos {
				created
				filename
				isApproved
				key
				type
			}
			lengthOfRelationship {
				id
				value
			}
			member {
				businessPhone
				dateOfBirth
				email
				state
				lastName
				firstName
				phone
				externalId
			}
			programDeclerationContinueAsOwner
			programDeclerationEntryIntoReHomed
			programDeclerationNotGuaranteeAcknowledgement
			programDeclerationRDAVAcknowledgement
			programDeclerationRacingVicDeterminesSalePrice
			programDeclerationReadPrivacyStatement
			programDeclerationRehomeIntention
			programDeclerationResponsibleOwner
			rehomingDeclarationMinOfTwo
			rehomingDeclarationNoKnownBehaviours
			rehomingDeclarationNoKnownVeterinary
			relationshipToHorse {
				id
				value
			}
			relationshipToHorseOther
			retirementReason {
				id
				value
			}
			retirementReasonOther
			reviewMessages
			reviewNotes
			riddenHistory
			rightSidePhotos {
				created
				filename
				isApproved
				key
				type
			}
			temperamentDetails
			vices
			vaccinationsAdministered
			surgicalProcedures
			status
			sireName
			adminData {
				adminAction {
					admin {
						externalId
					}
					adminStatus {
						id
					}
					formComplete
				}
				ewdAccept
				ewdComments
				ewdRecommendationDate
				ewgmComments
				ewgmProceedToVetAssessment
				ewgmRecommendationDate
				illness {
					id
				}
				ilnessesOther
				recommendation
				injuries {
					id
					value
				}
				injuriesOther
				rejectReason {
					id
				}
				rejectReasonOther
				rejectingApplication
				vetAssessmentDate
				vetAssessmentSummary
				vetAssessment {
					id
				}
				vetUploads {
					created
					filename
					key
					type
				}
				#Retirement Category
				retirementCategory
				vulnerabilityType
				behaviourOthersRetirement
				conformationalDetailsRetirement
				otherEducationDetails
				otherInjuryDetailsRetirement
				otherIllnessDetailsRetirement
				rehomingHistoryDetails
				otherDetailsRetirement
				habitsRetirement {
					id
					value
				}
				injuriesRetirement {
					id
					value
				}
				illnessRetirement {
					id
					value
				}
				education {
					id
					value
				}
				reasonRetirement {
					id
					value
				}
			}
			behalfAdmin {
				firstName
				lastName
			}
			lastReviewed
		}
	}
`;

export const getResetExpressRehomeAssessmentFormById = /* GraphQL */ `
	query getResetExpressRehomeAssessmentFormById($id: Int!) {
		getResetExpressRehomeAssessmentFormById(id: $id) {
			created
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			horse {
				horseCode
				name
				sex
				colour
				age
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			id
			lastReviewed
			lastUpdated
			member {
				businessPhone
				dateOfBirth
				email
				state
				lastName
				firstName
				phone
			}
			priceRange {
				id
				value
			}
			horseTemperamentDesc
			horseMovementDesc
			groundWorkdOtherDetails
			isHorseTieUpQuietly
			isHorseTieUpQuietlyDetails
			doesHorseStand
			doesHorseStandDetails
			riddenTrainingExposuredOtherDetails
			riddenExposureRetrainingdOtherDetails
			hasHorseReceivedTraining
			hasHorseReceivedTrainingDetails
			hasHorseTransported
			hasHorseTransportedDetails
			isHorseSound
			isHorseSoundDetails
			hasHorseBeenLame
			hasHorseBeenLameDetails
			hasHorseBeenAttentded
			hasHorseBeenAttentdedDetails
			hasHorseReceivedMedication
			hasHorseReceivedMedicationDetails
			isHorsedGood
			isHorsedGoodDetails
			isHorseShod
			isHorseShodDetails
			hasHorseBeenTreatedDentist
			hasHorseBeenTreatedDentistDetails
			disciplinesdOtherDetails
			riderTypedOtherDetails
			isRequiredOngoingAttention
			isRequiredOngoingAttentionDetails
			groundWork {
				id
				value
			}
			riddenTrainingExposure {
				id
				value
			}
			riddenExposureRetraining {
				id
				value
			}
			riderTypes {
				id
				value
			}
			disciplines {
				id
				value
			}
			reviewReasons
			reviewNotes
			status
			weekId
		}
	}
`;

export const getResetWeeklyFormById = `
query getResetWeeklyFormById($id: Int!) {
	getResetWeeklyProgressFormById(id: $id) {
		created
		extraAttention
		hasTreatements
		hasTreatementsDetails
		horse {
			age
			colour
			name
      ottInformation {
        paddockName
      }
		}
		id
		inWork
		inWorkDetails
		isConcerned
		isConcernedDetails
		lastReviewed
		lastUpdated
		member {
			businessPhone
			dateOfBirth
			email
			state
			lastName
			firstName
			phone
		}
		notInWorkReason
		plansForNextWeek
		progressDetails
		progressPhotos {
			created
			filename
			key
			reportId
			reportType
			key
		}
		reportConcerns
		reviewMessages
		reviewNotes
		status
		weekId
		addImageForSale
		imageForSale {
			filename
			key
		}
	}
  }
  `;

export const getResetInitialFormById = `
query getResetInitialFormById($id: Int!) {
	getResetInitialAssessmentFormById(id: $id) {
		created
		dentist
		farrier
		feedingRequirementsPlan
		groundwork
		horse {
			age
			colour
			name
      ottInformation {
        paddockName
      }
		}
		id
		lastReviewed
		lastUpdated
		leftSidePhotos {
			created
			filename
			key
			reportId
			reportType
			type
		}
		member {
			businessPhone
			dateOfBirth
			email
			state
			lastName
			firstName
			phone
		}
		notContinueDetails
		otherHusbandryInfo
		overallCondition
		possibleRepurposing
		retrainingTimeframe
		reviewMessages
		reviewNotes
		rightSidePhotos {
			created
			filename
			key
			reportId
			reportType
			type
		}
		status
		temperament
		weekId
		worming
		dateOfAssessment
	}
  }
  `;

export const getOhepNominationFormById = `
	Query GetOhepNominationFormById($id: Int!) {
		getOhepNominationForm(horseCode: $horseCode) {
			acknowledgedConsentToParticipate
			acknowledgedOwnerConfirmation
			acknowledgedOwnerIndemnity
			acknowledgedOwnershipBinding
			acknowledgedOwnershipWarranty
			acknowledgedPrivacy
			advertisedToRvDate
			currentAddress
			currentAddressDurationMonths
			currentAddressDurationYears
			currentAddressValidated
			currentCountry
			currentPostcode
			currentState
			currentSuburb
			dangerousBehaviourDetails
			thoroughbredOverTwentyDetails
			evidenceOfDangerousBehaviourFiles {
				created
				filename
				key
				type
			}
			horseCode
			id
			leftSidePhotos {
				created
				filename
				key
				type
			}
			memberId
			otherFiles {
				created
				filename
				key
				type
			}
			previousAddress
			previousAddressValidated
			previousCountry
			previousPostcode
			previousState
			previousSuburb
			reasonsForNomination {
				id
				value
				description
				label
			}
			rehomingAttemptDetails
			rehomingAttemptDetailsDate
			relationshipToHorse {
				id
				value
			}
			relationshipToHorseOther
			rightSidePhotos {
				created
				filename
				key
				type
			}
			rspcaNoticeToComplyFiles {
				created
				filename
				key
				type
			}
			statuaryDeclarationFiles {
				created
				filename
				key
				type
			}
			veterinaryCertificateFiles {
				created
				filename
				key
				type
			}
			status
		}
	}
`;

export const getShareInformationFormById = `
  query getShareInformationFormById($id: Int!) {
	getShareInformationFormById(id: $id) {
		adminAction {
			id
			formId
			admin {
				id
				lastName
				firstName
				externalId
			}
			adminOutcome {
				id
				value
			}
			adminStatus {
				id
				value
			}
		}
		address
		country
		id
		info
		isStatusCorrect
		lastSeenDate
		postcode
		reviewMessages
		reviewNotes
		state
		status
		suburb
		uploads {
		  created
		  filename
		  key
		}
		relationship {
		  value
		}
		relationshipOther
		horse {
      name
      sex
		  age
		  colour
		  status
      country
			damName
			sireName
			foalDate
			microChipNumber
			horseBrand
			horseMarkings
		}
		member {
		  businessPhone
		  email
		  lastName
		  firstName
		  phone
		}
		newStatus {
			value
		}
		causeOfDeath {
			value
		}
		naturalDateOfDeath
		retiredStatusDate
		retiredSuburb
		retiredPostcode
		otherStatusDate
		otherStatusPostcode
		otherStatusSuburb
		informationToUpdate
		euthanisedReason {
			value
		}
		deceasedOtherDate
		deceasedOtherInfo
		deceasedUnknownDate
		euthanisedDate
		illnesses {
			id
			value
		}
		euthanisedIllnessOther
		injuries {
			id
			value
		}
		euthanisedInjuryOther
		euthanisedUnsuitable {
			value
		}
		euthanisedUnsuitableOther
		euthanisedOther {
			value
		}
		knackeryDate
		knackeryPostcode
		knackerySuburb
		causeOfDeath {
			value
		}
		ohepDate
		confirmAccurateInfo
		otherStatusValue
		horse_code
		relationshipId
		updateLocationAndStatus
	  }
	}
	`;

export const deleteOhepNominationFile = /* GraphQL */ `
	mutation deleteOhepNominationFile($key: String!) {
		deleteOhepNominationFile(key: $key)
	}
`;

export const deleteOhepNTPFile = /* GraphQL */ `
	mutation deleteOhepNTPFile($key: String!) {
		deleteOhepNTPFile(key: $key)
	}
`;

export const deleteResetNominationImage = /* GraphQL */ `
	mutation deleteResetNominationImage($key: String!) {
		deleteResetNominationImage(key: $key)
	}
`;

export const dismissNotification = `
mutation dismissNotification($notificationId: Int!) {
	dismissNotification(id: $notificationId)
  }`;

export const getAcknowledgedFosterHorses = /* GraphQL */ `
	query getAcknowledgedFosterHorses($memberExternalId: String!) {
		getAcknowledgedFosterHorses(memberExternalId: $memberExternalId) {
			horseCode
			horseName
			lastSubmittedForm {
				id
				submittedDate
				type
				weekId
			}
			location {
				address
				postcode
				stabledDate
			}
			currentStatus {
				id
				value
			}
			horse {
				ottInformation {
					paddockName
					profilePhoto {
						key
					}
				}
			}
			effectiveDate
		}
	}
`;

export const getResetExpressHorses = /* GraphQL */ `
	query getResetExpressHorses($memberExternalId: String!) {
		getResetExpressHorses(memberExternalId: $memberExternalId) {
			horseCode
			horseName
			lastSubmittedForm {
				id
				submittedDate
				type
				weekId
			}
			location {
				address
				postcode
				stabledDate
			}
			currentStatus {
				id
				value
			}
			horse {
				ottInformation {
					paddockName
					profilePhoto {
						key
					}
				}
			}
			effectiveDate
		}
	}
`;

export const getAcknowledgedRetirementHorses = `
  query getAcknowledgedRetirementHorses($memberExternalId: String!) {
	getAcknowledgedRetirementHorses(memberExternalId: $memberExternalId) {
	  horseCode
	  horseName
	  lastSubmittedForm {
	    id
	    submittedDate
	    type
	  }
	  location {
	    address
	    postcode
		stabledDate
	  }
	  currentStatus {
		id
		value
	  }
	  horse {
		ottInformation {
		  paddockName
		  profilePhoto {
			key
		  }
		}
	  }
	effectiveDate
    }
  }`;

export const getHorsesByProgram = /* GraphQL */ `
	query getHorsesByProgram($memberExternalId: String!, $programId: Int!) {
		getHorsesByProgram(
			memberExternalId: $memberExternalId
			programId: $programId
		) {
			horseCode
			name
		}
	}
`;

export const getResetHorses = `
  query getResetHorses($memberExternalId: String!) {
	getResetHorses(memberExternalId: $memberExternalId) {
	  horseCode
	  horseName
	  lastSubmittedForm {
	    id
	    submittedDate
	    type,
					weekId
	  }
	  location {
	    address
	    postcode
	  }
	  currentStatus {
		id
		value
	  }
	  horse {
		ottInformation {
		  paddockName
		  profilePhoto {
			key
		  }
		}
	  }
    }
  }`;

export const getResetInitialAssessmentForm = `
  query getResetInitialAssessmentForm($memberExternalId: String!, $horseCode: Int!) {
	getResetInitialAssessmentForm(memberExternalId: $memberExternalId, horseCode: $horseCode) {
		id
		created
		dentist
		farrier
		feedingRequirementsPlan
		groundwork
		id
		lastReviewed
		lastUpdated
		leftSidePhotos {
		  key
		  filename
		}
		notContinueDetails
		otherHusbandryInfo
		overallCondition
		possibleRepurposing
		retrainingTimeframe
		reviewMessages
		reviewNotes
		rightSidePhotos {
		  key
		  filename
		}
		status
		temperament
		weekId
		worming
		dateOfAssessment
    }
  }`;

export const getResetWeeklyProgressForm = `
  query getResetWeeklyProgressForm($memberExternalId: String!, $horseCode: Int!) {
	getResetWeeklyProgressForm(memberExternalId: $memberExternalId, horseCode: $horseCode) {
		id
		created
		extraAttention
		hasTreatements
		hasTreatementsDetails
		inWork
		inWorkDetails
		isConcerned
		isConcernedDetails
		lastReviewed
		lastUpdated
		notInWorkReason
		plansForNextWeek
		progressDetails
		reportConcerns
		reviewMessages
		progressPhotos {
		  filename
		  key
		}
		reviewNotes
		status
		weekId
    }
  }`;

export const getAcknowledgedRetirementQuarterlyAssessmentForm = `
  query getAcknowledgedRetirementQuarterlyAssessmentForm($memberExternalId: String!, $horseCode: Int!, $quarterNumber: Int!, $year: Int!) {
	getAcknowledgedRetirementQuarterlyAssessmentForm(memberExternalId: $memberExternalId, horseCode: $horseCode, quarterNumber: $quarterNumber, year: $year) {
		id
		memberId
		quarterNumber {
			id
			value
		}
		dateOfAssessment
		hasHorseStatusChanged
		newStatus {
			id
			value
		}
		progressPhotos {
			filename
			key
		  }
		agistmentHasPhysicalHealthChanged
		agistmentChangesToPhysicalHealth {
			id
			value
		}
		agistmentChangesToPhysicalHealthOther
		agistmentHasBehaviourChanged
		agistmentChangesToBehaviourOther
		worming
		wormingDateCompleted
		farrier
		farrierDateCompleted
		dentistTreatment
		dentistTreatmentDateCompleted
		husbandryOther
		rehabReason {
			id
			value
		}
		injuryType {
			id
			value
		}
		illnessType {
			id
			value
		}
		rehabReasonOther
		rehabDateCommenced
		treatmentSinceLastReport
		deceasedCauseOfDeath {
			id
			value
		}
		deceasedDateOfDeath
		deceasedReasonOfDeath {
			id
			value
		}
		deceasedInjuryDetailsOther
		deceasedIllnessDetailsOther
		deceasedReasonForDeathOther {
			id
			value
		}
		deceasedKnackeryPostcode
		deceasedKnackerySuburb
		informationAboutDeath
		hasOwnerBeenNotified
		finalCommentsNotes
		status
    }
  }`;

export const getFosterWeeklyAssessmentForm = /* GraphQL */ `
	query getFosterWeeklyAssessmentForm(
		$memberExternalId: String!
		$horseCode: Int!
		$weekId: Int!
	) {
		getFosterWeeklyAssessmentForm(
			memberExternalId: $memberExternalId
			horseCode: $horseCode
			weekId: $weekId
		) {
			id
			memberId
			# member {
			# 	businessPhone
			# 	firstName
			# 	lastName
			# 	dateOfBirth
			# 	email
			# 	residentialAddress {
			# 		address
			# 		country
			# 		postcode
			# 		state
			# 		suburb
			# 	}
			# }
			weekId
			horseCode
			# horse {
			# 	horseCode
			# 	name
			# 	ottInformation {
			# 		paddockName
			# 	}
			# 	location {
			# 		address
			# 		suburb
			# 		postcode
			# 		state
			# 	}
			# }
			dateOfAssessment
			hasHorseStatusChanged
			newStatus {
				id
				value
			}
			progressPhotos {
				filename
				key
			}
			rehabReason {
				id
				value
			}
			rehabReasonOther
			rehabDateCommenced
			injuryType {
				id
				value
			}
			deceasedInjuryDetailsOther
			illnessType {
				id
				value
			}
			deceasedIllnessDetailsOther
			horseProgressUpdate
			horseIssuesEncountered
			feedingPlan
			physicalConcerns
			physicalConcernDetails
			vetTreatmentDetails
			vetTreatment
			concludedReason {
				id
				value
			}
			deceasedDateOfDeath
			deceasedReasonOfDeath {
				id
				value
			}
			deceasedReasonForDeathOther {
				id
				value
			}
			unsuitableReason {
				id
				value
			}
			unsuitableReasonOther
			dateSold
			priceBracket {
				id
				value
			}
			purpose {
				id
				value
			}
			purposeOther
			selectedDisciplines {
				id
				value
			}
			selectedDisciplinesOther
			postcode
			email
			fullName
			dateCommenced
			isRetrainHorse
			isFullCircle
			assistanceType {
				id
				value
			}
			spellingExpectedTimeframe
			otherConcerns
			status
			hasHorseBeenInWorkThisWeek
			detailsOfWorkSessionThisWeek
			keyProgressAchievementsToDate
			areaNeedExtraWorkAttention
			workPlanForTheComingWeek
			hasHorseBeenInWorkThisWeekExplaination
			anyLamenessOrPhysicalHealthConcern
			horseRequiredVetAttention
			requiredVetAttentionExplaination
			physicalHealthConcern
			dateFoundationTraining
		}
	}
`;

export const getResetExpressRehomeAssessmentForm = /* GraphQL */ `
	query getResetExpressRehomeAssessmentForm(
		$memberExternalId: String!
		$horseCode: Int!
		$weekId: Int!
	) {
		getResetExpressRehomeAssessmentForm(
			memberExternalId: $memberExternalId
			horseCode: $horseCode
			weekId: $weekId
		) {
			id
			weekId
			priceRange {
				id
				value
			}
			horseTemperamentDesc
			horseMovementDesc
			groundWorkdOtherDetails
			isHorseTieUpQuietly
			isHorseTieUpQuietlyDetails
			doesHorseStand
			doesHorseStandDetails
			riddenTrainingExposuredOtherDetails
			riddenExposureRetrainingdOtherDetails
			hasHorseReceivedTraining
			hasHorseReceivedTrainingDetails
			hasHorseTransported
			hasHorseTransportedDetails
			isHorseSound
			isHorseSoundDetails
			hasHorseBeenLame
			hasHorseBeenLameDetails
			hasHorseBeenAttentded
			hasHorseBeenAttentdedDetails
			hasHorseReceivedMedication
			hasHorseReceivedMedicationDetails
			isHorsedGood
			isHorsedGoodDetails
			isHorseShod
			isHorseShodDetails
			hasHorseBeenTreatedDentist
			hasHorseBeenTreatedDentistDetails
			disciplinesdOtherDetails
			riderTypedOtherDetails
			isRequiredOngoingAttention
			isRequiredOngoingAttentionDetails
			groundWork {
				id
				value
			}
			riddenTrainingExposure {
				id
				value
			}
			riddenExposureRetraining {
				id
				value
			}
			riderTypes {
				id
				value
			}
			disciplines {
				id
				value
			}
			created
			lastUpdated
			lastReviewed
			reviewNotes
			reviewReasons
			lastApproved
			lastSubmitted
			status
		}
	}
`;

export const getResetExpressRehabilitationConclusionFormById = /* GraphQL */ `
	query getResetExpressRehabilitationConclusionFormById($id: Int!) {
		getResetExpressRehabilitationConclusionFormById(id: $id) {
			id
			weekId
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			horse {
				horseCode
				name
				sex
				colour
				age
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			weeksToRetrainForPriorRehoming {
				id
				value
			}
			horseTemperamentDesc
			horseTemperamentDesc
			horseMovementDesc
			isHorseGoodForFarrier
			isHorseGoodForFarrierDetails
			isHorseShod
			isHorseShodDetails
			hasHorseBeenTreatedDentist
			hasHorseBeenTreatedDentistDetails
			isHorseReceivedMedication
			isHorseReceivedMedicationDetails
			hasHorseReceivedAnyFormOfExercise
			hasHorseReceivedAnyGroundWork
			groundWorkOtherDetails
			isHorseSound
			isHorseSoundDetails
			isHorseReadyToCommenceRiddenWork
			isHorseReadyToCommenceRiddenWorkDetails
			hasHorseBeenClearedByVet
			hasHorseBeenClearedByVetDetails
			vetClearedDocuments {
				filename
				key
				type
				created
			}
			horseDisciplineDetails
			isRequireOngoingAttention
			isRequireOngoingAttentionDetails
			requireOngoingAttentionDocuments {
				filename
				key
				type
				created
			}
			groundWork {
				id
				value
			}
			disciplines {
				id
				value
			}
			rehabilitationReportFormExercise {
				id
				value
			}
			created
			lastUpdated
			lastReviewed
			reviewNotes
			reviewReasons
			lastApproved
			lastSubmitted
			status
		}
	}
`;

export const getResetExpressRehabilitationConclusionForm = /* GraphQL */ `
	query getResetExpressRehabilitationConclusionForm(
		$memberExternalId: String!
		$horseCode: Int!
		$weekId: Int!
	) {
		getResetExpressRehabilitationConclusionForm(
			memberExternalId: $memberExternalId
			horseCode: $horseCode
			weekId: $weekId
		) {
			id
			weekId
			weeksToRetrainForPriorRehoming {
				id
				value
			}
			horseTemperamentDesc
			horseTemperamentDesc
			horseMovementDesc
			isHorseGoodForFarrier
			isHorseGoodForFarrierDetails
			isHorseShod
			isHorseShodDetails
			hasHorseBeenTreatedDentist
			hasHorseBeenTreatedDentistDetails
			isHorseReceivedMedication
			isHorseReceivedMedicationDetails
			hasHorseReceivedAnyFormOfExercise
			hasHorseReceivedAnyGroundWork
			groundWorkOtherDetails
			isHorseSound
			isHorseSoundDetails
			isHorseReadyToCommenceRiddenWork
			isHorseReadyToCommenceRiddenWorkDetails
			hasHorseBeenClearedByVet
			hasHorseBeenClearedByVetDetails
			vetClearedDocuments {
				filename
				key
				type
				created
			}
			horseDisciplineDetails
			isRequireOngoingAttention
			isRequireOngoingAttentionDetails
			requireOngoingAttentionDocuments {
				filename
				key
				type
				created
			}
			groundWork {
				id
				value
			}
			disciplines {
				id
				value
			}
			rehabilitationReportFormExercise {
				id
				value
			}
			created
			lastUpdated
			lastReviewed
			reviewNotes
			reviewReasons
			lastApproved
			lastSubmitted
			status
		}
	}
`;

export const saveResetInitialAssessmentForm = `
  mutation saveResetInitialAssessmentForm($input: ResetInitialAssessmentFormInput!) {
	saveResetInitialAssessmentForm(input: $input) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
  }`;

export const saveResetReportingImage = `
mutation saveResetReportingImage(
	$filename: String!
	$key: String!
	$reportType: ReportTypeEnum!
	$reportId: Int!
	$type: ReportImageTypeEnum!
  ) {
	saveResetReportingImage(
		filename: $filename
		key: $key
		reportType: $reportType
		reportId: $reportId
		type: $type
	) {
		id
		success
		error {
		errorCode
		errorDetails
		errorMessage
		}
	}
}`;

export const submitResetReportsForReview = `
  mutation submitResetReportsForReview {
	submitResetReportsForReview
  }`;

export const getResetWeeklyProgressFormByWeekId = `
  query getResetWeeklyProgressForm($horseCode: Int!, $memberExternalId: String!, $weekId: Int!) {
	getResetWeeklyProgressForm(horseCode: $horseCode, memberExternalId: $memberExternalId, weekId: $weekId){
		created
		extraAttention
		hasTreatements
		hasTreatementsDetails
		id
		inWork
		inWorkDetails
		isConcerned
		isConcernedDetails
		lastReviewed
		lastUpdated
		notInWorkReason
		plansForNextWeek
		progressDetails
		reportConcerns
		reviewMessages
		reviewNotes
		status
		weekId
		progressPhotos {
		  filename
		  key
		}
		newResetStatus {
			id
			value
		}
		addImageForSale
		imageForSale {
			filename
			key
		}
	}
  }`;

export const saveResetWeeklyProgressForm = `
  mutation saveResetWeeklyProgressForm($input: ResetWeeklyProgressFormInput!) {
	saveResetWeeklyProgressForm(input: $input) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
  }`;

export const getAcknowledgedRetrainerReferences = `
  query getAcknowledgedRetrainerReferences {
	listAckRetrainerEmplomentTypes {
	  id
	  value
	}
	listAckRetrainerStaffRoles {
	  id
	  value
	}
	listAckRetrainerSourceForHorseTypes {
	  id
	  value
	}
	listAckRetrainerNumberOfHorses {
	  id
	  value
	}
	listAckRetrainerLicenseTypes {
	  id
	  value
	}
	listSpecialtyDisciplines {
		id
		value
	}
	listRaceClubMemberships {
		id
		name
	  }
	  listMemberships {
		id
		name
	}
  }
  `;

export const getAcknowledgedRetirementQuarterlyAssessmentReferences = `
  query getAcknowledgedRetirementReferences {
	listAckRetirementQuarterTypes {
	  id
	  value
	}
	listAckRetirementChangesToHealthTypes {
	  id
	  value
	}
	listAckRetirementRehabReasonTypes {
	  id
	  value
	}
	listAckRetirementInjuryTypes {
	  id
	  value
	}
	listAckRetirementIllnessTypes {
	  id
	  value
	}
	listAckRetirementCauseOfDeathTypes {
		id
		value
	}
	listAckRetirementReasonOfDeathTypes {
		id
		value
	}
	listAckRetirementDeceasedReasonOfDeathOtherTypes {
		id
		value
	}
	listAckRetirementNewStatusTypes {
		id
		value
	}
  }
  `;

export const getTransitionReport = /* GraphQL */ `
	query getTransitionReport($horseCode: Int!, $memberExternalId: String!) {
		getTransitionReport(
			horseCode: $horseCode
			memberExternalId: $memberExternalId
		) {
			id
			member {
				businessPhone
				firstName
				lastName
				dateOfBirth
				email
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			behalfAdmin {
				firstName
				lastName
			}
			horse {
				horseCode
				name
				ottInformation {
					paddockName
				}
				location {
					address
					suburb
					postcode
					state
					country
				}
			}
			hasBeenTransitioned
			whoRequestedToAssist {
				id
				value
			}
			reasonForTransition {
				id
				value
			}
			retirementReason {
				id
				value
			}
			doesHorseHaveInjury
			doesHorseHaveIllness
			doesHorseHaveVices
			rehome {
				id
				value
			}
			lengthOfAdvertised {
				id
				value
			}
			moveDate
			address
			suburb
			postcode
			state
			country
			isAddressManual
			purpose {
				id
				value
			}
			vices {
				id
				value
			}
			injuries {
				id
				value
			}
			illnesses {
				id
				value
			}
			whoRequestedToAssistOtherDetails
			reasonForTransitionOtherDetails
			retirementReasonOtherDetails
			injuriesOtherDetails
			illnessesOtherDetails
			vicesOtherDetails
			rehomeOtherDetails
			purposeOtherDetails
			created
			lastUpdated
			lastSubmitted
			status
		}
	}
`;

export const getTransitionReportReferences = /* GraphQL */ `
	query getTransitionReportReferences {
		listWhoRequestedToAssistLookup {
			id
			value
		}
		listReasonForTransitionLookup {
			id
			value
		}
		listRehomeLookup {
			id
			value
		}
		listLengthOfAdvertisedLookup {
			id
			value
		}
		listRetirementLookup {
			id
			value
		}
		listHorsePurposes {
			id
			value
		}
		listInjuryDetails {
			id
			value
		}
		listAckRetirementIllnessTypes {
			id
			value
		}
		listHabitsAndVices {
			id
			value
		}
	}
`;

export const getResetExpressRehomeAssessmentReferences = /* GraphQL */ `
	query getResetExpressRehomeAssessmentReferences {
		listSpecialtyDisciplines {
			id
			value
		}
		listGroundWorks {
			id
			value
		}
		listRiddenTrainingExposure {
			id
			value
		}
		listRiddenExposureRetraining {
			id
			value
		}
		listRiderTypes {
			id
			value
		}
		listRehomePriceRanges {
			id
			value
		}
	}
`;

export const getResetExpressRehabilitationReferences = /* GraphQL */ `
	query getResetExpressRehabilitationReferences {
		listSpecialtyDisciplines {
			id
			value
		}
		listGroundWorks {
			id
			value
		}
		listFormOfExercisesRehab {
			id
			value
		}
		listWeeksToRetrainPriorRehoming {
			id
			value
		}
	}
`;

export const getAcknowledgedFosterWeeklyAssessmentReferences = /* GraphQL */ `
	query getAcknowledgedFosterWeeklyAssessmentReferences {
		listAckRetirementQuarterTypes {
			id
			value
		}
		listAckRetirementChangesToHealthTypes {
			id
			value
		}
		listAckRetirementRehabReasonTypes {
			id
			value
		}
		listAckRetirementInjuryTypes {
			id
			value
		}
		listAckRetirementIllnessTypes {
			id
			value
		}
		listAckRetirementCauseOfDeathTypes {
			id
			value
		}
		listAckRetirementReasonOfDeathTypes {
			id
			value
		}
		listAckRetirementDeceasedReasonOfDeathOtherTypes {
			id
			value
		}
		listAckRetirementNewStatusTypes {
			id
			value
		}
		listResetConcludedReasons {
			id
			value
		}
		listShareInfoEuthanisedReasons {
			id
			value
		}
		listShareInfoEuthanisedUnsuitableReasons {
			id
			value
		}
		listSoldRehomePriceBrackets {
			id
			value
		}
		listSoldRehomePurposes {
			id
			value
		}
		listSpecialtyDisciplines {
			id
			value
		}
		listHorsePurposes {
			id
			value
		}
		listFullCircleTypesOfAssistance {
			id
			value
		}
		listAckFosterNewStatusTypes {
			id
			value
		}
		listAckFosterConcludedReasons {
			id
			value
		}
	}
`;

export const sendLogToServer = `
  mutation addLog($input: LogInput!) {
	addLog(input: $input)
  }`;

export const listBusinessType = `
	query listBusinessType {
		listBusinessType {
    		id
    		value
		}
	}`;

export const getAcknowledgedRetrainerForm = /* GraphQL */ `
	query getAcknowledgedRetrainerForm($memberExternalId: String!) {
		getAcknowledgedReTrainerForm(memberExternalId: $memberExternalId) {
			id
			dateOfBirth
			contactNumber
			residentalAddress
			residentalSuburb
			residentalPostcode
			residentalState
			residentalCountry
			postalAddress
			postalSuburb
			postalPostcode
			postalState
			postalCountry
			stableAddress
			stableSuburb
			stablePostcode
			stableState
			stableCountry
			agentName
			personalDetailUploads {
				filename
				key
				type
				dateApprovedRejected
				isDeleted
				sightedBy {
					firstName
					lastName
					id
				}
				status
			}
			insuranceCertificateOfCurrencyUploads {
				filename
				key
				type
				dateApprovedRejected
				isDeleted
				sightedBy {
					firstName
					lastName
					id
				}
				status
			}
			businessName
			abn
			additionalDetails
			additionalEmployment {
				id
				value
			}
			additionalEmploymentDetails
			otherActivitiesDetails
			veterinaryPracticeName
			regularVeterinarian
			veterinaryAddress
			veterinaryPhone
			veterinaryEmail
			farrier
			farrierPhone
			farrierEmail
			creditReferencesUploads {
				filename
				key
				type
			}
			staffs {
				fullName
				role {
					id
					value
				}
				roleOther
			}
			publicLiability
			publicLiabilityexpirydate
			publicLiabilityCoverage
			publicLiabilityInsurerName
			publicLiabilityInsuredPartyName
			professionalIndemnity
			liabilityPolicyNumber
			liabilityExpiryDate
			liabilityCoverage
			indemnityPolicyNumber
			indemnityExpiryDate
			indemnityCoverage
			indemnityInsurerName
			indemnityInsuredPartyName
			workersCompensationPolicyNumber
			workersCompensationExpiryDate
			workersCompensationCoverage
			workersCompensationInsuredPartyName
			sourceOfHorses {
				id
				value
			}
			sourceOfHorsesOther
			disciplines {
				id
				value
			}
			disciplinesOther
			numberOfHorses {
				id
				value
			}
			rehomedHorsesDetails
			confirmCapacityToRetrain
			reasonToBecomeAckRetrainer
			expectations
			retrainingProcess
			stepsTakenForLongTermHorsePlacement
			raceNames
			signedReferences {
				filename
				key
				type
			}
			manageUnsuitableDetails
			businessModel
			canReceiveEmergencyCases
			ridingExperience
			memberships {
				id
				value
			}
			membershipsOther
			member {
				businessPhone
				firstName
				lastName
				dateOfBirth
				email
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			coach
			qualifyToCoach
			qualifications
			certificates {
				filename
				key
				type
			}
			resume {
				filename
				key
				type
			}
			videoDetails
			licenceType {
				id
				value
			}
			licence
			licenceStart
			previousLicenceType {
				id
				value
			}
			previousLicence
			previousLicenceStart
			hadRSPCAInvestigation
			rspcaInvestigationDetails
			isBannedByAnyRacingAuthority
			bannedByAnyRacingAuthorityDetails
			disqualified
			disqualificationDetails
			wasCharged
			chargeDetails
			currentCriminalProceedings
			criminalProceedingDetails
			bankruptcy
			bankruptcyDetails
			acknowledgesEquineWelfare
			acknowledgesNationalPoliceRecordCertificate
			acknowledgesCriminalCharges
			acknowledgesBankruptcy
			acknowledgesAgreement
			acknowledgesToUtiliseOttCommunity
			acknowledgesCorrectApplicationDetails
			status
			businessType {
				id
				value
			}
			ownsAllCompanyShares
			trusteeAcn
			trusteeFurtherDetails
			trusteeHasShares
			trusteeName
			partners {
				contactDetails
				name
			}
			companySharesOwnershipDetails
			stableAddressSameAsResidental
			propertyRented
		}
		listBusinessType {
			id
			value
		}
	}
`;

export const saveAcknowledgedRetrainerForm = `
  mutation saveAcknowledgedRetrainerForm($acknowledgedFormData: AcknowledgedReTrainerFormInput) {
	saveAcknowledgedReTrainerForm(input: $acknowledgedFormData) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
  }`;

export const saveAcknowledgedRetirementForm = `
  mutation saveAcknowledgedRetirementForm($acknowledgedFormData: AcknowledgedRetirementFormInput) {
	saveAcknowledgedRetirementForm(input: $acknowledgedFormData) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
  }`;

export const saveRetirementQuarterlyAssessmentForm = `
  mutation saveRetirementQuarterlyAssessmentForm($acknowledgedFormData: AcknowledgedRetirementQuarterlyAssessmentFormInput) {
	saveRetirementQuarterlyAssessmentForm(input: $acknowledgedFormData) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
  }`;

export const saveAckRetirementQuarterlyAssessmentFormStatus = `
  mutation saveAckRetirementQuarterlyAssessmentFormStatus($id: Int!, $status: FormStatusEnum!) {
	saveAckRetirementQuarterlyAssessmentFormStatus(id: $id, status: $status) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }
  `;
export const updateAcknowledgedFormStatus = `
  mutation updateAcknowledgedFormStatus($id: Int!, $status: FormStatusEnum!) {
	saveAcknowledgedReTrainerFormStatus(id: $id, status: $status) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }
  `;

export const updateAcknowledgedRetirementFormStatus = `
  mutation updateAcknowledgedRetirementFormStatus($id: Int!, $status: FormStatusEnum!) {
	saveAcknowledgedRetirementFormStatus(id: $id, status: $status) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }
  `;

export const deleteAcknowledgedRetrainerForm = `
  mutation deleteAcknowledgedRetrainerForm($id: Int!) {
	deleteAcknowledgedRetrainerForm(id: $id)
  }`;

export const deleteAcknowledgedRetirementForm = `
  mutation deleteAcknowledgedRetirementForm($id: Int!) {
	deleteAcknowledgedRetirementForm(id: $id)
  }`;

export const saveUploadedRetirementFile = `
  mutation saveUploadedRetirementFile($filename: String!
	$key: String!
	$memberExternalId: String!
	$type: UploadedFileTypeEnum!) {
	saveUploadedRetirementFile(filename: $filename
		key: $key
		memberExternalId: $memberExternalId
		type: $type) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const saveUploadedSponsorshipOutcomeFile = /* GraphQL */ `
	mutation saveUploadedSponsorshipOutcomeFile(
		$filename: String!
		$key: String!
		$memberExternalId: String!
		$type: SponsorshipOutcomeTypeEnum
	) {
		saveUploadedSponsorshipOutcomeFile(
			filename: $filename
			key: $key
			memberExternalId: $memberExternalId
			type: $type
		) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveUploadedFile = `
  mutation saveUploadedFile($filename: String!
	$key: String!
	$memberExternalId: String!
	$type: UploadedFileTypeEnum!) {
	saveUploadedFile(filename: $filename
		key: $key
		memberExternalId: $memberExternalId
		type: $type) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const saveOhepAdminFile = `
	mutation SaveOhepAdminFile (
		$filename: String!
		$key: String!
		$memberExternalId: String!
		$type: OhepAdminFileTypeEnum!
		$ohepNominationId: Int!
	) {
		saveOhepAdminFile(filename: $filename
		key: $key
		memberExternalId: $memberExternalId
		type: $type
		ohepNominationId: $ohepNominationId
		) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveResetAdminUploadedFile = `
  mutation saveResetAdminUploadedFile($filename: String!
	$key: String!
	$memberExternalId: String!
	$type: UploadedFileTypeEnum!
	$resetNominationId: Int!) {
	saveResetAdminUploadedFile(filename: $filename
		key: $key
		memberExternalId: $memberExternalId
		type: $type
		resetNominationId: $resetNominationId) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	}
  }`;

export const deleteUploadedFile = `
  mutation deleteUploadedFile($key: String!) {
	deleteUploadedFile(key: $key)
  }`;

export const deleteUploadedRetirementFile = `
  mutation deleteUploadedRetirementFile($key: String!) {
	deleteUploadedRetirementFile(key: $key)
  }`;

export const deleteUploadedSponsorshipOutcomeFile = `
  mutation deleteUploadedSponsorshipOutcomeFile($key: String!) {
	deleteUploadedSponsorshipOutcomeFile(key: $key)
  }`;

export const deleteResetAdminUploadedFile = `
  mutation deleteResetAdminUploadedFile($key: String!) {
	deleteResetAdminUploadedFile(key: $key)
  }`;

export const getAdminActionOptionsList = `
query getAdminActionOptions {
	listAdminActionStauses {
	  id
	  value
	}
	listAdminActionOutcomes {
	  id
	  value
	}
	getAdminMembers {
	  id
	  externalId
	  firstName
	  lastName
	}
  }
`;

export const unclaimHorse = `
  mutation unclaimHorse($input: UnclaimHorseInput!) {
	unclaimHorse(input: $input){
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
  }`;

export const unclaimHorseForAll = `
  mutation unclaimHorseForAll($input: UnclaimHorseInput!) {
	unclaimHorseForAll(input: $input){
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
  }`;

export const saveDeceasedThoroughbredForm = `
	mutation saveDeceasedThoroughbredForm($input: DeceasedThoroughbredFormInput!) {
	saveDeceasedThoroughbredForm(input: $input){
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
	}
}`;

export const saveDeceasedThoroughbredFormStatus = /* GraphQL */ `
	mutation saveDeceasedThoroughbredFormStatus(
		$id: Int!
		$reviewNotes: String
		$status: FormStatusEnum
		$deceasedInput: DeceasedThoroughbredFormInput
	) {
		saveDeceasedThoroughbredFormStatus(
			id: $id
			reviewNotes: $reviewNotes
			status: $status
			deceasedInput: $deceasedInput
		) {
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveAdminAction = `
mutation saveAdminAction($formId: String!
	$formType: FormTypeEnum!
	$memberExternalId: String!
	$adminOutcomeId: Int
	$adminStatusId: Int
	$id: Int) {
	saveAdminAction(formId: $formId, formType: $formType, memberExternalId: $memberExternalId, adminOutcomeId: $adminOutcomeId, adminStatusId: $adminStatusId, id: $id) {
	  id
	  success
	  error {
		errorCode
		errorDetails
		errorMessage
		}
	  }
	}
	`;

export const saveOhepAdminData = `
	mutation SaveOhepAdminData (
		$input: OhepAdminFormInput!
		$adminExternalId: String!
		$ohepNominationId: Int!
		$adminStatusId: Int
		$formComplete: Boolean
	) {
			saveOhepAdminForm( input: $input ) {
				id
				success
				error {
					errorCode
					errorDetails
					errorMessage
				}
			}
			saveOhepAdminAction(
				adminExternalId: $adminExternalId,
				ohepNominationId: $ohepNominationId,
				adminStatusId: $adminStatusId,
				formComplete: $formComplete
			) {
				id
				success
				error {
					errorCode
					errorDetails
					errorMessage
				}
			}
	}
`;

export const generateOhepAcceptanceNotice = /* GraphQL */ `
	mutation GenerateOhepAcceptanceNotice(
		$addEwgmSignatureOnAcceptanceNotice: Boolean!
		$automaticallySendAcceptanceNoticeToOwner: Boolean!
		$ohepNominationId: Int!
	) {
		generateOhepAcceptanceNotice(
			addEwgmSignatureOnAcceptanceNotice: $addEwgmSignatureOnAcceptanceNotice
			automaticallySendAcceptanceNoticeToOwner: $automaticallySendAcceptanceNoticeToOwner
			ohepNominationId: $ohepNominationId
		)
	}
`;

export const generateOhepNoticeToProceed = /* GraphQL */ `
	mutation GenerateNoticeToProceed($id: Int!) {
		generateNoticeToProceed(id: $id)
	}
`;

export const saveOhepEoiAdminData = `
	mutation SaveOhepEoiAdminData (
		$adminExternalId: String!
		$adminOutcomeId: Int
		$adminStatusId: Int
		$formType: String!
		$ohepEoiGuid: String!
	) {
			saveOhepEoiAdminAction(
				adminExternalId: $adminExternalId,
				adminOutcomeId: $adminOutcomeId,
				adminStatusId: $adminStatusId,
				formType: $formType,
				ohepEoiGuid: $ohepEoiGuid
			) {
				id
				success
				error {
					errorCode
					errorDetails
					errorMessage
				}
			}
	}
`;

export const saveResetAdminData = `
  mutation saveResetAdminData( $input: ResetAdminDataInput!, $adminExternalId: String!
	$resetNominationId: Int!
	$adminStatusId: Int
	$formComplete: Boolean) {
		saveResetAdminData( input: $input ) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
	 	 }
	  	saveResetAdminAction(adminExternalId: $adminExternalId, resetNominationId: $resetNominationId, adminStatusId: $adminStatusId, formComplete: $formComplete) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
	`;

export const doesHorseHaveInReviewResetNomination = `
	query doesHorseHaveInReviewResetNomination($horseCode: Int) {
		getResetNominationForms(horseCode: $horseCode, status: [Review]) {
			id
		}
	}`;

export const listAckRetirementNewStatusTypes = `
query listAckRetirementNewStatusTypes {
	listAckRetirementNewStatusTypes {
		id
		value
	}
}`;

export const listResetNewStatuses = `
	query listResetNewStatuses {
		listResetNewStatuses {
		  id
		  value
		}
	}`;

export const listAcknowledgedRetrainerNewStatuses = `
query listAcknowledgedRetrainerNewStatuses {
	listAcknowledgedRetrainerNewStatuses {
		id
		value
	}
}`;

export const getMemberProfileForAdminView = `
	query getMember($id: Int) {
		getMember(id: $id) {
			id
			email
			externalId
			firstName
			lastName
			preferredName
			phone
			businessPhone
			dateOfBirth
			gender
			yearsRiding {
			  id
			  value
			}
			raceClubMemberships {
				id
				name
				postcode
			}
			residentialAddress {
			  address
			  suburb
			  postcode
			  state
			  country
			}
			postalAddress {
				address
				country
				lastUpdated
				postcode
				state
				suburb
			}
			interests {
				id
				title
			}
			otherInterests
			memberships {
				id
				name
			}
			otherMemberships
			primaryMembershipType {
			  id
			  name
			}
			secondaryMemebershipTypes {
				id
				name
			}
			roles {
				code
				name
				description
			}
			unattendedAttachments {
			  id
			  filename
			  key
			  created
			}
			notes {
			  id
			  note
			  attachments {
				id
				filename
				key
			  }
			  created
			  by {
				firstName
				lastName
			  }
			}
			accountDisableReasonOther
			accountDisableReasons {
				id
				value
			}
			accountStatus
			accountStatusChangeDate
			accountStatusChangedBy
		}
	}
`;

export const changeUserAccountStatus = `
  mutation changeUserAccountStatus($input: UserAccountStatusInput) {
		changeUserAccountStatus(input: $input){
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	}
}`;

export const saveMemberNoteAttachment = `
  mutation saveMemberNoteAttachment(
	$memberId: Int!
	$filename: String!
	$key: String!
	$adminExternalId: String!) {
	saveMemberNoteAttachment(
		memberId: $memberId
		filename: $filename
		key: $key
		adminExternalId: $adminExternalId)
  }`;

export const deleteMemberNoteAttachment = `
  mutation deleteMemberNoteAttachment($key: String!) {
	deleteMemberNoteAttachment(key: $key)
  }`;

export const saveMemberNote = `
  mutation saveMemberNote(
	$memberId: Int!
	$note: String!
	$adminExternalId: String!) {
		saveMemberNote(
		memberId: $memberId
		note: $note
		adminExternalId: $adminExternalId) {
			id
			note
			attachments {
				id
				filename
				key
			}
			by {
				firstName
				lastName
			}
			created
		}
  }`;

export const listPreviouslyClaimedThoroughbreds = `
	query listPreviouslyClaimedThoroughbreds($memberExternalId: String) {
		getHorsePreviousClaims(memberExternalId: $memberExternalId) {
			id
			ownershipType
			horse {
				horseCode
				name
				ottInformation {
					paddockName
				}
				location {
					address
					suburb
					postcode
					state
				}
			}
			created
		}
	}
`;

export const listClaimedThoroughbreds = `
  query listClaimedThoroughbreds($memberExternalId: String, $status: [FormStatusEnum!]) {
	  getHorseClaims(memberExternalId: $memberExternalId, status: $status) {
		id
		ownershipType
		horse {
			horseCode
			name
			ottInformation {
				paddockName
			}
			location {
				address
				suburb
				postcode
				state
			}
		}
		status
		lastUpdated
		reviewLastUpdated
	  }
  }
`;

export const listFullCircleFormsForMember = `
	query getBusinessProfiles($memberExternalId: String) {
		getFullCircles(memberExternalId: $memberExternalId) {
			id
			status
			lastUpdated
    		reviewLastUpdated
			member {
				firstName
				lastName
			}
			horse {
				name
				ottInformation {
					paddockName
				}
			}
		}
	}
`;

export const listThoroughbredFormsForMember = `
	query getBusinessProfiles($memberExternalId: String) {
		getThoroughbredForms(memberExternalId: $memberExternalId) {
			guid
			horseName
			status
			lastUpdated
			reviewLastUpdated
		}
	}
`;

export const listResetNominationFormsForMember = `
	query getResetNominationForms($memberExternalId: String) {
		getResetNominationForms(memberExternalId: $memberExternalId) {
			id
			horse {
			  name
			  ottInformation {
				paddockName
			  }
			}
			lastUpdated
			lastReviewed
			status
		}
	}
`;

export const listResetWeeklyProgressFormsForMember = `
	query getResetWeeklyProgressForms($memberExternalId: String) {
		getResetWeeklyProgressForms(memberExternalId: $memberExternalId) {
			id
			weekId
			horse {
			  name
			  ottInformation {
				paddockName
			  }
			}
			lastUpdated
			lastReviewed
			status
		}
	}
`;

export const listResetInitialAssessmentFormsForMember = `
	query getResetInitialAssessmentForms($memberExternalId: String) {
		getResetInitialAssessmentForms(memberExternalId: $memberExternalId) {
			id
			horse {
			  name
			  ottInformation {
				paddockName
			  }
			}
			lastReviewed
			lastUpdated
			status
		}
	}
`;

export const listHorsePlacementFormsForMember = `
	query getHorsePlacementForms($memberExternalId: String) {
		getHorsePlacementForms(memberExternalId: $memberExternalId) {
			id
			horse {
			  name
			  ottInformation {
				paddockName
			  }
			}
			lastUpdated
			lastReviewed
			status
		}
	}
`;

export const listHorsePlacementInterestsForMember = `
	query getInterestedHorseListings($memberExternalId: String!) {
		getInterestedHorseListings(memberExternalId: $memberExternalId) {
			id
			horse {
			  horseCode
			  name
			  ottInformation {
				paddockName
			  }
			}
			interest {
				status
				lastUpdated
			}
		}
	}
`;

export const listShareInformationForms = `
	query getShareInformationForms($memberExternalId: String) {
		getShareInformationForms(memberExternalId: $memberExternalId) {
			id
			horse {
			  name
			  ottInformation {
				paddockName
			  }
			}
			lastUpdated
			lastReviewed
			status
		}
	}
`;

export const retrieveConcludedRetraining = (includeTotal) => {
	let totalString = '';
	if (includeTotal) {
		totalString = 'totalCount';
	}
	return `query retrieveConcludedRetraining($input: RetrieveConcludedRetrainingInput) {
		retrieveConcludedRetraining(input: $input) {
			asOfDate
			conclusionReason
			conclusionReasonId
			currentClaimStatus
			filename
			fullcircle
			horseCode
			horseOttStatusId
			horseStatusId
			key
			stabledDate
			paddockName
			previousClaimDate
			previousClaimId
			horseName
			${totalString}
		}
	}
`;
};

export const listHorseClaimSources = `
  query listHorseClaimSources {
	listHorseClaimSources {
	  id
	  value
	}
  }
`;

export const getTransitionGuides = `
 query getTransitionGuides($status: [FormStatusEnum]) {
	getTransitionGuides(status: $status) {
		horseName
		concerns
		created
		status
		id
		member {
			firstName
			lastName
			id
		}
    }
}`;

export const getTransitionGuide = `
 query getTransitionGuide($id: Int!) {
	getTransitionGuide(id: $id) {
		horseName
		concerns
		status
		id
		member {
			firstName
			lastName
			email
			gender
			phone
			residentialAddress {
				address
				country
				postcode
				state
				suburb
			}
		}
		step1
		step2
		step3
		step4
		step5
		step6
		step7
		step8
    }
}`;

export const getTransitionAdminInfo = `
 query getTransitionAdminInfo($id: Int!) {
	getAdminMembers {
		id
		externalId
		firstName
		lastName
    }
	listAdminActionStauses {
		id
		value
	}
	listTransitionGuideOutcomes {
		id
		value
	}
	getTransitionGuideAdminInfo(id: $id) {
		actioningMember
		outcome
		status
		notes
		notesOther
	}
}`;

export const saveAdminTransitionStatus = `
  mutation saveAdminTransitionStatus($input: AdminTransitionReviewInput) {
   saveAdminTransitionStatus(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const getContactUsInfo = `
  query getContactUsInfo {
	listBrowserTypes {
      id
	  value
	}
	listContactReasons {
	  id
	  value
	}
	listDeviceTypes {
	  id
	  value
	}
	listWhichFeatures {
	  id
	  value
	}
  }
`;

export const getContactUsForms = `
 query getContactUsForms($status: [FormStatusEnum]) {
	getContactUsForms(status: $status) {
		contactReason
		created
		status
		id
		member {
			firstName
			lastName
			id
		}
		firstName
		lastName
    }
	listContactReasons {
		id
		value
	}
}`;

export const getContactUsFormById = `
 query getContactUsFormById($id: Int!) {
	getContactUsFormById(id: $id) {
		browserType
		contactReason
		contactDetails
		created
		deviceType
		firstName
		generalFeedback
		howCanWeHelp
		id
		lastName
		memberId
		moreInformation
		otherInformation
		preferredContact
		status
		wantContact
		whichFeature
		whichFeatureOther
		member {
			firstName
			id
			lastName
		}
		supportingImages {
			filename
			key
		}
	}
	listContactFormOutcomes {
		id
		value
	}
	listContactReasons {
		id
		value
	}
	listWhichFeatures {
		id
		value
	}
	listBrowserTypes {
		id
		value
	}
	listDeviceTypes {
		id
		value
	}
	getAdminMembers {
		id
		externalId
		firstName
		lastName
    }
	listAdminActionStauses {
		id
		value
	}
}`;

export const getContactUsAdminInfo = `
 query getContactUsAdminInfo($id: Int!) {
	getContactUsAdminInfo(id: $id) {
		actioningMember
		notes
		outcome
		status
		notesOther
	}
}
`;

export const saveContactUsForm = /* GraphQL */ `
	mutation saveContactUsForm($input: ContactUsInput) {
		saveContactUsForm(input: $input) {
			id
			error {
				errorCode
				errorDetails
				errorMessage
			}
			message
			success
		}
	}
`;

export const saveContactUsFormImages = /* GraphQL */ `
	mutation saveContactUsFormImages($input: [ContactUsImageInput]) {
		saveContactUsImages(input: $input)
	}
`;

export const saveAdminContactUsStatus = `
  mutation saveAdminContactUsStatus($input: AdminContactUsReviewInput) {
	saveAdminContactUsStatus(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const generateAdminReviewNotification = `
  mutation generateAdminReviewNotification($formId: Int!, $formType: FormTypeEnum!) {
	generateAdminReviewNotification(formId: $formId, formType: $formType)
  }`;

export const updateHorseToListed = `
  mutation updateHorsePlacementInterest($horseCode: Int!) {
	updateHorsePlacementInterest(horseCode: $horseCode) {
		id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
	}
  }
`;
export const getEventDropdowns = `
	query getEventDropdowns {
		listEventDisciplines {
		id
		value
		}
		listEventOrganisers {
		id
		value
		}
		listEventCancellationReasons {
		id
		value
		}
	}

	`;

export const saveCompetitionAdminDismissableNotif = `
	mutation saveCompetitionAdminDismissableNotification($memberId: Int!, $source: String!, $link: String!) {
	saveCompetitionAdminDismissableNotification(memberId: $memberId, source: $source, link: $link) {
		id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
	}
  }
`;

export const updateCompetitionAdminDismissableNotif = `
mutation updateCompetitionAdminDismissableNotification($memberId: Int!, $source: String!, $link: String!) {
updateCompetitionAdminDismissableNotification(memberId: $memberId, source: $source, link: $link) {
	id
	success
	error {
		errorCode
		errorDetails
		errorMessage
	}
}
}
`;

export const updateLastUpdatedByUser = `
mutation updateLastUpdatedBy($memberExternalId: String!, $fullName: String!) {
	updateLastUpdatedBy(memberExternalId: $memberExternalId, fullName: $fullName) {
		id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
}
}
`;

export const saveRetirementDismissableNotification = `
mutation saveRetirementDismissableNotification($externalId: String!, $source: String!, $link: String!, $reportId: Int!) {
	saveRetirementDismissableNotification(externalId: $externalId, source: $source, link: $link, reportId: $reportId) {
	id
	success
	error {
		errorCode
		errorDetails
		errorMessage
	}
}
}
`;
export const getListOfHorseLatestPostUpdate = `
query getListOfHorseCodesWithLatestPost($horsesCode: [Int]) {
	getListOfHorseCodesWithLatestPost(horsesCode: $horsesCode) {
		created_at
		horse_code
		post_id
		message
		member_id
		source
		horse {
		  name
		  ottInformation {
			profilePhotoVisibility {
			  id
			}
			profilePhoto {
			  key
			}
		  }
		}
		files {
		  filename
		  key
		}
}
}
`;

export const getDismissableNotification = `
query getDismissableNotificationExist($externalId: String!, $autoDismissDate: String!, $source: String!) {
	getDismissableNotificationExist(autoDismissDate: $autoDismissDate, memberExternalId: $externalId, source: $source) {
	autoDismissDate
	created
	dismissed
	id
	link
	memberId
	message
	}
}
`;

export const saveDismissableNotif = `
	mutation saveDismissableNotification($externalId: String!, $source: String!, $link: String!, $message: String!, $autoDismissDate: String!) {
	saveDismissableNotification(externalId: $externalId, source: $source, link: $link, message: $message, autoDismissDate: $autoDismissDate) {
		id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
	}
  }
`;

export const getSelectedMemberRoles = `
query getMemberRoles($memberExternalId: String!) {
	getMemberRoles(memberExternalId: $memberExternalId) {
		memberId
		roleId
		eventOrganiserId
		organiserOther
}
}
`;

export const checkIfEmailExist = `
query isEmailExist($email: String!) {
	isEmailExist(email: $email)
}
`;

export const updateCurrentUserEmail = `
	mutation updateUserCurrentEmail($memberId: Int!, $email: String!) {
		updateUserCurrentEmail(email: $email, memberId: $memberId)
  }
`;

export const getForSaleHorseList = `
query getForSaleHorseListNotFollowing($horsesCode: [Int]) {
	getForSaleHorseListNotFollowing(horsesCode: $horsesCode) {
		horseId
		horseCode
		horseOTTStatusId
		created
		byMemberId
		horseName
		businessProfileId
		files {
			filename
			key
		  }
}
}
`;

export const getForSaleHorseListRetrainer = `
query getForSaleHorseListByBusinessProfileId($businessProfileId: Int!) {
	getForSaleHorseListByBusinessProfileId(businessProfileId: $businessProfileId) {
		horseId
		horseCode
		horseOTTStatusId
		horse {
			age
			sex
			ottInformation {
    disciplines {
     id
     value
    }
    disciplinesOther
			}
		}
		created
		byMemberId
		horseName
		businessProfileId
		files {
			filename
			key
		  }
}
}
`;

export const getListOfHabitsAndVices = `
query getListOfHabitsAndVices {
	listHabitsAndVices {
		id
		value
}
}
`;

export const getListHorseDetailVisibilityOptions = `
query getListHorseDetailVisibilityOptions {
	listHorseDetailVisibilityOptions {
		id
		value
	}
}
`;

export const saveRetirementInitialAssessmentForm = `
  mutation saveRetirementInitialAssessmentForm($input: RetirementInitialAssessmentFormInput) {
	saveRetirementInitialAssessmentForm(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const getRetirementInitialAssessment = `
	query getRetirementInitialAssessmentForm($horseCode: Int!, $memberExternalId: String!) {
		getRetirementInitialAssessmentForm(horseCode: $horseCode, memberExternalId: $memberExternalId) {
			habits {
				id
				value
			}
			created
			dateOfAssessment
			worming
			dentist
			farrier
			feedingRequirementsPlan
			horseStatusChanged
			newStatus
			otherHusbandryInfo
			overallCondition
			temperament
			habitsOther
			leftSidePhotos{
				filename
				key
				type
			}
			rightSidePhotos{
				filename
				key
				type
			}
			horsePyhsicalHealth
			horsePyhsicalHealthOtherConcern
			horseBehaviour
			finalComments
			status
		}
	}
`;

export const getRetirementForms = /* GraphQL */ `
	query getRetirementForms(
		$memberExternalId: String
		$status: [FormStatusEnum]
	) {
		getRetirementInitialAssessmentForms(
			memberExternalId: $memberExternalId
			status: $status
		) {
			id
		}
		getRetirementQuarterlyAssessmentForms(
			memberExternalId: $memberExternalId
			status: $status
		) {
			id
			newStatus {
				id
				value
			}
			deceasedCauseOfDeath {
				id
				value
			}
			deceasedDateOfDeath
			deceasedReasonOfDeath {
				id
				value
			}
			deceasedReasonForDeathOther {
				id
				value
			}
			deceasedInjuryDetailsOther
			deceasedIllnessDetailsOther
			deceasedKnackeryPostcode
			deceasedKnackerySuburb
			informationAboutDeath
			hasOwnerBeenNotified
			injuryType {
				id
				value
			}
			illnessType {
				id
				value
			}
			horse {
				name
				horseCode
			}
		}
	}
`;

export const getRetirementFormsAdmin = /* GraphQL */ `
	query getRetirementForms(
		$memberExternalId: String
		$status: [FormStatusEnum]
	) {
		getRetirementInitialAssessmentForms(
			memberExternalId: $memberExternalId
			status: $status
		) {
			id
			created
			dateOfAssessment
			worming
			dentist
			farrier
			feedingRequirementsPlan
			horseStatusChanged
			newStatus
			otherHusbandryInfo
			overallCondition
			temperament
			habits {
				id
				value
			}
			habitsOther
			leftSidePhotos {
				filename
				key
			}
			rightSidePhotos {
				filename
				key
			}
			horsePyhsicalHealth
			horsePyhsicalHealthOtherConcern
			horseBehaviour
			finalComments
			status
			lastUpdated
			member {
				id
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				horseCode
				ottInformation {
					paddockName
				}
			}
		}
		getRetirementQuarterlyAssessmentForms(
			memberExternalId: $memberExternalId
			status: $status
		) {
			id
			memberId
			horseCode
			quarterNumber {
				id
				value
			}
			dateOfAssessment
			progressPhotos {
				filename
				key
			}
			hasHorseStatusChanged
			newStatus {
				id
				value
			}
			agistmentHasPhysicalHealthChanged
			agistmentChangesToPhysicalHealth {
				id
				value
			}
			agistmentChangesToPhysicalHealthOther
			agistmentHasBehaviourChanged
			agistmentChangesToBehaviourOther
			agistmentTreatmentSinceLastReport
			worming
			wormingDateCompleted
			farrier
			farrierDateCompleted
			dentistTreatment
			dentistTreatmentDateCompleted
			husbandryOther
			rehabReason {
				id
				value
			}
			injuryType {
				id
				value
			}
			illnessType {
				id
				value
			}
			rehabReasonOther
			rehabDateCommenced
			treatmentSinceLastReport
			deceasedCauseOfDeath {
				id
				value
			}
			deceasedDateOfDeath
			deceasedReasonOfDeath {
				id
				value
			}
			deceasedInjuryDetailsOther
			deceasedIllnessDetailsOther
			deceasedReasonForDeathOther {
				id
				value
			}
			deceasedKnackeryPostcode
			deceasedKnackerySuburb
			informationAboutDeath
			hasOwnerBeenNotified
			finalCommentsNotes
			created
			lastUpdated
			lastReviewed
			member {
				id
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				horseCode
				ottInformation {
					paddockName
				}
			}
			status
		}
		getFosterInitialAssessmentForms(
			memberExternalId: $memberExternalId
			status: $status
		) {
			id
			created
			dateOfAssessment
			worming
			dentist
			farrier
			feedingRequirementsPlan
			otherHusbandryInfo
			overallCondition
			temperament
			habits {
				id
				value
			}
			habitsOther
			leftSidePhotos {
				filename
				key
			}
			rightSidePhotos {
				filename
				key
			}
			horseBehaviour
			horsePhysicalHealth
			horsePhysicalHealthOtherConcern
			finalComments
			status
			lastUpdated
			member {
				id
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				horseCode
			}
		}
		getFosterWeeklyAssessmentForms(
			memberExternalId: $memberExternalId
			status: $status
		) {
			id
			memberId
			horseCode
			weekId
			dateOfAssessment
			progressPhotos {
				filename
				key
			}
			hasHorseStatusChanged
			newStatus {
				id
				value
			}
			injuryType {
				id
				value
			}
			illnessType {
				id
				value
			}
			unsuitableReason {
				id
				value
			}
			selectedDisciplines {
				id
				value
			}
			assistanceType {
				id
				value
			}
			rehabReason {
				id
				value
			}
			rehabReasonOther
			rehabDateCommenced
			deceasedInjuryDetailsOther
			horseProgressUpdate
			horseIssuesEncountered
			feedingPlan
			physicalConcerns
			physicalConcernDetails
			vetTreatmentDetails
			vetTreatment
			concludedReason {
				id
				value
			}
			deceasedDateOfDeath
			deceasedReasonOfDeath {
				id
				value
			}
			deceasedReasonForDeathOther {
				id
				value
			}
			deceasedIllnessDetailsOther
			unsuitableReasonOther
			dateSold
			priceBracket {
				id
				value
			}
			selectedDisciplinesOther
			postcode
			email
			fullName
			dateCommenced
			isRetrainHorse
			purpose {
				id
				value
			}
			purposeOther
			isFullCircle
			spellingExpectedTimeframe
			otherConcerns
			horse {
				name
				sex
				colour
				age
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			member {
				id
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			created
			lastUpdated
			lastReviewed
			status
			reviewMessages
			lastRejected
			reviewNotes
			reviewReasons
			lastSubmitted
			lastApproved
		}
		getResetExpressRehomeAssessmentForms(
			status: $status
			memberExternalId: $memberExternalId
		) {
			id
			member {
				id
				externalId
				firstName
				lastName
			}
			status
			lastUpdated
			horse {
				name
				ottInformation {
					paddockName
				}
			}
			weekId
		}
		getResetExpressRehabilitationConclusionForms(
			status: $status
			memberExternalId: $memberExternalId
		) {
			id
			member {
				id
				externalId
				firstName
				lastName
			}
			status
			lastUpdated
			horse {
				name
				ottInformation {
					paddockName
				}
			}
			weekId
		}
	}
`;

export const getBusinessProfilesByMemberId = `
query getBusinessProfilesByMemberId($memberExternalId: String! ,$status: [FormStatusEnum!], $businessProfileTypeId: [Int!]) {
	getBusinessProfilesByMemberId(memberExternalId: $memberExternalId, status: $status, businessProfileTypeId: $businessProfileTypeId) {
		id
		status
		reviewNotes
		reviewReasons
		created
		lastUpdated
		reviewLastUpdated
		businessProfileType {
			id
			value
		}
		businessName
		logo {
			approved
			filename
			key
			type
			businessProfileId
		}
		firstName
		firstNameDisplayOption {
			id
			value
		}
		lastName
		lastNameDisplayOption {
			id
			value
		}
		email
		emailDisplayOption {
			id
			value
		}
		businessPhone
		businessPhoneDisplayOption {
			id
			value
		}
		fullAddress
		streetAddress
		streetAddressDisplayOption {
			id
			value
		}
		suburb
		suburbDisplayOption {
			id
			value
		}
		postcode
		postcodeDisplayOption {
			id
			value
		}
		state
		stateDisplayOption {
			id
			value
		}
		businessSummary
		facilitiesOnProperty {
			id
			value
		}
		facilitiesOffSite {
			id
			value
		}
		specialtyDisciplines {
			id
			value
		}
		servicesOffered {
			id
			value
		}
		facilitiesOffSiteOther
		facilitiesOnPropertyOther
		specialtyDisciplinesOther
		servicesOfferedOther
		priceRange
		experiences
		twitter
		instagram
		facebook
		website
		riddenPhotos {
			approved
			filename
			key
			type
			businessProfileId
		}
		propertyPhotos {
			approved
			filename
			key
			type
			businessProfileId
		}
		generalPhotos {
			approved
			filename
			key
			type
			businessProfileId
		}
		isMaximumCapacity
		maximumCapacity
		rangeOfFosterCare
		adviseDatesUnAvailable
		dateUnavailableEnd
		dateUnavailableStart
		finalNotes
		alternativeContact
		alternativePhone
	}
}
`;

export const listBusinessProfilesAdminAction = `
query listBusinessProfilesAdminAction($memberExternalId: String, $status: [FormStatusEnum!], $businessProfileTypeId: [Int!]) {
	listBusinessProfilesAdminAction(memberExternalId: $memberExternalId, status: $status, businessProfileTypeId: $businessProfileTypeId) {
		id
		status
		reviewNotes
		reviewReasons
		businessProfileType {
			id
			value
		}
		businessName
		logo {
			approved
			filename
			key
			type
			businessProfileId
		}
		firstName
		firstNameDisplayOption {
			id
			value
		}
		lastName
		lastNameDisplayOption {
			id
			value
		}
		email
		emailDisplayOption {
			id
			value
		}
		businessPhone
		businessPhoneDisplayOption {
			id
			value
		}
		fullAddress
		streetAddress
		streetAddressDisplayOption {
			id
			value
		}
		suburb
		suburbDisplayOption {
			id
			value
		}
		postcode
		postcodeDisplayOption {
			id
			value
		}
		state
		stateDisplayOption {
			id
			value
		}
		businessSummary
		facilitiesOnProperty {
			id
			value
		}
		facilitiesOffSite {
			id
			value
		}
		specialtyDisciplines {
			id
			value
		}
		servicesOffered {
			id
			value
		}
		facilitiesOffSiteOther
		facilitiesOnPropertyOther
		specialtyDisciplinesOther
		servicesOfferedOther
		priceRange
		experiences
		twitter
		instagram
		facebook
		website
		riddenPhotos {
			approved
			filename
			key
			type
			businessProfileId
		}
		propertyPhotos {
			approved
			filename
			key
			type
			businessProfileId
		}
		generalPhotos {
			approved
			filename
			key
			type
			businessProfileId
		}
	}
}
`;
export const searchRetirementFormHorses = /* GraphQL */ `
	query searchRetirementFormHorses(
		$businessProfileType: Int
		$name: String
		$servicesOffered: [Int!]
		$specialtyDisciplines: [Int!]
		$orderBy: OrderByEnum
		$businessProfileIds: [Int!]
	) {
		countRetrainers(
			businessProfileType: $businessProfileType
			name: $name
			servicesOffered: $servicesOffered
			specialtyDisciplines: $specialtyDisciplines
			businessProfileIds: $businessProfileIds
		)
		findRetrainers(
			businessProfileType: $businessProfileType
			name: $name
			servicesOffered: $servicesOffered
			specialtyDisciplines: $specialtyDisciplines
			orderBy: $orderBy
			businessProfileIds: $businessProfileIds
		) {
			id
			businessProfileType {
				id
				value
			}
			businessName
			suburb
			postcode
			state
			businessSummary
			latitude
			longitude
			servicesOffered {
				id
				value
			}
			specialtyDisciplines {
				id
				value
			}
			riddenPhotos {
				filename
				key
				type
				approved
			}
		}
	}
`;

export const searchFosterProvider = /* GraphQL */ `
	query searchFosterProvider(
		$businessProfileType: Int
		$name: String
		$filterOnFacilities: [Int!]
		$filterOffFacilities: [Int!]
		$rangeOfFosterCare: Int
		$orderBy: OrderByEnum
		$businessProfileIds: [Int!]
	) {
		countSearchResults(
			businessProfileType: $businessProfileType
			name: $name
			filterOnFacilities: $filterOnFacilities
			filterOffFacilities: $filterOffFacilities
			rangeOfFosterCare: $rangeOfFosterCare
			businessProfileIds: $businessProfileIds
		)
		findRetrainers(
			businessProfileType: $businessProfileType
			name: $name
			filterOnFacilities: $filterOnFacilities
			filterOffFacilities: $filterOffFacilities
			rangeOfFosterCare: $rangeOfFosterCare
			orderBy: $orderBy
			businessProfileIds: $businessProfileIds
		) {
			id
			businessProfileType {
				id
				value
			}
			businessName
			suburb
			postcode
			state
			businessSummary
			latitude
			longitude
			servicesOffered {
				id
				value
			}
			specialtyDisciplines {
				id
				value
			}
			propertyPhotos {
				filename
				key
				type
				approved
				businessProfileId
			}
			facilitiesOnProperty {
				id
				value
			}
			facilitiesOffSite {
				id
				value
			}
			facilitiesOffSiteOther
			facilitiesOnPropertyOther
			rangeOfFosterCare
		}
	}
`;

export const getAcknowledgedRetirementReferences = /* GraphQL */ `
	query getAcknowledgedRetirementReferences {
		listAckRetrainerEmplomentTypes {
			id
			value
		}
		listAckRetrainerStaffRoles {
			id
			value
		}
		listAckRetrainerLicenseTypes {
			id
			value
		}
		listRaceClubMemberships {
			id
			name
		}
		listMemberships {
			id
			name
		}
	}
`;

export const getAcknowledgedRetirementForm = /* GraphQL */ `
	query getAcknowledgedRetirementForm($memberExternalId: String!) {
		getAcknowledgedRetirementForm(memberExternalId: $memberExternalId) {
			id
			dateOfBirth
			contactNumber
			residentalAddress
			residentalSuburb
			residentalPostcode
			residentalState
			residentalCountry
			postalAddress
			postalSuburb
			postalPostcode
			postalState
			postalCountry
			stableAddress
			stableSuburb
			stablePostcode
			stableState
			stableCountry
			agentName
			personalDetailUploads {
				filename
				key
				type
				dateApprovedRejected
				isDeleted
				sightedBy {
					firstName
					lastName
					id
				}
				status
			}
			insuranceCertificateOfCurrencyUploads {
				filename
				key
				type
				dateApprovedRejected
				isDeleted
				sightedBy {
					firstName
					lastName
					id
				}
				status
			}
			businessName
			abn
			additionalDetails
			additionalEmployment {
				id
				value
			}
			additionalEmploymentDetails
			otherActivitiesDetails
			veterinaryPracticeName
			regularVeterinarian
			veterinaryAddress
			veterinaryPhone
			veterinaryEmail
			farrier
			farrierPhone
			farrierEmail
			creditReferencesUploads {
				filename
				key
				type
			}
			staffs {
				fullName
				role {
					id
					value
				}
				roleOther
			}
			publicLiability
			publicLiabilityexpirydate
			publicLiabilityCoverage
			publicLiabilityInsurerName
			publicLiabilityInsuredPartyName
			professionalIndemnity
			liabilityPolicyNumber
			liabilityExpiryDate
			liabilityCoverage
			indemnityPolicyNumber
			indemnityExpiryDate
			indemnityCoverage
			indemnityInsurerName
			indemnityInsuredPartyName
			workersCompensationPolicyNumber
			workersCompensationExpiryDate
			workersCompensationCoverage
			workersCompensationInsuredPartyName
			ridingExperience
			agistmentFacilitiesUploads {
				filename
				key
				type
			}
			clientRefUploads {
				filename
				key
				type
			}
			ackFarmSupplierQuestion
			expectationFromRVQuestion
			hadFormalAgreementForAgistmentQuestion
			outlineApproachQuestion
			outlineResponsibilityQuestion
			memberships {
				id
				value
			}
			membershipsOther
			member {
				businessPhone
				firstName
				lastName
				dateOfBirth
				email
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			licenceType {
				id
				value
			}
			licence
			licenceStart
			previousLicenceType {
				id
				value
			}
			previousLicence
			previousLicenceStart
			hadRSPCAInvestigation
			rspcaInvestigationDetails
			isBannedByAnyRacingAuthority
			bannedByAnyRacingAuthorityDetails
			disqualified
			disqualificationDetails
			wasCharged
			chargeDetails
			currentCriminalProceedings
			criminalProceedingDetails
			bankruptcy
			bankruptcyDetails
			acknowledgesEquineWelfare
			acknowledgesNationalPoliceRecordCertificate
			acknowledgesCriminalCharges
			acknowledgesBankruptcy
			acknowledgesAgreement
			acknowledgesToUtiliseOttCommunity
			acknowledgesCorrectApplicationDetails
			status
			businessType {
				id
				value
			}
			ownsAllCompanyShares
			trusteeAcn
			trusteeFurtherDetails
			trusteeHasShares
			trusteeName
			partners {
				contactDetails
				name
			}
			companySharesOwnershipDetails
			stableAddressSameAsResidental
			propertyRented
		}
		listBusinessType {
			id
			value
		}
	}
`;

export const getAcknowledgedRetirementFormById = /* GraphQL */ `
	query getAcknowledgedRetirementFormById($formId: Int!) {
		getAcknowledgedRetirementFormById(id: $formId) {
			id
			dateOfBirth
			contactNumber
			residentalAddress
			residentalSuburb
			residentalPostcode
			residentalState
			residentalCountry
			postalAddress
			postalSuburb
			postalPostcode
			postalState
			postalCountry
			stableAddress
			stableSuburb
			stablePostcode
			stableState
			stableCountry
			agentName
			personalDetailUploads {
				filename
				key
				type
				documentType
				sightedBy {
					firstName
					lastName
					id
				}
				isDeleted
				dateApprovedRejected
				status
			}
			insuranceCertificateOfCurrencyUploads {
				filename
				key
				type
				sightedBy {
					firstName
					lastName
					id
				}
				isDeleted
				dateApprovedRejected
				status
			}
			businessName
			abn
			additionalDetails
			additionalEmployment {
				id
				value
			}
			additionalEmploymentDetails
			otherActivitiesDetails
			veterinaryPracticeName
			regularVeterinarian
			veterinaryAddress
			veterinaryPhone
			veterinaryEmail
			farrier
			farrierPhone
			farrierEmail
			creditReferencesUploads {
				filename
				key
				type
			}
			staffs {
				fullName
				role {
					id
					value
				}
				roleOther
			}
			publicLiability
			publicLiabilityexpirydate
			publicLiabilityCoverage
			publicLiabilityInsurerName
			publicLiabilityInsuredPartyName
			professionalIndemnity
			liabilityPolicyNumber
			liabilityExpiryDate
			liabilityCoverage
			indemnityPolicyNumber
			indemnityExpiryDate
			indemnityCoverage
			indemnityInsurerName
			indemnityInsuredPartyName
			workersCompensationPolicyNumber
			workersCompensationExpiryDate
			workersCompensationCoverage
			workersCompensationInsuredPartyName
			ridingExperience
			agistmentFacilitiesUploads {
				filename
				key
				type
			}
			clientRefUploads {
				filename
				key
				type
			}
			ackFarmSupplierQuestion
			expectationFromRVQuestion
			hadFormalAgreementForAgistmentQuestion
			outlineApproachQuestion
			outlineResponsibilityQuestion
			memberships {
				id
				value
			}
			membershipsOther
			member {
				businessPhone
				firstName
				lastName
				dateOfBirth
				email
				gender
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			licenceType {
				id
				value
			}
			licence
			licenceStart
			previousLicenceType {
				id
				value
			}
			previousLicence
			previousLicenceStart
			hadRSPCAInvestigation
			rspcaInvestigationDetails
			isBannedByAnyRacingAuthority
			bannedByAnyRacingAuthorityDetails
			disqualified
			disqualificationDetails
			wasCharged
			chargeDetails
			currentCriminalProceedings
			criminalProceedingDetails
			bankruptcy
			bankruptcyDetails
			acknowledgesEquineWelfare
			acknowledgesNationalPoliceRecordCertificate
			acknowledgesCriminalCharges
			acknowledgesBankruptcy
			acknowledgesAgreement
			acknowledgesToUtiliseOttCommunity
			acknowledgesCorrectApplicationDetails
			status
			businessType {
				id
				value
			}
			ownsAllCompanyShares
			trusteeAcn
			trusteeFurtherDetails
			trusteeHasShares
			trusteeName
			partners {
				contactDetails
				name
			}
			companySharesOwnershipDetails
			stableAddressSameAsResidental
			adminAction {
				admin {
					externalId
				}
				adminStatus {
					id
				}
				formComplete
			}
			phoneInterviewComments
			phoneInterviewDate
			propertyAssessmentDate
			propertyAssessmentUploads {
				filename
				key
			}
			propertyRidingPhotos {
				filename
				key
			}
			ewgmComment
			ewgmReviewDate
			applicationRecommendation
			rejectionReasons {
				id
				value
			}
			propertyRented
		}
	}
`;

export const filterAcknowledgedProgramApplications = /* GraphQL */ `
	query filterAcknowledgedRetrainerForms(
		$filterStatuses: [FormStatusEnum]
		$order: OrderByEnum = DESC
		$paginationIndex: Int
		$paginationSize: Int
		$businessProfileTypeId: [Int]
	) {
		getAcknowledgedReTrainerForms(
			status: $filterStatuses
			order: $order
			paginationSize: $paginationSize
			paginationIndex: $paginationIndex
			businessProfileTypeId: $businessProfileTypeId
		) {
			id
			member {
				firstName
				lastName
				id
			}
			status
			lastUpdated
			lastSubmitted
			ewgmAdminStatusValue
			ewgmAdminStatusId
			business_profile_type_id
		}
		getAcknowledgedRetirementForms(
			status: $filterStatuses
			order: $order
			paginationSize: $paginationSize
			paginationIndex: $paginationIndex
			businessProfileTypeId: $businessProfileTypeId
		) {
			id
			member {
				firstName
				lastName
				id
			}
			lastSubmitted
			lastUpdated
			ewgmAdminStatusValue
			ewgmAdminStatusId
			status
		}
	}
`;

export const countTotalFilteredAcknowledgedProgramApplications = /* GraphQL */ `
	query countTotalFilteredAcknowledgedProgramApplications(
		$filterStatuses: [FormStatusEnum]
		$businessProfileTypeId: [Int]
	) {
		countAcknowledgedRetrainerForms(
			status: $filterStatuses
			businessProfileTypeId: $businessProfileTypeId
		)
		countAcknowledgedRetirementForms(
			status: $filterStatuses
			businessProfileTypeId: $businessProfileTypeId
		)
	}
`;

export const getFosterInitialAssessmentById = `
	query getFosterInitialAssessmentFormById($id: Int!) {
		getFosterInitialAssessmentFormById(id: $id) {
			id
			habits {
				id
				value
			}
			created
			dateOfAssessment
			worming
			dentist
			farrier
			feedingRequirementsPlan
			otherHusbandryInfo
			overallCondition
			temperament
			habitsOther
			leftSidePhotos{
				filename
				key
				type
			}
			rightSidePhotos{
				filename
				key
				type
			}
			additionalPhotos{
				filename
				key
				type
			}
			horseHandle
			hasHorseDisplayedVices
			horsePhysicalHealth
			horsePhysicalHealthOtherConcern
			horseBehaviour
			finalComments
			status
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				horseCode
				status
			}
			reviewNotes
			reviewReasons
			lastSubmitted
			lastApproved
		}
	}
`;

export const getRetirementInitialAssessmentById = `
	query getRetirementInitialAssessmentFormById($id: Int!) {
		getRetirementInitialAssessmentFormById(id: $id) {
			id
			habits {
				id
				value
			}
			created
			dateOfAssessment
			worming
			dentist
			farrier
			feedingRequirementsPlan
			horseStatusChanged
			newStatus
			otherHusbandryInfo
			overallCondition
			temperament
			habitsOther
			leftSidePhotos{
				filename
				key
				type
			}
			rightSidePhotos{
				filename
				key
				type
			}
			horsePyhsicalHealth
			horsePyhsicalHealthOtherConcern
			horseBehaviour
			finalComments
			status
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				horseCode
				status
			}
			reviewNotes
			reviewReasons
			lastSubmitted
			lastApproved
		}
	}
`;

export const getRetirementQuarterlyAssessmentById = `
  query getRetirementQuarterlyAssessmentFormById($id: Int!) {
	getRetirementQuarterlyAssessmentFormById(id: $id) {
		id
		memberId
		year
		quarterNumber {
			id
			value
		}
		dateOfAssessment
		hasHorseStatusChanged
		newStatus {
			id
			value
		}
		progressPhotos {
			filename
			key
		  }
		agistmentHasPhysicalHealthChanged
		agistmentChangesToPhysicalHealth {
			id
			value
		}
		agistmentChangesToPhysicalHealthOther
		agistmentHasBehaviourChanged
		agistmentChangesToBehaviourOther
		worming
		wormingDateCompleted
		farrier
		farrierDateCompleted
		dentistTreatment
		dentistTreatmentDateCompleted
		husbandryOther
		rehabReason {
			id
			value
		}
		injuryType {
			id
			value
		}
		illnessType {
			id
			value
		}
		rehabReasonOther
		rehabDateCommenced
		treatmentSinceLastReport
		deceasedCauseOfDeath {
			id
			value
		}
		deceasedDateOfDeath
		deceasedReasonOfDeath {
			id
			value
		}
		deceasedInjuryDetailsOther
		deceasedIllnessDetailsOther
		deceasedReasonForDeathOther {
			id
			value
		}
		deceasedKnackeryPostcode
		deceasedKnackerySuburb
		informationAboutDeath
		hasOwnerBeenNotified
		finalCommentsNotes
		status
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horse {
				name
				horseCode
				status
			}
			reviewNotes
			reviewReasons
			lastSubmitted
			lastApproved
    }
  }`;

export const reviewFosterInitialAssessmentForm = `
  mutation saveFosterInitialAssessmentFormStatus($id: Int!
	  $reviewNotes: String
	  $reviewReasons: String
	  $status: FormStatusEnum!) {
		  saveFosterInitialAssessmentFormStatus(
		  id: $id
		  reviewNotes: $reviewNotes
		  reviewReasons: $reviewReasons
		  status: $status
	  ) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	  }
	}
	`;

export const reviewFosterWeeklyAssessmentForm = `
  mutation saveFosterWeeklyAssessmentFormStatus($id: Int!
	  $reviewNotes: String
	  $reviewReasons: String
	  $status: FormStatusEnum!) {
		  saveFosterWeeklyAssessmentFormStatus(
		  id: $id
		  reviewNotes: $reviewNotes
		  reviewReasons: $reviewReasons
		  status: $status
	  ) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	  }
	}
	`;

export const reviewRetirementInitialAssessmentForm = `
	mutation saveRetirementInitialAssessmentFormStatus($id: Int!
		$reviewNotes: String
		$reviewReasons: String
		$status: FormStatusEnum!) {
			saveRetirementInitialAssessmentFormStatus(
			id: $id
			reviewNotes: $reviewNotes
			reviewReasons: $reviewReasons
			status: $status
		) {
		  id
		  success
		  error {
			errorCode
			errorDetails
			errorMessage
		  }
		}
	  }
	  `;

export const reviewRetirementQuarterlyAssessmentForm = `
	  mutation saveRetirementQuarterlyAssessmentFormStatus($id: Int!
		  $reviewNotes: String
		  $reviewReasons: String
		  $status: FormStatusEnum!) {
			  saveRetirementQuarterlyAssessmentFormStatus(
			  id: $id
			  reviewNotes: $reviewNotes
			  reviewReasons: $reviewReasons
			  status: $status
		  ) {
			id
			success
			error {
			  errorCode
			  errorDetails
			  errorMessage
			}
		  }
		}
		`;

export const dismissRetirementNotificationSubmit = `
mutation dismissRetirementNotification {
	dismissRetirementNotification
}
`;

export const getHorseOTTStatus = `
query getHorseOTTStatus($horseCode: Int!) {
  getHorseOTTStatus(horseCode: $horseCode) {
	 id
	 horseCode
	 horseOTTStatusId
	 byMemberId
	 effectiveDate
  }
}`;

export const getAllUserVerifiedByRole = `
query getAllUserVerifiedByRole($roleId: Int!) {
  getAllUserVerifiedByRole(roleId: $roleId) {
	 id
	 firstName
	 lastName
	 isVerified
	 memberRoleId
	 memberExternalId
  }
}`;

export const updateHorsePlacementMemberId = `
  mutation updateHorsePlacementMemberId ($memberId: Int, $horsePlacementId: Int!){
	updateHorsePlacementMemberId(
		memberId: $memberId
		horsePlacementId: $horsePlacementId)
  }`;

export const getRetrainerHorsePlacementListings = `
  query getRetrainerHorsePlacementListings($memberExternalId: String!) {
	  getRetrainerHorsePlacementListings(memberExternalId: $memberExternalId) {
		latestRESETExpressQualification {
			id
			value
		}
		  horse {
			  age
			  name
			  horseCode
			sex
		racingSummary {
		  dateLastRaced
		  raceTypeSummary {
			career
			starts
		  }
		}
		  }
		  height
		  suburb
		  postcode
		  riddenHistory
		  disciplines {
			  id
			  value
		  }
		  disciplinesOther
		  leftSidePhotos {
			  created
			  filename
			  isApproved
			  key
			  type
		  }
		  rightSidePhotos {
			  created
			  filename
			  isApproved
			  key
			  type
		  }
		  behindFullPhotos {
			  created
			  filename
			  isApproved
			  key
			  type
		  }
		  faceOnPhotos {
			  created
			  filename
			  isApproved
			  key
			  type
		  }
		  additionalPhotos {
			  created
			  filename
			  isApproved
			  key
			  type
		  }
		  id
		  horseOTTStatusID
		  byMemberID
		  eligibilityForBCTS
	  }
  }`;

export const checkDismissmissableExist = `
query isDismissableNotificationExist($memberExternalId: String! $source: String!) {
	isDismissableNotificationExist(memberExternalId: $memberExternalId 
		source: $source)
}
`;

export const saveFosterInitialAssessmentForm = `
  mutation saveFosterInitialAssessmentForm($input: FosterInitialAssessmentFormInput) {
	saveFosterInitialAssessmentForm(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const saveFosterWeeklyAssessmentForm = `
  mutation saveFosterWeeklyAssessmentForm($input: FosterWeeklyAssessmentFormInput) {
	saveFosterWeeklyAssessmentForm(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const saveResetExpressRehabilitationConclusionForm = `
  mutation saveResetExpressRehabilitationConclusionForm($input: ResetExpressRehabilitationConclusionFormInput) {
	saveResetExpressRehabilitationConclusionForm(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const saveTransitionReport = `
  mutation saveTransitionReport($input: TransitionReportInput) {
	saveTransitionReport(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const saveResetExpressRehomeAssessmentForm = `
  mutation saveResetExpressRehomeAssessmentForm($input: ResetExpressRehomeAssessmentFormInput) {
	saveResetExpressRehomeAssessmentForm(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const submitResetExpressRehomeAssessmentForReview = `
  mutation submitResetExpressRehomeAssessmentForReview ($weekId: Int!, $horseCode: Int!){
	submitResetExpressRehomeAssessmentForReview(
		weekId: $weekId
		horseCode: $horseCode)
  }`;

export const submitResetExpressRehabilitationConclusionForReview = `
  mutation submitResetExpressRehabilitationConclusionForReview ($weekId: Int!, $horseCode: Int!){
	submitResetExpressRehabilitationConclusionForReview(
		weekId: $weekId
		horseCode: $horseCode)
  }`;

export const submitAckFosterWeeklyAssessmentForReview = `
  mutation submitAckFosterWeeklyAssessmentForReview ($weekId: Int!, $horseCode: Int!){
	submitAckFosterWeeklyAssessmentForReview(
		weekId: $weekId
		horseCode: $horseCode)
  }`;

export const getFosterInitialAssessment = `
	query getFosterInitialAssessmentForm($horseCode: Int!, $memberExternalId: String!) {
		getFosterInitialAssessmentForm(horseCode: $horseCode, memberExternalId: $memberExternalId) {
			habits {
				id
				value
			}
			created
			dateOfAssessment
			worming
			dentist
			farrier
			feedingRequirementsPlan
			hasHorseDisplayedVices
			horseHandle
			otherHusbandryInfo
			overallCondition
			temperament
			habitsOther
			leftSidePhotos{
				filename
				key
				type
			}
			rightSidePhotos{
				filename
				key
				type
			}
			additionalPhotos{
				filename
				key
				type
			}
			horsePhysicalHealth
			horsePhysicalHealthOtherConcern
			horseBehaviour
			finalComments
			status
		}
	}
`;

export const submitFosterInitialAssessmentForReview = `
  mutation submitAckFosterInitialAssessmentForReview ( $horseCode: Int!){
	submitAckFosterInitialAssessmentForReview(horseCode: $horseCode
		)
  }`;

export const checkImageExist = /* GraphQL */ `
	query checkImageExist($imageKey: String!) {
		checkImageExist(imageKey: $imageKey)
	}
`;

export const getEligibilityReferences = `
  query getEligibilityReferences {
	listHabitsAndVices {
		id
		value
	}
	listAckRetirementInjuryTypes {
	  id
	  value
	}
	listAckRetirementIllnessTypes {
	  id
	  value
	}
	listInformationSources {
	  id
	  value
	}
	listSourceDetails{
	  id
	  value
	  informationSourceId
	}
	getAdminMembers {
	  id
	  externalId
	  firstName
	  lastName
	}
  }
  `;

export const getFosterWeeklyAssessmentById = `
	query getFosterWeeklyAssessmentFormById($id: Int!) {
		getFosterWeeklyAssessmentFormById(id: $id) {
			id
		memberId
		horseCode
		weekId
		dateOfAssessment
		progressPhotos {
			filename
			key
		}
		hasHorseStatusChanged
		newStatus {
			id
			value
		}
		injuryType {
			id
			value
		}
		illnessType {
			id
			value
		}
		unsuitableReason {
			id
			value
		}
		selectedDisciplines {
			id
			value
		}
		assistanceType {
			id
			value
		}
		rehabReason {
			id
			value
		}
		rehabReasonOther 
		rehabDateCommenced
		deceasedInjuryDetailsOther
		horseProgressUpdate
		horseIssuesEncountered
		feedingPlan
		physicalConcerns
		physicalConcernDetails
		vetTreatmentDetails
		vetTreatment
		concludedReason {
			id
			value
		}
		deceasedDateOfDeath
		deceasedReasonOfDeath {
			id
			value
		}
		deceasedReasonForDeathOther {
			id
			value
		}
		deceasedIllnessDetailsOther
		unsuitableReasonOther
		dateSold
		priceBracket {
			id
			value
		}
		selectedDisciplinesOther
		postcode
		email
		fullName
		dateCommenced
		isRetrainHorse
		purpose {
			id
			value
		}
		purposeOther
		isFullCircle
		spellingExpectedTimeframe
		otherConcerns
		horse {
			name
			sex
			colour
			age
			country
			damName
			sireName
			foalDate
			microChipNumber
			horseBrand
			horseMarkings
		}
		member {
			id
			externalId
			email
			firstName
			lastName
			gender
			phone
			residentialAddress {
				address
				country
				postcode
				state
				suburb
			}
			roles {
				code
			}
		}
		created
		lastUpdated
		lastReviewed
		status
		reviewMessages
		lastRejected
		reviewNotes
		reviewReasons
		lastSubmitted
		lastApproved
		hasHorseBeenInWorkThisWeek
		detailsOfWorkSessionThisWeek
		keyProgressAchievementsToDate
		areaNeedExtraWorkAttention
		workPlanForTheComingWeek
		hasHorseBeenInWorkThisWeekExplaination
		anyLamenessOrPhysicalHealthConcern
		horseRequiredVetAttention
		requiredVetAttentionExplaination
		physicalHealthConcern
		dateFoundationTraining
		}
	}
`;

export const getUserFosterForms = `
	query getUserFosterForms($memberExternalId: String!, $horseCode: Int) {
		getFosterWeeklyAssessmentForms(memberExternalId: $memberExternalId, horseCode: $horseCode) {
			id
		memberId
		horseCode
		weekId
		dateOfAssessment
		progressPhotos {
			filename
			key
		}
		hasHorseStatusChanged
		newStatus {
			id
			value
		}
		injuryType {
			id
			value
		}
		illnessType {
			id
			value
		}
		unsuitableReason {
			id
			value
		}
		selectedDisciplines {
			id
			value
		}
		assistanceType {
			id
			value
		}
		rehabReason {
			id
			value
		}
		rehabReasonOther 
		rehabDateCommenced
		deceasedInjuryDetailsOther
		horseProgressUpdate
		horseIssuesEncountered
		feedingPlan
		physicalConcerns
		physicalConcernDetails
		vetTreatmentDetails
		vetTreatment
		concludedReason {
			id
			value
		}
		deceasedDateOfDeath
		deceasedReasonOfDeath {
			id
			value
		}
		deceasedReasonForDeathOther {
			id
			value
		}
		deceasedIllnessDetailsOther
		unsuitableReasonOther
		dateSold
		priceBracket {
			id
			value
		}
		selectedDisciplinesOther
		postcode
		email
		fullName
		dateCommenced
		isRetrainHorse
		purpose {
			id
			value
		}
		purposeOther
		isFullCircle
		spellingExpectedTimeframe
		otherConcerns
		horse {
			name
			sex
			colour
			age
			country
			damName
			sireName
			foalDate
			microChipNumber
			horseBrand
			horseMarkings
		}
		member {
			id
			externalId
			email
			firstName
			lastName
			gender
			phone
			residentialAddress {
				address
				country
				postcode
				state
				suburb
			}
			roles {
				code
			}
		}
		created
		lastUpdated
		lastReviewed
		status
		reviewMessages
		lastRejected
		reviewNotes
		reviewReasons
		lastSubmitted
		lastApproved
		hasHorseBeenInWorkThisWeek
		detailsOfWorkSessionThisWeek
		keyProgressAchievementsToDate
		areaNeedExtraWorkAttention
		workPlanForTheComingWeek
		hasHorseBeenInWorkThisWeekExplaination
		anyLamenessOrPhysicalHealthConcern
		horseRequiredVetAttention
		requiredVetAttentionExplaination
		physicalHealthConcern
		dateFoundationTraining
		}
	}
`;

export const submitResetInitialReportsForReview = `
  mutation submitResetInitialReportsForReview ( $horseCode: Int!){
	submitResetInitialReportsForReview(horseCode: $horseCode
		)
  }`;

export const submitResetWeeklyReportsForReview = `
  mutation submitResetWeeklyReportsForReview ( $horseCode: Int!, $weekId: Int!){
	submitResetWeeklyReportsForReview(horseCode: $horseCode, weekId: $weekId
		)
  }`;

export const saveOTTStatusAndProgram = `mutation saveOTTStatusAndProgram($memberExternalId: String, $horseCode: [Int!], $programId: Int!, $asOfDate: String, $statusId: Int!){
	saveOTTStatusAndProgram(memberExternalId: $memberExternalId, horseCode: $horseCode, programId: $programId, asOfDate: $asOfDate, statusId: $statusId)
  }`;

export const saveForSaleHorseUpdate = `
	  mutation saveForSaleHorseUpdate($horseCode: Int!
		  $message: String
		  $source: String) {
			  saveForSaleHorseUpdate(
				horseCode: $horseCode
				message: $message
			 	source: $source
		  ) {
			id
			success
			error {
			  errorCode
			  errorDetails
			  errorMessage
			}
		  }
		}
		`;

export const saveForSaleHorseImage = `
mutation saveForSaleHorseImage($horseCode: Int!
	$fileName: String!
	$fileKey: String!) {
		saveForSaleHorseImage(
			horseCode: $horseCode
			fileName: $fileName
			fileKey: $fileKey
	) {
		id
		success
		error {
		errorCode
		errorDetails
		errorMessage
		}
	}
	}
	`;

export const getAllMembersVerified = /* GraphQL */ `
	query getAllMembersVerified(
		$horseCode: Int!
		$isGetAllUsersVerified: Boolean
		$isGetUsersUnclaim: Boolean
		$typeOfUsers: Int
		$input: String
	) {
		getAllMembersVerified(
			horseCode: $horseCode
			isGetAllUsersVerified: $isGetAllUsersVerified
			isGetUsersUnclaim: $isGetUsersUnclaim
			typeOfUsers: $typeOfUsers
			input: $input
		) {
			id
			firstName
			lastName
			isVerified
			memberExternalId
			email
			gender
			phone
			memberRoleId
			roles {
				code
			}
		}
	}
`;

export const deleteUploadedImageByMemberIdAndHorseCode = `
	mutation deleteUploadedImageByMemberIdAndHorseCode($memberId: Int!
		$horseCode: Int!) {
			deleteUploadedImageByMemberIdAndHorseCode(
				memberId: $memberId
				horseCode: $horseCode
		) 
		}
		`;

export const deleteResetNominationImageByResetNominationFormId = `
		mutation deleteResetNominationImageByResetNominationFormId($resetNominationFormId: Int!) {
				deleteResetNominationImageByResetNominationFormId(
					resetNominationFormId: $resetNominationFormId
			) 
			}
			`;

export const deleteOhepNominationFileByOhepNominationFormId = `
mutation deleteOhepNominationFileByOhepNominationFormId($ohepNominationFormId: Int!) {
		deleteOhepNominationFileByOhepNominationFormId(
			ohepNominationFormId: $ohepNominationFormId
	) 
	}
	`;

export const getThoroughbredUnregisteredForm = /* GraphQL */ `
	query getThoroughbredUnregisteredForm($memberExternalId: String!) {
		getMember(externalId: $memberExternalId) {
			id
			email
			externalId
			firstName
			lastName
			preferredName
			dateOfBirth
			gender
			phone
			businessPhone
			postcode
			residentialAddress {
				address
				country
				lastUpdated
				postcode
				state
				suburb
			}
			postalAddress {
				address
				country
				lastUpdated
				postcode
				state
				suburb
			}
		}
		getThoroughbredUnregisteredForm(memberExternalId: $memberExternalId) {
			id
			memberId
			horseCode
			isThisHorseLocatedTheSameAddress
			horseLocationResidentialAddress
			horseLocationResidentialSuburb
			horseLocationResidentialPostcode
			horseLocationResidentialState
			horseLocationResidentialCountry
			horseNameBreeding
			gender
			foalDate
			age
			foalYear
			sireName
			damName
			colour
			countryOfBirth
			countryOfRegistration
			registrationNo
			lifeNo
			microchipNumber
			overseasName
			importationDate
			hasThisHorseEverRaced
			horseToBeInYourCase
			anyOtherDetailsHorseIdentity
			status
			lastUpdated
			lastApproved
			lastSubmitted
			brandLeftImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			brandRightImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsLegImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsHeadImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			uploadImportExportImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
		}
	}
`;

export const getThoroughbredUnregisteredFormById = /* GraphQL */ `
	query getThoroughbredUnregisteredFormById(
		$memberExternalId: String!
		$id: Int!
	) {
		getMember(externalId: $memberExternalId) {
			id
			email
			externalId
			firstName
			lastName
			preferredName
			dateOfBirth
			gender
			phone
			businessPhone
			postcode
			residentialAddress {
				address
				country
				lastUpdated
				postcode
				state
				suburb
			}
			postalAddress {
				address
				country
				lastUpdated
				postcode
				state
				suburb
			}
		}
		getThoroughbredUnregisteredFormById(id: $id) {
			id
			memberId
			horseCode
			isThisHorseLocatedTheSameAddress
			horseLocationResidentialAddress
			horseLocationResidentialSuburb
			horseLocationResidentialPostcode
			horseLocationResidentialState
			horseLocationResidentialCountry
			horseNameBreeding
			gender
			foalDate
			age
			foalYear
			sireName
			damName
			colour
			countryOfBirth
			countryOfRegistration
			registrationNo
			lifeNo
			microchipNumber
			overseasName
			importationDate
			hasThisHorseEverRaced
			horseToBeInYourCase
			anyOtherDetailsHorseIdentity
			status
			lastUpdated
			lastApproved
			lastSubmitted
			brandLeftImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			brandRightImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsLegImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsHeadImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			uploadImportExportImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
		}
	}
`;

export const saveThoroughbredUnregisteredForm = `
	mutation saveThoroughbredUnregisteredForm($input: ThoroughbredUnregisteredFormInput) {
	  saveThoroughbredUnregisteredForm(input: $input) {
	  id
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	  message
	  success
	}
  }`;

export const deleteThoroughbredUnregisteredFormMutation = /* GraphQL */ `
	mutation deleteThoroughbredUnregisteredForm($id: Int!) {
		deleteThoroughbredUnregisteredForm(id: $id)
	}
`;

export const deleteThoroughbredUnregisteredImagesMutation = /* GraphQL */ `
	mutation deleteThoroughbredUnregisteredImages($id: Int!, $key: String!) {
		deleteThoroughbredUnregisteredImages(id: $id, key: $key)
	}
`;

export const getThoroughbredUnregisteredForms = /* GraphQL */ `
	query getThoroughbredUnregisteredForms($status: [FormStatusEnum]) {
		getThoroughbredUnregisteredForms(status: $status) {
			id
			adminAction {
				id
				formId
				admin {
					id
					lastName
					firstName
					externalId
				}
				adminOutcome {
					id
					value
				}
				adminStatus {
					id
					value
				}
			}
			memberId
			member {
				id
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			horseCode
			isThisHorseLocatedTheSameAddress
			horseLocationResidentialAddress
			horseLocationResidentialSuburb
			horseLocationResidentialPostcode
			horseLocationResidentialState
			horseLocationResidentialCountry
			horseNameBreeding
			gender
			foalDate
			age
			foalYear
			sireName
			damName
			colour
			countryOfBirth
			countryOfRegistration
			registrationNo
			lifeNo
			microchipNumber
			overseasName
			importationDate
			hasThisHorseEverRaced
			horseToBeInYourCase
			anyOtherDetailsHorseIdentity
			status
			lastUpdated
			lastApproved
			lastSubmitted
			brandLeftImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			brandRightImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsLegImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsHeadImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			uploadImportExportImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
		}
	}
`;

export const getThoroughbredUnregisteredFormByIdReview = /* GraphQL */ `
	query getThoroughbredUnregisteredFormByIdReview($id: Int!) {
		getThoroughbredUnregisteredFormById(id: $id) {
			id
			adminAction {
				id
				formId
				admin {
					id
					lastName
					firstName
					externalId
				}
				adminOutcome {
					id
					value
				}
				adminStatus {
					id
					value
				}
			}
			memberId
			horseCode
			isThisHorseLocatedTheSameAddress
			horseLocationResidentialAddress
			horseLocationResidentialSuburb
			horseLocationResidentialPostcode
			horseLocationResidentialState
			horseLocationResidentialCountry
			horseNameBreeding
			gender
			foalDate
			age
			foalYear
			sireName
			damName
			colour
			countryOfBirth
			countryOfRegistration
			registrationNo
			lifeNo
			microchipNumber
			overseasName
			importationDate
			hasThisHorseEverRaced
			horseToBeInYourCase
			anyOtherDetailsHorseIdentity
			status
			lastUpdated
			lastApproved
			lastSubmitted
			brandLeftImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			brandRightImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsLegImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			markingsHeadImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			uploadImportExportImages {
				filename
				type
				created
				key
				thoroughbredUnregisteredId
			}
			reviewNotes
		}
	}
`;

export const getAdminActionHorseRegistrationOptionsList = `
query getAdminActionHorseRegistrationOptionsList {
	listAdminActionStauses {
	  id
	  value
	}
	listAdminActionOutcomesHorseRegistration {
	  id
	  value
	}
	getAdminMembers {
	  id
	  externalId
	  firstName
	  lastName
	}
  }
`;

export const saveThoroughbredUnregisteredFormStatus = `
  mutation saveThoroughbredUnregisteredFormStatus($id: Int!
	  $reviewNotes: String
	  $status: FormStatusEnum!) {
		  saveThoroughbredUnregisteredFormStatus(
		  id: $id
		  reviewNotes: $reviewNotes
		  status: $status
	  ) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	  }
	}
	`;

export const getHorseProfileRetirement = `
	query getHorseProfileRetirement($horseCode: Int!) {
	  getHorseProfileRetirement(horseCode: $horseCode) {
	 	id
		memberId
		horseCode
		retirementCategory
		behaviourOthersRetirement
		conformationalDetailsRetirement
		otherEducationDetails
		otherInjuryDetailsRetirement
		otherIllnessDetailsRetirement
		rehomingHistoryDetails
		otherDetailsRetirement
		vulnerabilityType
		habits {
			id
			value
		}
		injuries {
			id
			value
		}
		illness {
			id
			value
		}
		education {
			id
			value
		}
		reason {
			id
			value
		}
	  }
	}`;

export const saveHorseProfileRetirement = `
	mutation saveHorseProfileRetirement($input: HorseProfileRetirementInput) {
	  saveHorseProfileRetirement(input: $input) {
	  id
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	  message
	  success
	}
  }`;

export const saveSponsorshipOutcomeForm = /* GraphQL */ `
	mutation saveSponsorshipOutcomeForm($input: SponsorshipOutcomeFormInput!) {
		saveSponsorshipOutcomeForm(input: $input) {
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveSponsorshipOutcomeFormStatus = /* GraphQL */ `
	mutation saveSponsorshipOutcomeFormStatus(
		$id: Int!
		$status: FormStatusEnum
		$sponsorshipAdminInput: SponsorshipOutcomeFormAdminInput
	) {
		saveSponsorshipOutcomeFormStatus(
			id: $id
			status: $status
			sponsorshipAdminInput: $sponsorshipAdminInput
		) {
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const getSponsorshipOutcomeForms = /* GraphQL */ `
	query getSponsorshipOutcomeForms($status: [FormStatusEnum]) {
		getSponsorshipOutcomeForms(status: $status) {
			id
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			organisation {
				id
				value
			}
			status
			lastUpdated
		}
	}
`;

export const getSponsorshipOutcomeFormsAdmin = /* GraphQL */ `
	query getSponsorshipOutcomeFormsAdmin(
		$memberExternalId: String
		$status: [FormStatusEnum]
	) {
		getSponsorshipOutcomeFormsAdmin(
			memberExternalId: $memberExternalId
			status: $status
		) {
			id
			member {
				id
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			organisation {
				id
				value
			}
			status
			lastUpdated
		}
	}
`;

export const getSponsorshipOutcomeFormById = /* GraphQL */ `
	query getSponsorshipOutcomeFormById($id: Int!) {
		getSponsorshipOutcomeFormById(id: $id) {
			id
			adminAction {
				id
				formId
				admin {
					id
					lastName
					firstName
					externalId
				}
				adminOutcome {
					id
					value
				}
				adminStatus {
					id
					value
				}
			}
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			organisation {
				id
				value
			}
			eventPhotos {
				created
				filename
				key
				type
			}
			signageOrPromotionPhotos {
				created
				filename
				key
				type
			}
			representativeName
			role
			contactDetails
			reportDate
			effectiveDateAgreement
			conclusionDateAgreement
			totalValueAgreement
			utilizationOfOttSponsorship
			utilizationOfOttSponsorshipOther
			numberOfEventsIncluded
			totalOttClassesRun
			overallEntrantsNumber
			ottEntrantsNumber
			discipline {
				id
				value
			}
			isPrizeMoneyDistributed
			amountOfPrizeMoneyDistributed
			numberOfFacebookEventPosts
			numberOfInstagramEventPosts
			otherSocialMediaUpdates
			numberOfFacebookPostShare
			numberOfInstagramPostShare
			otherComments
			signageDescription
			whatWorkedWellQuestion
			whatDidNotWorkQuestion
			howToImproveQuestion
			keyAchievements
			feedbackFromMembers
			isAnyPathwaysInititiativesCreated
			competitiveOrNonCompetitive
			numberOfNewPathways
			isIncreasedUpstakeOfThoroughbreds
			numberOfNewOtts
			commentsOrSuggestions
			status
			created
			lastUpdated
			lastSubmitted
			otherDiscipline
		}
	}
`;

export const getSponsorshipOutcomeFormByIdAdmin = /* GraphQL */ `
	query getSponsorshipOutcomeFormByIdAdmin($id: Int!) {
		getSponsorshipOutcomeFormByIdAdmin(id: $id) {
			id
			adminAction {
				id
				formId
				admin {
					id
					lastName
					firstName
					externalId
				}
				adminOutcome {
					id
					value
				}
				adminStatus {
					id
					value
				}
			}
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			organisation {
				id
				value
			}
			eventPhotos {
				created
				filename
				key
				type
			}
			signageOrPromotionPhotos {
				created
				filename
				key
				type
			}
			representativeName
			role
			contactDetails
			reportDate
			effectiveDateAgreement
			conclusionDateAgreement
			totalValueAgreement
			utilizationOfOttSponsorship
			utilizationOfOttSponsorshipOther
			numberOfEventsIncluded
			totalOttClassesRun
			overallEntrantsNumber
			ottEntrantsNumber
			discipline {
				id
				value
			}
			isPrizeMoneyDistributed
			amountOfPrizeMoneyDistributed
			numberOfFacebookEventPosts
			numberOfInstagramEventPosts
			otherSocialMediaUpdates
			numberOfFacebookPostShare
			numberOfInstagramPostShare
			otherComments
			signageDescription
			whatWorkedWellQuestion
			whatDidNotWorkQuestion
			howToImproveQuestion
			keyAchievements
			feedbackFromMembers
			isAnyPathwaysInititiativesCreated
			competitiveOrNonCompetitive
			numberOfNewPathways
			isIncreasedUpstakeOfThoroughbreds
			numberOfNewOtts
			commentsOrSuggestions
			status
			created
			lastUpdated
			lastSubmitted
			reviewNotes
			hasTheSponsorshipAgreement
			howHasSponsorshipAgreementDelivered
			whatNeedsImproveAgreement
			areThereAdditionalImprovements
			improvementsRequired
			commentsOnSponsorshipOverall
			doYouRecommendRenewSponsorship
			isRecommendationFutureSponsorship
			renewalRecommendation
			rejectReasonNotification
			otherDiscipline
		}
	}
`;

export const getSponsorshipOutcomeForm = /* GraphQL */ `
	query getSponsorshipOutcomeForm {
		getSponsorshipOutcomeForm {
			id
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
				roles {
					code
				}
			}
			organisation {
				id
				value
			}
			eventPhotos {
				created
				filename
				key
				type
			}
			signageOrPromotionPhotos {
				created
				filename
				key
				type
			}
			representativeName
			role
			contactDetails
			reportDate
			effectiveDateAgreement
			conclusionDateAgreement
			totalValueAgreement
			utilizationOfOttSponsorship
			utilizationOfOttSponsorshipOther
			numberOfEventsIncluded
			totalOttClassesRun
			overallEntrantsNumber
			ottEntrantsNumber
			discipline {
				id
				value
			}
			isPrizeMoneyDistributed
			amountOfPrizeMoneyDistributed
			numberOfFacebookEventPosts
			numberOfInstagramEventPosts
			otherSocialMediaUpdates
			numberOfFacebookPostShare
			numberOfInstagramPostShare
			otherComments
			signageDescription
			whatWorkedWellQuestion
			whatDidNotWorkQuestion
			howToImproveQuestion
			keyAchievements
			feedbackFromMembers
			isAnyPathwaysInititiativesCreated
			competitiveOrNonCompetitive
			numberOfNewPathways
			isIncreasedUpstakeOfThoroughbreds
			numberOfNewOtts
			commentsOrSuggestions
			status
			created
			lastUpdated
			lastSubmitted
			otherDiscipline
		}
	}
`;

export const saveEventReportRequestNotification = /* GraphQL */ `
	mutation saveEventReportRequestNotification(
		$eventOrganiserId: Int!
		$dateRequired: String!
	) {
		saveEventReportRequestNotification(
			eventOrganiserId: $eventOrganiserId
			dateRequired: $dateRequired
		) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const generateAssignedEventReportNotice = /* GraphQL */ `
	mutation generateAssignedEventReportNotice(
		$eventOrganiserId: Int!
		$dueDate: String
	) {
		generateAssignedEventReportNotice(
			eventOrganiserId: $eventOrganiserId
			dueDate: $dueDate
		)
	}
`;

export const getAdminActionSponsorshipOutcomeOptionsList = `
query getAdminActionSponsorshipOutcomeOptionsList {
	listAdminActionStauses {
	  id
	  value
	}
	listAdminActionOutcomesSponsorship {
	  id
	  value
	}
	getAdminMembers {
	  id
	  externalId
	  firstName
	  lastName
	}
  }
`;

export const getSelectedMemberAddress = `
	query getSelectedMemberAddress($memberExternalId: String!) {
	  getSelectedMemberAddress(memberExternalId: $memberExternalId) {
		address
		country
		lastUpdated
		postcode
		state
		suburb
	  }
	}`;

export const generateClaimDeclinedNotice = /* GraphQL */ `
	mutation generateClaimDeclinedNotice(
		$horseCode: Int!
		$memberExternalId: String!
		$declineReason: String!
	) {
		generateClaimDeclinedNotice(
			horseCode: $horseCode
			memberExternalId: $memberExternalId
			declineReason: $declineReason
		)
	}
`;

export const generateRequestRetrainingNotice = /* GraphQL */ `
	mutation generateRequestRetrainingNotice(
		$horseCode: Int!
		$memberExternalId: String!
		$status: FormStatusEnum!
		$declineReason: String
	) {
		generateRequestRetrainingNotice(
			horseCode: $horseCode
			memberExternalId: $memberExternalId
			declineReason: $declineReason
			status: $status
		)
	}
`;

export const getResetExpressInitialAssessment = `
	query getResetExpressInitialAssessmentForm($horseCode: Int!, $memberExternalId: String!, $weekId: Int) {
		getResetExpressInitialAssessmentForm(horseCode: $horseCode, memberExternalId: $memberExternalId, weekId: $weekId) {
			id
			member {
				externalId
				email
				firstName
				lastName
				gender
				phone
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			horse {
				name
				sex
				colour
				age
				country
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
			}
			weekId
			categoryId
			overallCondition
			worming
			dentist
			farrier
			vet
			otherHusbandryInfo
			feedingRequirementsPlan
			horsePhysicalHealthDetails
			horsePhysicalHealthDetailsFurther
			anyInjuriesAilments
			anyInjuriesAilmentsOther
			assessmentHorseTemperamentBehaviour
			assessmentHorseInteraction
			hasHorseDisplayedHabits
			habits{
				id
				value
			}
			habitsOther
			injuries {
				id
				value
			}
			created
			lastUpdated
			lastReviewed
			reviewNotes
			reviewReasons
			lastApproved
			lastSubmitted
			status
			photosOfTheHorse {
				filename
				key
				type
			}
		}
	}
`;

export const getListOfReferences = `
query getListOfReferences {
	listHabitsAndVices {
		id
		value
	}
	listInjuryDetails {
		id
		value
	}
}
`;

export const submitResetExpressInitialAssessmentForReview = `
  mutation submitAckResetExpressInitialAssessmentForReview ( $horseCode: Int!, $weekId: Int!){
	submitAckResetExpressInitialAssessmentForReview(
		horseCode: $horseCode 
		weekId: $weekId
		)
  }`;

export const saveResetExpressInitialAssessmentForm = `
  mutation saveResetExpressInitialAssessmentForm($input: ResetExpressInitialAssessmentFormInput) {
	saveResetExpressInitialAssessmentForm(input: $input) {
    id
    error {
      errorCode
      errorDetails
      errorMessage
    }
    message
    success
  }
}`;

export const reviewResetExpressInitialAssessmentForm = `
  mutation saveResetExpressInitialAssessmentFormStatus($id: Int!
	  $reviewNotes: String
	  $reviewReasons: String
	  $status: FormStatusEnum!) {
		  saveResetExpressInitialAssessmentFormStatus(
		  id: $id
		  reviewNotes: $reviewNotes
		  reviewReasons: $reviewReasons
		  status: $status
	  ) {
		id
		success
		error {
		  errorCode
		  errorDetails
		  errorMessage
		}
	  }
	}
	`;

export const getResetExpressInitialForms = `
  query getResetExpressInitialAssessmentForms($status: [FormStatusEnum]) {
	getResetExpressInitialAssessmentForms(status: $status) {
	  id
	  member {
  		externalId
  		firstName
  		lastName
		id
	  }
	  status
  	horse {
  	  name
      ottInformation {
        paddockName
      }
	  }
	 lastUpdated
	 weekId
	}
}`;

export const getResetExpressInitialFormById = `
query getResetExpressInitialAssessmentFormById($id: Int!) {
	getResetExpressInitialAssessmentFormById(id: $id) {
		id
		member {
			externalId
			email
			firstName
			lastName
			gender
			phone
			residentialAddress {
				address
				country
				postcode
				state
				suburb
			}
		}
		horse {
			name
			sex
			colour
			age
			country
			damName
			sireName
			foalDate
			microChipNumber
			horseBrand
			horseMarkings
		}
		weekId
		categoryId
		overallCondition
		worming
		dentist
		farrier
		vet
		otherHusbandryInfo
		feedingRequirementsPlan
		horsePhysicalHealthDetails
		horsePhysicalHealthDetailsFurther
		anyInjuriesAilments
		anyInjuriesAilmentsOther
		assessmentHorseTemperamentBehaviour
		assessmentHorseInteraction
		hasHorseDisplayedHabits
		habits{
			id
			value
		}
		habitsOther
		injuries {
			id
			value
		}
		created
		lastUpdated
		lastReviewed
		reviewNotes
		reviewReasons
		lastApproved
		lastSubmitted
		status
		photosOfTheHorse {
			filename
			key
			type
		}
	}
  }
  `;

export const expressHorseOptionList = `
query expressHorseOptionList {
  listInjuryDetails {
    id
    value
  }
  listAckRetirementIllnessTypes {
	id
	value
  }
}`;
